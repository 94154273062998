/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import Swal from "../constants/swal";

const Modal = ({ Html, show, onHide }) => {
  onHide = onHide ? onHide : () => null;

  const _onShow = () => {
    if (show) {
      Swal.fire({
        title: "",
        html: <Html />,
        width: "60em",
        showCloseButton: true,
        showConfirmButton: false,
        showDenyButton: false,
        showCancelButton: false,
        backdrop: true,
        allowOutsideClick: false,
        allowEscapeKey: true,
        allowEnterKey: false,
        willClose: _onHide,
      });
    }
  };

  const _onHide = () => {
    onHide();
    Swal.close();
  };

  //console.log(show);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      _onShow();
    }
    return () => (isMounted = false);
  }, [show]);

  return null;
};

export default Modal;
