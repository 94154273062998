/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { useLocalization, useAuth } from "../context";
import usePagination from "../hooks/usePagination";
import useSearch from "../hooks/useSearch";
import { FiSearch, FiEye } from "react-icons/fi";

const QuestionList = ({ view, add, serie , categories }) => {
  const { localization } = useLocalization();
  const { authToken } = useAuth();
  const {
    onSearch,
    searchQuerys,
    optionSearch,
    setSearchValue,
    onSearchOption,
  } = useSearch("translate");

  const { page, data, loading, more, pageCount, paginate } = usePagination({
    url: "data",
    where:  { table: "questions", active : 1, translated: 1, category_name : serie?.category_name ? serie?.category_name : '',  mode :  serie?.mode ? serie?.mode : '',  ...searchQuerys,  },
    authToken: authToken,
    count: 20,
  });

  useEffect(() => {
    onSearch();
  }, [optionSearch]);


  //console.log(data);

  return (
    <Row className="question-list ">
      <Col lg="12" md="12" sm="12" xs="12" className="p-2">
        <div className="user-search box">
          <Form className="search" onSubmit={(event) => event.preventDefault()}>
            <Form.Row>
              <Col lg="6" md="4" sm="12" xs="12" className="input">
                <InputGroup>
                  <FormControl
                    type="text"
                    placeholder={localization.searchInput}
                    name="search"
                    onChange={(event) => setSearchValue(event.target.value)}
                  />
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FiSearch size={30} color="#333" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                </InputGroup>
              </Col>

              <Col lg="4" md="4" sm="12" xs="12" className="input">
                <InputGroup>
                  <FormControl
                    name="category_name"
                    as="select"
                    defaultValue={serie?.category_name}
                    disabled={serie?.mode === 'thematic-exam'}
                    onChange={(event) => serie?.mode === 'thematic-exam' ? null : onSearchOption('category_name', event.target.value)}
                  >
                    <option value={""}>{localization.categories}</option>
                    {categories.map((category, index) => {
                      //console.log(`'${category.name}' : '${category.translate[localization._locale]}',`);
                      return (
                        <React.Fragment key={index}>
                          <option value={category.name}>
                            {category.translate[localization._locale]}
                          </option>
                        </React.Fragment>
                      );
                    })}
                  </FormControl>
                </InputGroup>
              </Col>

              <Col lg="2" md="4" sm="12" xs="12" className="input">
                <Button className="btn-search" onClick={onSearch}>
                  {localization.search}
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </div>
      </Col>
      <Col
        lg="12"
        md="12"
        sm="12"
        xs="12"
        className="scroll pagination-section"
      >
        {data.map((question, index) => {
          //const key = `question_${question.id}`;
          //const _question = question.translate[lang][key];
         // const _question = JSON.parse(question.content);
          const content = (typeof question.content === 'object')  ? question.content : JSON.parse(question.content);
          const translate = (typeof question.translate === 'object') ? question.translate : JSON.parse(question.translate);

          //translate['fr'].question_1.question

          return (
            <Col
              key={index}
              lg="12"
              md="12"
              sm="12"
              xs="12"
              className="input p-0"
            >
              {/*<p dir="auto">{question.content.question_1.question}</p>*/}
              <div className="question-box">
                <div className="right-side">
                  <p>{question.id}</p>
                  <p className="question" dir="auto">
                    {translate['fr'].question_1.question ? translate['fr'].question_1.question : content.question_1.question}
                    <b>{localization[question.category_name]} - <span>{localization.used} ({question.used})</span></b>
                  </p>
                </div>
                <div className="left-side">
                  <Button
                    onClick={() => {
                      view(question);
                    }}
                  >
                    <FiEye size={20} color="#fff" />
                  </Button>
                  <Button variant="success" onClick={() => add(question)}>{localization.Add}</Button>
                </div>
              </div>
            </Col>
          );
        })}
      </Col>
      <Col lg={12} md={12} sm={12} xs={12}>
        <button
          className="btn more w-100"
          onClick={() => {
            if (page < pageCount) {
              paginate(page + 1);
            }
          }}
        >
          <span className="more-text">
            {localization.more}
            {(loading || more) && <div className="lds-dual-ring"></div>}
            {' '}{localization.page} {`${page} / ${pageCount}`}
          </span>
        </button>
      </Col>
    </Row>
  );
};

export default QuestionList;
