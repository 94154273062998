/* eslint-disable import/no-anonymous-default-export */
import config from "../appConfig.js";
const baseUri = config.url;

export default {
  baseUri,
  data: baseUri + "/panel/data",
  getDataUsers: baseUri + "/panel/users",
  update: baseUri + "/panel/update",
  delete: baseUri + "/panel/delete-data",
  passwordUpdate: baseUri + "/panel/update-user",
  getUserById: baseUri + "/panel/user",
  //settings: baseUri + "/panel/settings",
  login: baseUri + "/panel/login",
  singup: baseUri + "/singup",
  getUserByToken: baseUri + "/check-login",
  logout: baseUri + "/logout",
  categories: baseUri + "/panel/categories",
  check : baseUri + "/panel/p-user",
  series : baseUri + "/panel/series",
  newSerie : baseUri + '/panel/new-serie',
  newCours : baseUri + '/panel/new-cours',
  newQuestion : baseUri + '/panel/new-question',
  addCategory : baseUri + '/panel/new-category',
  addSubCategory : baseUri + '/panel/new-subcategory',
  updateImageQuestion : baseUri + "/panel/question-image-update",
  updateImageCategory : baseUri + "/panel/category-image-update",
  updateImageCours : baseUri + "/panel/cours-image-update",
  autoecoles: baseUri + "/panel/autoecoles",
  roles : baseUri + "panel/roles",
};
