const fileUpload = (files, callback) => {
  if (files.length !== 0) {
    const file = files[0];
    const size = files[0].size;
    const type = files[0].type;

    const types = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/x-png",
      "image/gif",
    ];

    if (size >= 1000000 * 4) {
      callback({
        status: false,
        message: [3013],
      });
      return false;
    }

    if (!types.includes(type)) {
      callback({
        status: false,
        message: [3014],
      });
      return false;
    }

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      callback({
        status: true,
        message: "",
        filebase64: reader.result,
        file: file,
      });
      return false;
    };
    reader.onerror = function (error) {
      //console.log('Error: ', error);
      callback({
        status: false,
        message: [3012],
        log: error,
      });
      return false;
    };
  } else {
    callback({
      status: false,
      message: [3012],
      log: "",
    });
  }
};

export default fileUpload;
