import React from "react";
import { useLocalization, useAuth } from "../context";
import { Container, Row, Col } from "react-bootstrap";
import AutoEcoleTable from "../components/autoEcoleTable";
import FormSearch from "../components/formSearch";
import useSearch from "../hooks/useSearch";
import useDb from "../hooks/useDb";
import usePagination from "../hooks/usePagination";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const AutoEcole = ({ table }) => {
  //console.log(props);
  const { localization, direction } = useLocalization();
  const { authToken, userData } = useAuth();

  const {
    onSearch,
    searchQuerys,
    setSearchBy,
    setSearchValue,
    onSearchOption,
  } = useSearch();
  const { removeFinelbyId, update } = useDb(table);

  const { search, pathname } = useLocation();
  const { p } = queryString.parse(search);

  const {
    page,
    data,
    loading,
    more,
    paginate,
    setPage,
    pageCount,
  } = usePagination({
    url: "autoecoles",
    where: { ...searchQuerys },
    authToken: authToken,
    page: p,
    onPageChange : (_p) => {
      window.history.pushState(
        {},
        null,
        `${window.location.origin}/#${pathname}?p=${_p}`
      )
    },
  });

  const _onSearch = () => {
    setPage(1);
    onSearch();
  };

  return (
    <Container className={`container-app ${direction}`}>
      <Row>
        <Col>
          <div className="title">{localization.users}</div>
        </Col>
      </Row>
      <Row>
        <Col lg="12" md="12" sm="12" xs="12">
          <FormSearch
            onSearch={_onSearch}
            searchBy={setSearchBy}
            searchValue={setSearchValue}
            table={table}
            searchOption={onSearchOption}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <AutoEcoleTable
            searchQuerys={searchQuerys}
            data={data}
            paginate={() => paginate(page + 1)}
            userData={userData}
            authToken={authToken}
            nbrOfPage={`${page} / ${pageCount}`}
            loading={loading || more}
            update={update}
            removeFinelbyId={removeFinelbyId}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default AutoEcole;
