import React, { useState } from "react";
import { useLocalization, useAuth } from "../context";
import { Container, Row, Col, InputGroup, Button } from "react-bootstrap";
import useDb from "../hooks/useDb";
import usePagination from "../hooks/usePagination";
import Alert from "../components/alert";
import Codes from "../constants/codes";

const Settings = ({ table }) => {
  const { localization, direction } = useLocalization();
  const { authToken, userData } = useAuth();
  const { update } = useDb(table);

  const _localization = (key) => {
    return localization[key];
  };

  const getTextFromCodes = (code) => {
    if (!code) {
      return "";
    }
    return _localization(Codes[code].text);
  };

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [values, setValues] = useState({});

  const { data, loading } = usePagination({
    url: "data",
    where: { table: table },
    authToken: authToken,
    count: 20,
  });

  //console.log(values);

  const _update = (id, data) => {
    if (!userData.id && !data) {
      return false;
    }

    //console.log(data);
    setLoadingUpdate(true);

    update(id, data).then((response) => {
      if (response.status) {
        setAlertType("success");
        setSuccess(true);
      } else {
        setError(true);
        setAlertType("error");
      }
      setMessage(response?.results?.code);
      setLoadingUpdate(false);
    });

    //return response;
  };

  return (
    <Container className={`container-app ${direction}`}>
      <Row>
        <Col>
          <div className="title">{localization.settings}</div>
        </Col>
      </Row>
      <Row>
        <Col lg="12" md="12" sm="12" xs="12" className={`${direction}`}>
          {Array.isArray(message) ? (
            message.map((item, index) => (
              <Alert
                key={index}
                message={getTextFromCodes(item)}
                show={error || success}
                type={alertType}
                onHide={() => {
                  setError(false);
                  setSuccess(false);
                }}
              />
            ))
          ) : (
            <Alert
              message={localization[message] ? localization[message] : message}
              show={error || success}
              type={alertType}
              onHide={() => {
                setError(false);
                setSuccess(false);
              }}
            />
          )}
        </Col>
        <Col lg={12} className={"box"}>
          {data.map((item, index) => {
            return (
              <InputGroup key={index} className="input-settings">
                <InputGroup.Append>
                  <InputGroup.Text>
                    {localization[item.type]
                      ? localization[item.type]
                      : item.type}
                  </InputGroup.Text>
                </InputGroup.Append>
                <textarea
                  defaultValue={item.value}
                  className="form-control"
                  onChange={(event) => {
                    setValues({
                      ...values,
                      [item.type]: {
                        value: event.target.value,
                      },
                    });
                  }}
                ></textarea>
                <InputGroup.Prepend>
                  <Button onClick={() => _update(item.id, values[item.type])}>
                    {localization.update}
                  </Button>
                </InputGroup.Prepend>
              </InputGroup>
            );
          })}
        </Col>
        {(loadingUpdate || loading) && (
          <div
            className="lds-dual-ring"
            style={{ position: "relative", right: "50%" }}
          ></div>
        )}
      </Row>
    </Container>
  );
};

export default Settings;
