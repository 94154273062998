const ar = {
  direction: "rtl",
  or: "أو",
  app_name: "موقع لتعليم القيادة",
  locale: "العربية",
  _locale: "ar",
  ar: "العربية",
  fr: "الفرنسية",
  en: "الإنجليزية",
  language: "اللغة",
  home: "الرئيسية",
  logout: "تسجيل الخروج",
  settings: "الإعدادات",
  loginTitle: "تسجيل الدخول",
  signUpTitle: "انشاء حساب جديد",
  btnLogin: "تسجيل الدخول",
  btnSignup: "حساب جديد",
  btnFacebook: "مع الفيسبوك",
  recoveryPass: "نسيت كلمة المرور؟",
  recovery: "استرجاع",
  recoveryPassBtn: "استعادة كلمة المرور",
  recoveryPassTitle: "إسترجاع كلمة المرور",
  recoveryPassMsg:
    "تم إرسال رسالة تغير كلمة السر الى إيميلك. بعض مرات ترسل الى علبة سبام",
  recoveryPassMsgError: "يوجد مشكلة ما ! تحقق من البريد الإلكتروني",
  register: "حساب جديد",
  registerQuestion: "ليس لديك حساب ؟ سجل من هنا",
  update: "تعديل",
  fullname: "الإسم واللقب",
  name: "الإسم",
  phone: "رقم الهاتف",
  email: "البريد الإلكتروني",
  password: "كلمة المرور",
  newPassword: "كلمة مرور جديدة",
  oldPassword: "كلمة السر الحالية الخاصة بك",
  PassNotMatch: "تأكد من مطابقة كلمتَي المرور",
  first_name: "الإسم",
  last_name: "اللقب",
  phone2: "رقم هاتف بديل ",
  genre: [
    "النوع",
    {
      male: "الذكر",
      female: "أنثى",
    },
  ],
  male: "الذكر",
  female: "أنثى",
  confirmPassword: "أكد كلمة المرور",
  date: "تاريخ الميلاد",
  account: "الحساب",
  myAccount: "حسابي",
  connection: "الدخول",
  FieldEmptyInput: "جميع الحقول مطلوبة.",
  rateText: "قيم التطبيق",
  users: "المستخدمين",
  user: "المستخدم",
  profile: "الملف الشخصي",
  address: "عنوان",
  activity: [
    "نشاط",
    {
      student: "طالب",
      employee: "موظف",
      particular: "خاص",
    },
  ],
  dontChange: "إذا لم تريد تغيير كلمة المرور اتركه فارغ",
  newAccount: "انشاء حساب جديد",
  IhaveAccount: "لدي حساب ، ارجع إلى تسجيل الدخول",
  EmailNoValid: "البريد الإلكتروني المستخدم بالفعل أو غير صالح",
  PhoneNoValid: "رقم الهاتف مستخدم بالفعل أو غير صالح",
  FieldEmpty: "حقل مطلوب.",
  categories: "الأقسام",
  category: "الفئة",
  subCategory: "تصنيف فرعي",
  category_name: "اسم التصنيف",
  title: "العنوان",
  bookmarks: "المفضلة",
  about: "عن الموقع",
  notification: "إشعار",
  bookmarkMsg: "تم إضافة إلى المفضلة",
  bookmarkMsgAdded: "تم إضافتها مسبقا",
  noInternet: "خطأ في الإتصال",
  resources: "مصادر",
  subscribeFacebook: "انضم إلينا على فيس بوك",
  contact: "اتصل بنا على البريد الإلكتروني",
  contactUs: "اتصل بنا",
  developer: "مطور بواسطة @meksiabdou",
  version: "إصدار",
  more: "عرض المزيد",
  notificationAlert:
    "تم حظر الإشعار ، انتقل إلى إعدادات النظام الخاص بك وتمكين الإشعارات",
  alert: "تنبيه",
  social: "وسائل التواصل الاجتماعي",
  share: "مشاركة",
  rate: "قوم",
  privacy: "سياسة الخصوصية",
  Comments: "التعليقات",
  MinPassWord: "يجب أن تكون كلمة المرور أكثر من 8 أحرف",
  profileUpdate: "تحديث الملف الشخصي",
  youInfoUpdated: "تم تعديل بنجاح",
  youInfoUpdatedUnsuccess: "التحديث غير ناجح",
  cart: "عربة التسوق",
  popularProducts: "المنتجات الأكثر شعبية",
  statusUser: {
    student: "طالب",
    employee: "موظف",
    particular: "خاص",
  },
  coupon: "كوبون خصم",
  coupons: "كوبونات ",
  allCoupons: " كل كوبونات",
  code: "كود",
  subTotal: "المجموع الفرعي",
  total: "مبلغ إجمالي",
  apply: "إستعمل",
  delete: "حذف",
  cancel: "إلغاء",
  ok: "حسنا",
  buy: "مرر الطلب",
  deleteItme: "هل حق تريد حذف عنصر من عربة التسوق؟",
  open: "مفتوح",
  closed: "مغلق",
  Add: "أضف",
  addressNotUpdate: "عنوان **** غير متوفر",
  results_denied: "تم رفض إذن معلومات الموقع من قبل المستخدم.",
  never_ask_again: "تم إلغاء إذن الموقع من قبل المستخدم.",
  clickInMaps: "إضغط على مكان التي تريد تحديده",
  selectAddress: "اختر عنوان التسليم",
  gestionAddress: "إدارة العناوين",
  MailNotSend: "هناك مشكلة! لم يتم إرسال البريد الإلكتروني",
  RegisterFiled: "خطأ اتناء تسجيل حساب جديد ",
  YourInfoNotValid: "معلومات المستخدم غير صالحة",
  autre: "آخر",
  detail: "تفاصيل",
  searchInput: "بحث",
  search: "بحث",
  status: "الحالة",
  loginUnsuccessful: "خطأ أثناء تسجيل الدخول",
  active: "نشط",
  disactive: "تعطيل",
  accountStatus: "حالة الحساب",
  maxFileSize: "غير مسموح بتحميل حجم أكبر من 4 ميغا بايت",
  typeFile: "تأكد من امتداد الملف (png, gif, jpeg)",
  deleteIsSuccess: "تم الحذف بنجاح",
  deleteIsError: "خطأ عند الحذف",
  addIsSuccess: "تم إضافة بنجاح",
  date_created: "تاريخ الإنشاء",
  date_updated: "التاريخ تعدل",
  causeDelete: "اذكر سبب الحذف",
  FieldCauseDeleteEmpty: "سبب حذف الحقل إلزامي.",
  num_ccp: "رقم Ccp",
  stores: "المتاجر",
  store: "متجر",
  help: "المساعدة",
  youWelcome: "اهلا بك",
  updatePassword: "تعديل كلمة المرور",
  referral: "الإحالة",
  referralLink: "رابط الإحالة",
  loginFacebook: "تسجيل عبر فيسبوك",
  loginGoogle: "تسجيل عبر جوجل",
  error: "خطأ",
  youAccountCreated: "إنشاء الحساب بنجاح. تم إرسال رسالة تفعيل الى إيميلك",
  youCountRef: "عدد مسجلين عن طريق رابط الاحالة الخاص بك",
  browseMore: "تصفح القائمة كاملة الآن",
  aboutTitle: "عن كاشباك",
  aboutDisc:
    "عد إجراء عملية الشراء عبر موقعنا ، سيظهر المبلغ المسترد معلق في حسابك بعد قيامك بالشراء او الحجز بعد التأكد من موقع التاجر بعمليتك الشرائية ، قد يستغرق مدة تتراوح من بضع ساعات إلى بضعة أسابيع ، وفور تأكيد الكاش باك المعلق سيتم توفيرها في غضون 30 إلى 90 يومًا ، وبمجرد استلامها ستظهر في حسابك ضمن الرصيد النقدي المتاح",
  expiryDate: "تاريخ انتهاء",
  upTo: "يصل إلى",
  conditions: "الشروط !",
  days: "يوم",
  referrals: "الإحالات",
  updateImage: "تحرير صورة الملف الشخصي",
  menu: "قائمة",
  copyRight: "Recashit.com جميع حقوق محفوظة",
  summary: "فهرس",
  cours: "دروس",
  traffic: "قانون المرور",
  result: "نتيجة",
  series: "سلسلة",
  newSerie: "سلسلة جديدة",
  training: "تدريب",
  whiteExam: "امتحان تجريبي",
  thematic: "سلسلة مواضيعية",
  "training-exam": "تدريب",
  "white-exam": "امتحان تجريبي",
  "thematic-exam": "سلسلة مواضيعية",
  without: "بدون",
  with: "مع",
  variableResult: "{min} على {max}",
  question: "سؤال",
  questions: "أسئلة",
  correction: "تصحيح",
  valid: "تحقق من صحة إجابتك",
  relaunch: "إعادة",
  continue: "استمر",
  congratulations: "تهانينا",
  resultSuccessText: "أحسنت ، لقد اجتزت الاختبار الخاص بك!",
  resultUnSuccessText: "اسف, لقد فشلت في اختبار الخاص بك",
  errors: "أخطاء",
  faq: "أسئلة مكررة",
  wilaya: "الولاية",
  roles: [
    "الأدوار",
    {
      //0 : "",
      admin: "مدير",
      user: "مستخدم",
      manager: "مشرف",
    },
  ],
  admin: "مدير",
  manager: "مشرف",
  autoecole: "حساب مدرسة السياقة",
  autoEcole: "مدرسة السياقة",
  moreInfo: "معلومات إضافية",
  url_admin: "رابط لوحة تحكم",
  email_admin: "الإميل مشرف الموقع",
  sendMail: "ارسل الرسالة",
  sendMailTitle: "إرسال رسالة",
  subject: "موضوع",
  message: "الرسالة",
  content: "المحتوى",
  description: "تفاصيل",
  all: "الجميع",
  newItem: "عنصر جديد",
  info: "معلومات",
  accountDeleted: "حساب غير موجود",
  Recover_account: "استعادة حساب",
  removeFinel: "هل أنت متأكد أنك تريد الحذف نهائيًا؟",
  finel: "نهائي",
  attach: "ربط",
  page: "صفحة",
  deleted: "تم حذفهم",
  userNoActive: "حساب غير نشط",
  userActive: "مستخدم نشط",
  noActive: "معطل",
  answer: "إجابة",
  response: "إجابة",
  translated: "مترجم",
  translation: "الترجمة",
  noTranslated: "غير مترجم",
  othreAnswer: "إجابات أخرى",
  othre: "آخر",
  mode: "نوع",
  minQuestions: "السلسلة يجب ان تتكون من 40 سؤال",
  circulation: "حركة المرور",
  conducteur: "سائق",
  diverses: "متعدد",
  environnement: "بيئة",
  meca_equipements: "معدات ميكانيكية",
  prendre_quitter_voiture: "تعلم الإقلاع السيارة",
  route: "الطريق",
  secours: "إسعاف",
  sécurité_passager_véhicule: "سلامة ركاب المركبة",
  usagers: "المستخدمين",
  rightAnswerCheck: "قم بوضع علامة صح على إجابة صحيحة",
  setCours: "إضافة درس ب",
  setQuestion: "إضافة سؤال ب",
  updateLocked: "التحديث ملغى",
  response_text: "شرح الإجابة",
  free: "مجاني",
  premium: "مدفوع",
  subscribe_type: "نوع الاشتراك",
  subscribe: "الاشتراك",
  months: "أشهر",
  month: "شهر",
  subscribe_date_end: "تاريخ نهاية الاشتراك",
  imAutoecole: "حساب مدرسة السياقة",
  updateAutoEcoleInfo: "حساب مدرسة السياقة",
  moniteur: "مراقب",
  moniteursInfo: "معلومات المدربين",
  automobileInfo: "معلومات عن السيارات",
  manual: "يدوي",
  auto: "أوتوماتيكية",
  model: "طراز السيارة",
  gearbox: "علبة السرعة",
  car: "السيارة",
  year: "السنة",
  pages: "الصفحات",
  used : 'مستعمل',
};

export default ar;
