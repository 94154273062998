/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Image, Button, Form, Col, Row } from "react-bootstrap";
import { useLocalization, useAuth } from "../context";
import { BiLowVision, BiShowAlt } from "react-icons/bi";
import { postJson } from "../networking/server";
import Links from "../constants/Links";
import Codes from "../constants/codes";
import useValidation from "../hooks/useValidation";
import Alert from "../components/alert";
import Config from "../appConfig";
import Modal from "./Modal";

const FormPasswordUpdate = (props) => {
  const { localization, direction } = useLocalization();
  const { authToken } = useAuth();
  const adminData = props.adminData;

  const _localization = (key) => {
    return localization[key];
  };

  const getTextFromCodes = (code) => {
    if (!code) {
      return "";
    }
    return _localization(Codes[code].text);
  };

  const Html = () => {
    const [userData, setUserData] = useState({});
    const [showPassword, setShowPassword] = useState({
      password: false,
      confirmPassword: false,
    });
    const {
      errors,
      handelOnChange,
      data,
      handelOnSubmit,
      refForm,
    } = useValidation();
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [alertType, setAlertType] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setLoading(false);
      setError(false);
      setSuccess(false);
    }, []);

    useEffect(() => {
      setUserData(props.userData);
    }, [props.userData]);

    const onSubmit = (event, status) => {
      event.preventDefault();

      if (status) {
        if (adminData.role !== "admin" && userData.role === "admin") {
          return false;
        }

        setLoading(true);
        setError(false);
        setSuccess(false);
        postJson(
          Links.passwordUpdate,
          { ...data, id: userData.id, adminId: adminData.id },
          authToken
        ).then((response) => {
          if (response.status) {
            setAlertType("success");
            setSuccess(true);
          } else {
            setError(true);
            setAlertType("error");
          }
          setMessage(response?.results?.code);
          setLoading(false);
        });
      }
    };

    return (
      <Form
        ref={refForm}
        onSubmit={(event) => handelOnSubmit(event, onSubmit)}
        autoComplete="off"
        className={direction}
      >
        <Form.Row>
          <Col lg="3" md="4" sm="12" xs="12">
            <div className="profile-pic" style={{ marginTop: 0 }}>
              <Image src={`${Config.pic}${userData.image}`} roundedCircle />
            </div>
          </Col>
          <Col lg="9" md="8" sm="12" xs="12">
            <Row>
              <Col lg="12" md="12" sm="12" xs="12" className="title">
                {localization.newPassword}
              </Col>
              <Col lg="12" md="12" sm="12" xs="12">
                {Array.isArray(message) ? (
                  message.map((item, index) => (
                    <Alert
                      key={index}
                      message={getTextFromCodes(item)}
                      show={error || success}
                      type={alertType}
                      onHide={() => {
                        setError(false);
                        setSuccess(false);
                      }}
                    />
                  ))
                ) : (
                  <Alert
                    message={message}
                    show={error || success}
                    type={alertType}
                    onHide={() => {
                      setError(false);
                      setSuccess(false);
                    }}
                  />
                )}
              </Col>

              <Col lg="12" md="12" sm="12" xs="12" className="input">
                <div>
                  <span className="error form-text">
                    {errors.password && _localization(errors.password)}
                  </span>
                  <div
                    className={`form-group input-group input-password ${
                      errors.password ? " input-error" : ""
                    }`}
                  >
                    <input
                      type={showPassword.password ? "text" : "password"}
                      name="password"
                      defaultValue=""
                      className={`form-control`}
                      onChange={handelOnChange}
                      placeholder={_localization("password")}
                    />
                    <span
                      className="input-group-text"
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          password: !showPassword.password,
                        })
                      }
                    >
                      {showPassword.password === true ? (
                        <BiLowVision color="#91A2B7" size="1.3em" />
                      ) : (
                        <BiShowAlt color="#91A2B7" size="1.3em" />
                      )}
                    </span>
                  </div>
                </div>
              </Col>
              <Col lg="12" md="12" sm="12" xs="12" className="input">
                <div>
                  <span className="error form-text">
                    {errors.confirmPassword &&
                      _localization(errors.confirmPassword)}
                  </span>
                  <div
                    className={`form-group input-group input-password ${
                      errors.confirmPassword ? " input-error" : ""
                    }`}
                  >
                    <input
                      type={showPassword.confirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      defaultValue=""
                      className={`form-control`}
                      onChange={handelOnChange}
                      placeholder={_localization("confirmPassword")}
                    />
                    <span
                      className="input-group-text"
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          confirmPassword: !showPassword.confirmPassword,
                        })
                      }
                    >
                      {showPassword.confirmPassword === true ? (
                        <BiLowVision color="#91A2B7" size="1.3em" />
                      ) : (
                        <BiShowAlt color="#91A2B7" size="1.3em" />
                      )}
                    </span>
                  </div>
                </div>
              </Col>
              <Col>
                <Button disabled={loading} type="submit">
                  {localization.update}
                  {loading && <div className="lds-dual-ring white"></div>}
                </Button>
              </Col>
            </Row>
          </Col>
        </Form.Row>
      </Form>
    );
  };

  return <Modal show={props.show} onHide={props.onHide} Html={Html} />;
};

export default FormPasswordUpdate;
