import { useState } from "react";
import { useReload } from "../context";

const useSearch = (defaultSearchBy = "name") => {
  const { setReload } = useReload();
  const [searchBy, setSearchBy] = useState(defaultSearchBy);
  const [searchValue, setSearchValue] = useState("");
  const [optionSearch, setOptionSearch] = useState({});
  const [searchQuerys, setSearchQuery] = useState({});

  const onSearch = () => {
    if (optionSearch.deleted && optionSearch.deleted === 1) {
      if (optionSearch.active && optionSearch.active === null) {
        optionSearch.active = 2;
      }
    }
    setSearchQuery({ [searchBy]: searchValue, ...optionSearch });
    setReload();
  };

  const onSearchOption = (key, value) => {
    if (!key && !value) {
      setOptionSearch({});
    } else {
      if (value !== null) {
        setOptionSearch({
          ...optionSearch,
          [key]: value,
        });
        if (key === "active" && value === "all") {
          delete optionSearch.active;
          setOptionSearch({ ...optionSearch });
        }
        if (key === "translated" && value === "all") {
          delete optionSearch.translated;
          setOptionSearch({ ...optionSearch });
        }
      } else {
        if (key === "deleted" && optionSearch.active === 2) {
          delete optionSearch.active;
        }

        delete optionSearch[key];
        setOptionSearch({ ...optionSearch });
      }
    }
  };

  return {
    onSearch,
    searchQuerys,
    optionSearch,
    setSearchBy,
    setSearchValue,
    onSearchOption,
  };
};

export default useSearch;
