import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import useValidation from "../hooks/useValidation";
import { BiLowVision, BiShowAlt } from "react-icons/bi";
import { postJson } from "../networking/server";
import Links from "../constants/Links";
import Codes from "../constants/codes";
import { useAuth, useLocalization } from "../context";
import Alert from "../components/alert";

const Login = () => {
  const { localization, direction } = useLocalization();
  const router = useHistory();
  const {
    errors,
    handelOnChange,
    data,
    handelOnSubmit,
    refForm,
  } = useValidation();
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [loading, setLoading] = useState(false);
  const { setAuthUserData } = useAuth();

  const _localization = (key) => {
    return localization[key];
  };

  const getTextFromCodes = (code) => {
    if (!code) {
      return "";
    }
    return _localization(Codes[code].text);
  };

  const checkIsA = (data) => {
    postJson(Links.check, { id: data.id }, data.token).then((roles) => {
      if (roles.status) {
        data.role = roles.results;
        setAuthUserData(data).then((status) => {
          if (status) {
            router.push(`/user/${data.id}`);
          } else {
            setError(true);
            setAlertType("error");
            setMessage(3001);
          }
        });
      } else {
        setError(true);
        setAlertType("error");
        setMessage(3001);
      }
    });
  };

  const onSubmit = (event, status) => {
    event.preventDefault();
    if (status) {
      setLoading(true);
      setError(false);
      data.identity = data.email;
      postJson(Links.login, data).then((response) => {
        if (response.status) {
          checkIsA(response.results);
        } else {
          setError(true);
          setAlertType("error");
          setMessage(response?.results?.code);
        }
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };

  return (
    <div className={`container-fluid ${direction}`}>
      <div className="row login">
        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-12 login-left-side p-0">
          <div
            className="login-bg"
            style={{ background: 'url("/assets/images/login-bg.png")' }}
          ></div>
        </div>
        <div className="col-xl-2 col-lg-1 col-md-1 col-sm-12 login-center-side p-0" />
        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 login-right-side p-0">
          <form
            onSubmit={(event) => handelOnSubmit(event, onSubmit)}
            ref={refForm}
          >
            <div className="title">
              <h4>{_localization("loginTitle")}</h4>
            </div>

            {Array.isArray(message) ? (
              message.map((item, index) => (
                <Alert
                  key={index}
                  message={getTextFromCodes(item)}
                  show={error}
                  type={alertType}
                  onHide={() => setError(false)}
                />
              ))
            ) : (
              <Alert
                message={message}
                show={error}
                type={alertType}
                onHide={() => setError(false)}
              />
            )}
            <div className="form-group">
              <span className="error form-text">
                {errors.email && _localization(errors.email)}
              </span>
              <div
                className={`input-group ${errors.email ? "input-error" : ""}`}
              >
                <input
                  type="email"
                  name="email"
                  defaultValue=""
                  className={`form-control`}
                  onChange={handelOnChange}
                  placeholder={_localization("email")}
                />
              </div>
            </div>
            <div>
              <span className="error form-text">
                {errors.password && _localization(errors.password)}
              </span>
              <div
                className={`form-group input-group input-password ${
                  errors.password ? " input-error" : ""
                }`}
              >
                <input
                  type={showPassword.password ? "text" : "password"}
                  name="password"
                  defaultValue=""
                  className={`form-control ${
                    errors.password ? "input-error" : ""
                  }`}
                  onChange={handelOnChange}
                  placeholder={_localization("password")}
                />
                <span
                  className="input-group-text"
                  onClick={() =>
                    setShowPassword({ password: !showPassword.password })
                  }
                >
                  {showPassword.password === true ? (
                    <BiLowVision color="#91A2B7" size="1.3em" />
                  ) : (
                    <BiShowAlt color="#91A2B7" size="1.3em" />
                  )}
                </span>
              </div>
            </div>
            <button type="submit" className="btn btn-default btn-submit">
              {_localization("btnLogin")}
              {loading && <div className="lds-dual-ring white"></div>}
            </button>
            <div className="sub-form">
              <p>
                <Link to="/password-recovery">
                  {_localization("recoveryPass")}
                </Link>
              </p>
              <p>
                <Link to="/register">{_localization("registerQuestion")}</Link>
              </p>
            </div>
          </form>
        </div>
        <div className="col-xl-2 col-lg-1 col-md-1 col-sm-12 login-center-side p-0" />
      </div>
    </div>
  );
};

export default Login;