/* eslint-disable no-unused-vars */
import React from "react";
import {
  Table,
  Button,
  DropdownButton,
  Dropdown,
  Row,
  Col,
  Image,
} from "react-bootstrap";
import { useLocalization } from "../context";
import Swal from "../constants/swal";
import { FiTrash2, FiEdit } from "react-icons/fi";
import { IoIosMore } from "react-icons/io";
import FormEditCours from "./formEditCours";
import Config from "../appConfig";
import moment from "moment";

var dateString = (value) => moment(value).format("DD/MM/YYYY");

const CoursTable = ({
  userData,
  data,
  paginate,
  categories,
  update,
  removeFinelbyId,
  nbrOfPage,
  loading,
}) => {
  const { localization } = useLocalization();
  const role = userData.role;
  const [show, setShow] = React.useState(false);
  const [cours, setCours] = React.useState({});
  const [lang, _setLang] = React.useState("ar");

  //const categories = categories;
  const _update = update;

  //console.log(userData);

  //const removeFinelbyId = removeFinelbyId;

  const _deleteFinel = async (item) => {
    if (role !== "admin") {
      return false;
    }

    const swalWithBootstrapButtons =  Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger m-2",
        cancelButton: "btn btn-secondary",
      },
      buttonsStyling: false,
    });
    await swalWithBootstrapButtons.fire(
      {
        title: localization.removeFinel,
        confirmButtonText: localization.delete,
        showCancelButton: true,
        cancelButtonText: localization.cancel,
        text: "",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          removeFinelbyId(item.id).then((result) => {
            if (result.status) {
              Swal.fire(localization.deleteIsSuccess, "", "success").then();
            } else {
              Swal.fire(localization.deleteIsError, "", "error").then();
            }
          });
        },
      },
    );
  };

  const _edit = (item, _lang) => {
    setCours(item);
    _setLang(_lang);
    setTimeout(() => {
      setShow(true);
    }, 150);
  };

  //console.log(serie);

  return (
    <>
      <div className="box table-app">
        <Table responsive>
          <thead>
            <tr>
              <th>{localization.title}</th>
              <th>{localization.status}</th>
              <th>{localization.translation}</th>
              <th>{localization.date_created}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              let style = { backgroundColor: "#ffffff" };
              let colorWhite = "";
              if (!parseInt(item.active)) {
                style = { backgroundColor: "#cdcdcd" };
                colorWhite = "text-white";
              }

              item._content = JSON.parse(item.content);

              return (
                <tr key={index} style={style}>
                  <td className={colorWhite}>
                    <Image
                      className="image-article"
                      src={`${Config.uploads}/articles/${item.image}`}
                    />
                    <span
                      style={{
                        display: "inline-grid",
                        lineHeight: 1,
                      }}
                    >
                      {item._content.ar.title
                        ? item._content.ar.title
                        : item._content.fr.title}
                      <span className="subline">{item.category}</span>
                      <span className="subline">{item.subCategory}</span>
                    </span>
                  </td>
                  <td className={colorWhite}>
                    <span>
                      {parseInt(item.active) === 1
                        ? localization.active
                        : localization.disactive}
                    </span>
                  </td>
                  <td className={colorWhite}>
                    <span>
                      {parseInt(item.translated) === 1
                        ? localization.translated
                        : localization.noTranslated}
                    </span>
                  </td>
                  <td className={colorWhite}>
                    <span>{dateString(item.created_at)}</span>
                  </td>
                  <td className="btn-table">
                    <DropdownButton
                      drop={"down"}
                      variant="info"
                      className="dropdown-more"
                      id="tt"
                      title={<FiEdit size={20} color="#fff" />}
                    >
                      <Dropdown.Item onClick={() => _edit(item, "ar")}>
                        {localization.ar}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => _edit(item, "fr")}>
                        {localization.fr}
                      </Dropdown.Item>
                    </DropdownButton>
                    <DropdownButton
                      drop={"down"}
                      variant="secondary"
                      className="dropdown-more"
                      id="tt"
                      title={<IoIosMore color="#fff" fontSize="24px" />}
                    >
                      <Button
                        variant="danger"
                        onClick={() => _deleteFinel(item)}
                      >
                        <FiTrash2 size={20} color="#fff" /> {localization.finel}
                      </Button>
                      <DropdownButton
                        variant="secondary"
                        className="dropdown-user"
                        id="status"
                        title={localization.status}
                      >
                        <Dropdown.Item
                          onClick={() => _update(item.id, { active: 1 })}
                        >
                          {localization.active}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => _update(item.id, { active: "0" })}
                        >
                          {localization.disactive}
                        </Dropdown.Item>
                      </DropdownButton>
                    </DropdownButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <Row>
        <Col lg={4} md={4} sm={1} xs={12} />
        <Col lg={4} md={4} sm={10} xs={12}>
          <button className="btn more w-100" onClick={paginate}>
            <span className="more-text">
              {localization.more}
              {' - '}{localization.page} {nbrOfPage}
              {loading && <div className="lds-dual-ring"></div>}
            </span>
          </button>
        </Col>
        <Col lg={4} md={4} sm={1} xs={12} />
      </Row>
      <FormEditCours
        callBack={_update}
        show={show}
        onHide={() => setShow(false)}
        lang={lang}
        cours={cours}
        categories={categories}
      />
    </>
  );
};

export default CoursTable;
