/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import {
  Image,
  Button,
  Form,
  InputGroup,
  Col,
  FormControl,
  Row,
} from "react-bootstrap";
import { useLocalization, useAuth, useReload } from "../context";
import Codes from "../constants/codes";
import Config from "../appConfig";
import useValidation from "../hooks/useValidation";
import Alert from "../components/alert";
import { FiEdit } from "react-icons/fi";
import fileUpload from "../helps/fileUpload";
import { postJson } from "../networking/server";
import Links from "../constants/Links";
import Modal from "./Modal";

const FormEditCategories = (props) => {
  const { localization, direction } = useLocalization();
  const { authToken, userData } = useAuth();
  const { setReload } = useReload();

  const _onSubmit = props.callBack;

  const _localization = (key) => {
    return localization[key];
  };

  const getTextFromCodes = (code) => {
    if (!code) {
      return "";
    }
    return _localization(Codes[code].text);
  };

  /*const inputs = [
    {
      name: "content",
      placeholder : localization.category,
      value: "",
      type: "textarea",
    },
  ];*/

  const Html = () => {
    const inputNotReq = [];

    const {
      errors,
      handelOnChange,
      data,
      handelOnSubmit,
      setData,
      RefEvent,
      refForm,
    } = useValidation(inputNotReq);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [alertType, setAlertType] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [id, setId] = useState(0);
    const [category, setCategory] = useState({});
    const [inputs, setInputs] = useState([]);
    const refImage = useRef(null);
    const [lang, setLang] = useState("ar");

    //const lang = props.lang;

    useEffect(() => {
      setCategory(props.category);
      setId(props.category.id);
      setImage(props.category.image ? `${Config.uploads}/categories/${props.category.image}` : null);
      setFileName(props.category.image);
      setInputs([
        {
          name: `translate`,
          placeholder: localization.category,
          type: "textarea",
        },
        {
          name: `content`,
          placeholder: localization.content,
          type: "textarea",
        },
      ]);
      setLang(props.lang);
    }, [props.category, props.lang]);

    //console.log(props.category);

    useEffect(() => {
      setTimeout(() => setData(RefEvent()), 800);
      //console.log(refForm);
    }, [refForm]);

    const _fileUpdoad = (event) => {
      const _data = {
        id: id,
        uid: userData.id,
        filename : fileName,
      };
      setLoading(true);
      fileUpload(event.target.files, (result) => {
        //console.log(result);
        if (result.status) {
          if (result.filebase64) {
            setImage(result.filebase64);
            setError(false);
            postJson(
              Links.updateImageCategory,
              { ..._data, image: result.file },
              authToken
            ).then((response) => {
              if (response.status) {
                setImage(result.filebase64);
                setFileName(response.results.data.image);
                setAlertType("success");
                setError(false);
                setSuccess(true);
                setReload();
              } else {
                setAlertType("error");
                setFileName(null);
                setSuccess(false);
                setError(true);
              }
              setMessage(response?.results?.code);
            });
          }
        } else {
          setError(true);
          setAlertType("error");
          setMessage(result.message);
        }
        setLoading(false);
      });
    };

    const onSubmit = (event, status) => {
      //console.log(data);

      const translate = {
          ...category.translate,
          [lang] : data.translate,
      };

      const content = {
        ...category.content,
        [lang] : data.content,
    };


    //console.log(translate, content);
    //return false;

      if (status) {
        setLoading(true);
        _onSubmit(id, {
            translate : JSON.stringify(translate),
            content : JSON.stringify(content),
        }).then((response) => {
          if (response.status) {
            setAlertType("success");
            setSuccess(true);
            //setTimeout(() => props.onHide(), 500);
          } else {
            setError(true);
            setAlertType("error");
          }
          setMessage(response?.results?.code);
          setLoading(false);
        });
      }
    };

    return (
      <>
        <Form
          ref={refForm}
          onSubmit={(event) => handelOnSubmit(event, onSubmit)}
          autoComplete="off"
          className={`edit-question ${direction}`}
        >
          <Form.Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <Row>
                <Col>
                  <p className="title">
                    {localization.update} ({category.name})
                  </p>
                </Col>
                <Col lg="12" md="12" sm="12" xs="12" className={`${direction}`}>
                  {Array.isArray(message) ? (
                    message.map((item, index) => (
                      <Alert
                        key={index}
                        message={getTextFromCodes(item)}
                        show={error || success}
                        type={alertType}
                        onHide={() => {
                          setError(false);
                          setSuccess(false);
                        }}
                      />
                    ))
                  ) : (
                    <Alert
                      message={
                        localization[message] ? localization[message] : message
                      }
                      show={error || success}
                      type={alertType}
                      onHide={() => {
                        setError(false);
                        setSuccess(false);
                      }}
                    />
                  )}
                </Col>
                <Col lg="12" md="12" sm="12" xs="12">
                  <div className="question-image">
                    <div
                      className="image-handle"
                      onClick={() => refImage.current.click()}
                    >
                      <Image className="img-fluid" src={image} alt="" />
                      <div className="image-edit">
                        {<FiEdit size={82} color="#fff" />}
                      </div>
                    </div>
                  </div>
                </Col>
                {inputs.map((input, index) => {
                  return (
                    <React.Fragment key={index.toString()}>
                      <Col lg="12" md="12" sm="12" xs="12" className="input">
                        <label className={`${direction}`}>
                          {input.placeholder} <span> *</span>
                        </label>
                        <span className={`error form-text ${direction}`}>
                          {errors[input.name] &&
                            _localization(errors[input.name])}
                        </span>
                        {(input.type === "textarea" ||
                          input.type === "text") && (
                          <InputGroup
                            className={errors[input.name] ? "input-error" : ""}
                          >
                            <FormControl
                              dir="auto"
                              as={input.type}
                              placeholder={input.placeholder}
                              name={input.name}
                              defaultValue={category[input.name][lang]}
                              onChange={handelOnChange}
                            />
                          </InputGroup>
                        )}
                      </Col>
                    </React.Fragment>
                  );
                })}

                <Col className={`${direction}`}>
                  <Button
                    disabled={loading}
                    type="submit"
                    className="btn-submit btn-default"
                  >
                    {localization.update}
                    {loading && <div className="lds-dual-ring white"></div>}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Form.Row>
        </Form>
        <div>
          <input
            type="file"
            hidden
            ref={refImage}
            onChange={(event) => _fileUpdoad(event, "image")}
            accept="image/x-png,image/gif,image/jpeg"
            name="image"
          />
        </div>
      </>
    );
  };

  return <Modal show={props.show} onHide={props.onHide} Html={Html} />;
};

export default FormEditCategories;
