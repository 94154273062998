import React from "react";
import { Link } from "react-router-dom";
import { useLocalization, useAuth, useReload } from "../context";
import pages from "../pages";
import { FiUser } from "react-icons/fi";
import { useHistory } from "react-router-dom";

const Navbar = (props) => {
  const { localization, direction } = useLocalization();

  const { userData, setAuthUserData } = useAuth();

  const history = useHistory();

  const pathname = history.location.pathname;

  const { setReload } = useReload();

  const logOut = () => {
    setAuthUserData({}).then();
  };

  const Items = [
    {
      key: "profile",
      link: `/user/${userData.id}`,
      name: localization.myAccount,
      icon: () => <FiUser color="#fff" className="navbar-icon" />,
      class : " ",
    },
    ...pages(localization),
  ];

  return (
    <div className="container-navbar">
      <div
        className="nav-bar"
        style={
          direction === "rtl"
            ? { right: 0, borderTopLeftRadius: "50px" }
            : { left: 0, borderTopRightRadius: "50px" }
        }
      >
        {Items.map((item, index) => {
          const focus =
            pathname === item.link ? item.class + "focus" : item.class;
          //console.log(pathname === item.link);  
          return (
            <div key={index} className={`nav-item ${focus}`}>
              <Link
                replace={true}
                onClick={() =>
                  item.key === "logout"
                    ? logOut()
                    : item.key === "profile"
                    ? null
                    : setReload("All")
                }
                to={item.link}
              >
                {item.icon()}
                <p>{item.name}</p>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Navbar;
