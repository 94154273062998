/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Route, useHistory } from "react-router-dom";
import { useAuth } from "./context";
import Navbar from "./components/navbar";
import Header from "./components/header";
import { Container } from "react-bootstrap";

function PrivateRoute({
  component: Component,
  page,
  table,
  categories,
  ...rest
}) {
  const { authToken } = useAuth();
  const [loading, setLoading] = React.useState(true);
  const router = useHistory();

  React.useEffect(() => {
    let IsMounted = true;
    if (IsMounted) {
      if (authToken) {
        setLoading(false);
      } else {
        router.push('/login');
      }
    }
    return () => (IsMounted = false);
  }, [authToken]);

  if (loading) {
    return null;
  } else {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Container>
            <Navbar page={page} />
            <Header />
            <Component
              page={page}
              table={table}
              categories={categories}
              {...props}
            />
          </Container>
        )}
      />
    );
  }
}

export default PrivateRoute;