/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { HashRouter, Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { Context } from "./context";
import Login from "./pages/login";
import Home from "./pages/home";
import Users from "./pages/users";
import { postJson, getJson } from "./networking/server";
import Links from "./constants/Links";
import { _localization, locales } from "./localization";
import { reactLocalStorage } from "reactjs-localstorage";
import pages from "./pages";

import "bootstrap-v4-rtl/dist/css/bootstrap-rtl.min.css";
import "react-quill/dist/quill.snow.css";
import "./styles/index.css";
import "./styles/form.css";
import "./styles/loading.css";
import "./styles/alert.css";
import "./styles/navbar.css";
import "./styles/table.css";
import "./styles/results.css";

function App() {
  const existingToken = reactLocalStorage.get("token");
  const [authToken, setAuthToken] = useState(
    existingToken ? existingToken : undefined
  );
  const [userData, setUserData] = useState({});
  const [reload, setReload] = useState(false);
  const [localization, setLocalization] = useState(_localization("ar"));
  const [direction, setDirection] = useState("rtl");
  const [reloadTo, setReloadTo] = useState("");
  const [categories, setCategories] = useState([]);

  // console.log(userData);

  useEffect(() => {
    let isMounted = true;
    if (authToken) {
      postJson(Links.getUserByToken, { token: authToken }, authToken).then(
        (response) => {
          if (isMounted) {
            if (response.status) {
              const data = {
                ...response.results,
                token: authToken,
              };
              postJson(Links.check, { id: data.id }, authToken).then(
                (roles) => {
                  if (roles.status) {
                    data.role = roles.results;
                    _setUserData(data).then();
                  } else {
                    _setUserData({}).then();
                  }
                }
              );
            } else {
              _setUserData({}).then();
            }
          }
        }
      );
    }
    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    let isMounted = true;
    getJson(Links.categories, { limit: 20, offset: 0 }).then((response) => {
      if (isMounted) {
        if (response.status) {
          setCategories(response.results.data);
        }
      }
    });
    return () => (isMounted = false);
  }, [reload]);

  const _setUserData = async (data) => {
    if (data.token) {
      reactLocalStorage.set("token", data.token);
      setAuthToken(data.token);
      setUserData(data);
      return true;
    } else {
      reactLocalStorage.remove("token");
      setAuthToken(undefined);
      return false;
    }
    //console.log(data);
  };

  const getLang = async () => {
    const local = reactLocalStorage.getObject("lang");
    if (local) {
      return local;
    } else {
      return undefined;
    }
  };

  const setLang = async (local) => {
    reactLocalStorage.setObject("lang", local);
  };

  const _setLocalization = (lang) => {
    let local = {};

    if (lang && lang === "ar") {
      local = {
        lang: "ar",
        direction: "rtl",
      };
    } else if (lang && lang !== "ar") {
      if (!locales[lang]) {
        lang = "fr";
      }
      local = {
        lang: lang,
        direction: "ltr",
      };
    } else {
      getLang().then((_local) => {
        if (_local !== undefined) {
          local = {
            lang: _local.lang,
            direction: _local.direction,
          };
        } else {
          local = {
            lang: "fr",
            direction: "ltr",
          };
        }
        setDirection(local.direction);
        setLocalization(_localization(local.lang));
        setLang(local).then();
      });
      return false;
    }
    setDirection(local.direction);
    setLocalization(_localization(local.lang));
    setLang(local).then();
  };

  return (
    <Context.Provider
      value={{
        authToken,
        userData,
        reload,
        reloadTo: reloadTo,
        setReload: (status) => {
          setReloadTo(status ? status : "");
          setReload(!reload);
        },
        setAuthUserData: _setUserData,
        localization,
        direction,
        setLang: _setLocalization,
      }}
    >
      <HashRouter>
        <Switch>
          <Route path="/login" component={Login} />
          <PrivateRoute
            exact
            path="/"
            component={Users}
            page="users"
            table="users"
          />
          <PrivateRoute
            exact
            path="/user/:id"
            component={Users}
            page="users"
            table="users"
          />
          {pages(localization)
            .filter((page) =>
              userData.role === "manager" && page.key === "questions"
                ? true
                : userData.role === "admin"
            )
            .map((page, index) => {
              if (page.key !== "logout") {
                //console.log(page.key);
                return (
                  <PrivateRoute
                    key={index}
                    exact
                    path={page.link}
                    component={page.component}
                    page={page.key}
                    table={page.key}
                    categories={categories}
                  />
                );
              }
              return null;
            })}
          <Route path="*" exact component={Home} />
        </Switch>
      </HashRouter>
    </Context.Provider>
  );
}

export default App;