/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { useLocalization, useAuth } from "../context";
import { Container, Row, Col, DropdownButton, Dropdown } from "react-bootstrap";
import QuestionsTabel from "../components/questionsTabel";
import FormSearch from "../components/formSearch";
import useSearch from "../hooks/useSearch";
import useDb from "../hooks/useDb";
import { FiPlusSquare } from "react-icons/fi";
import FormAddQuestion from "../components/formAddQuestion";
import usePagination from "../hooks/usePagination";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
const Questions = ({ table, categories }) => {
  const { localization, direction } = useLocalization();
  const { authToken, userData } = useAuth();

  const {
    onSearch,
    searchQuerys,
    setSearchBy,
    setSearchValue,
    onSearchOption,
  } = useSearch("translate");
  const { removeFinelbyId, update, addData } = useDb(table);
  const [show, setShow] = React.useState(false);

  const { search, pathname } = useLocation();


  const { p } = queryString.parse(search);

  const count = 10;

  //console.log(p);

  const _add = () => {
    setTimeout(() => setShow(true), 150);
  };

  const {
    page,
    data,
    loading,
    more,
    paginate,
    setPage,
    pageCount,
  } = usePagination({
    url: "data",
    where: { table: table, ...searchQuerys },
    authToken: authToken,
    count: count,
    page: p,
    onPageChange : (_p) => {
      window.history.pushState(
        {},
        null,
        `${window.location.origin}/#${pathname}?p=${_p}`
      )
    },
  });

  const _onSearch = () => {
    setPage(1);
    onSearch();
  };

  /**
   * 
   * window.history.pushState(
      {},
      null,
      `${window.location.origin}/#${pathname}?p=${p}`
    )
   */
  return (
    <Container className={`container-app ${direction}`}>
      <Row>
        <Col>
          <div className="title">{localization.questions}</div>
        </Col>
      </Row>
      <Row>
        <Col lg="10" md="8" sm="12" xs="12">
          <FormSearch
            onSearch={_onSearch}
            searchBy={setSearchBy}
            searchValue={setSearchValue}
            table={table}
            searchOption={onSearchOption}
            categories={categories}
          />
        </Col>
        <Col lg="2" md="4" sm="12" xs="12">
          <div className="box btn-add">
            <DropdownButton
              className="dropdown-user"
              id="status"
              title={<FiPlusSquare size={26} />}
            >
              <Dropdown.Item onClick={() => _add()}>
                {localization.setQuestion}
                {localization.fr}
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <QuestionsTabel
            searchQuerys={searchQuerys}
            data={data}
            paginate={() => paginate(page + 1)}
            userData={userData}
            nbrOfPage={`${page} / ${pageCount}`}
            authToken={authToken}
            update={update}
            loading={loading || more}
            removeFinelbyId={removeFinelbyId}
          />
        </Col>
      </Row>
      <FormAddQuestion
        show={show}
        onHide={() => setShow(false)}
        lang={"fr"}
        categories={categories}
        onSubmit={addData}
      />
    </Container>
  );
};

export default Questions;
