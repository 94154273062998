/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import useValidation from "../hooks/useValidation";
import { useLocalization, useReload } from "../context";
import Codes from "../constants/codes";
import Alert from "../components/alert";
//import { postJson, goCoding } from "../networking/server";
import { MdLocationOn } from "react-icons/md";
import Modal from "../components/Modal";
//import Map from "../components/map";
import Config from "../appConfig";
import fileUpload from "../helps/fileUpload";

import { FormControl } from "react-bootstrap";

const FormUpdateAutoEcole = ({ autoecole, update, show, onHide, forView }) => {
  //console.log(autoecole);

  const { localization, direction } = useLocalization();
  const {setReload} = useReload();

  const _localization = (key) => {
    if (localization[key]) {
      return localization[key];
    }
    return key;
  };

  const _years = (min = 1990) => {
    const years = {};

    const year = new Date().getFullYear();

    for (let i = min; i <= year; i++) {
      years[i] = i;
    }

    return Array.prototype.reverse.call(years);
  };

  const FormHtml = () => {
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [alertType, setAlertType] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    //const [showMap, setShowMap] = useState(false);

    const imageUrl = `${Config.pic}`;

    const [requires, setRequires] = useState([]);

    const {
      errors,
      handelOnChange,
      data,
      handelOnSubmit,
      refForm,
      RefEvent,
      setData,
    } = useValidation(requires);

    const moniteursTamp = (
      index,
      value = { name: "", genre: "", image: "" }
    ) => {
      return [
        {
          name: `moniteur_name_${index}`,
          type: "text",
          value: value?.name ? value?.name : null,
          index,
          props: {
            disabled: forView,
          },
          placeholder: `${localization.name} ${localization.moniteur}`,
        },
        {
          name: `moniteur_genre_${index}`,
          type: "select",
          value: value?.genre ? value?.genre : null,
          index,
          props: {
            disabled: forView,
          },
          placeholder: `${localization.genre[0]} ${localization.moniteur}`,
          items: {
            male: localization.male,
            female: localization.female,
          },
        },
        {
          name: `moniteur_image_${index}`,
          type: "file",
          value: value?.image ? value?.image : null,
          index,
          props: {
            //style: { display: "none" },
            disabled: forView,
          },
          placeholder: ``,
        },
      ];
    };

    const automobilesTamp = (
      index,
      value = { name: "", type: "", image: "" }
    ) => {
      return [
        {
          name: `automobile_name_${index}`,
          type: "text",
          value: value?.name ? value?.name : null,
          index,
          props: {
            disabled: forView,
          },
          placeholder: `${localization.model}`,
        },
        {
          name: `automobile_year_${index}`,
          type: "select",
          value: value?.year ? value?.year : null,
          index,
          props: {
            disabled: forView,
          },
          placeholder: `${localization.year}`,
          items: _years(),
        },
        {
          name: `automobile_type_${index}`,
          type: "select",
          value: value?.type ? value?.type : null,
          index,
          props: {
            disabled: forView,
          },
          placeholder: `${localization.gearbox}`,
          items: {
            auto: localization.auto,
            manual: localization.manual,
          },
        },
        {
          name: `automobile_image_${index}`,
          type: "file",
          value: value?.image ? value?.image : null,
          index,
          props: {
            //style: { display: "none" },
            disabled: forView,
          },
          placeholder: ``,
        },
      ];
    };

    const [moniteurs, setMoniteur] = useState([moniteursTamp(0)]);
    const [automobiles, setAutomobiles] = useState([automobilesTamp(0)]);
    const [location, setLocation] = useState([]);

    const inputs = [
      {
        name: "name",
        type: "text",
        value: "",
        props: {
          disabled: forView,
        },
        placeholder: `${localization.name} ${localization.autoEcole}`,
      },
      {
        name: "address",
        type: "text",
        value: "",
        props: {
          disabled: forView,
        },
        placeholder: localization.address,
      },
    ];

    const onChange = (e, name) => {
      fileUpload(e.target.files, (result) => {
        if (result.status) {
          if (result.filebase64) {
            setData({
              ...data,
              [name]: result.filebase64,
            });
          }
        } else {
          if (result.message) {
            setData({
              ...data,
              [name]: null,
            });
          }
        }
      });
    };

    const setDataToForm = () => {
      //let _requiresMon = [];
      //let _requiresAuto = [];

      const _moniteur = autoecole.moniteur.map((item, index) => {
        return moniteursTamp(index, item);
      });

      const _automobile = autoecole.automobile.map((item, index) => {
        return automobilesTamp(index, item);
      });

      if (_automobile.length >= 1) {
        setAutomobiles(_automobile);
        //_requiresMon = _moniteur.map((item) => item[item.length - 1].name);
      }

      if (_moniteur.length >= 1) {
        setMoniteur(_moniteur);
      }

      //setRequires([..._requiresAuto, ..._requiresMon]);
    };

    useEffect(() => {
      setData(RefEvent());
      const _requiresAuto = automobiles.map(
        (item) => item[item.length - 1].name
      );
      const _requiresMon = moniteurs.map((item) => item[item.length - 1].name);
      setRequires([..._requiresAuto, ..._requiresMon]);
    }, [moniteurs, automobiles, autoecole]);

    useEffect(() => {
      setDataToForm();
      setLocation(autoecole.location);
      setData({
        ...data,
        name: autoecole.name,
        address: autoecole.address,
      });
    }, [autoecole]);

    //console.log(moniteurs, automobiles);

    const addMonieur = () => {
      const index = moniteurs[moniteurs.length - 1]
        ? moniteurs[moniteurs.length - 1][0].index
        : -1;
      setMoniteur([...moniteurs, moniteursTamp(index + 1)]);
    };

    const removeMonieur = (index) => {
      const items = moniteurs.filter((item, _index) => _index !== index);
      setMoniteur(items);
    };

    const addAutomobile = () => {
      const index = automobiles[automobiles.length - 1]
        ? automobiles[automobiles.length - 1][0].index
        : -1;
      setAutomobiles([...automobiles, automobilesTamp(index + 1)]);
    };

    const removeAutomobile = (index) => {
      const items = automobiles.filter((item, _index) => _index !== index);
      setAutomobiles(items);
    };

    const getTextFromCodes = (code) => {
      if (!code) {
        return "";
      }
      return _localization(Codes[code].text);
    };

    const _onSubmitUpdate = (event, status) => {
      event.preventDefault();
      setLoading(true);
      //console.log(data);

      let _moniteurs = [];
      let _automobiles = [];

      moniteurs.map((moniteur) => {
        return moniteur.map((item) => {
          const name = `moniteur_name_${item.index}`;
          const genre = `moniteur_genre_${item.index}`;
          const image = `moniteur_image_${item.index}`;
          if (data[name] && data[genre]) {
            _moniteurs[item.index] = {
              name: data[name],
              genre: data[genre],
              image: data[image],
            };
            if (data[image]) {
              _moniteurs[item.index].image = data[image];
            } else {
              if (image === item.name) {
                _moniteurs[item.index].image = item.value;
              }
            }
          }
          return true;
        });
      });

      automobiles.map((automobile) => {
        return automobile.map((item) => {
          const name = `automobile_name_${item.index}`;
          const type = `automobile_type_${item.index}`;
          const year = `automobile_year_${item.index}`;
          const image = `automobile_image_${item.index}`;
          if (data[name] && data[type] && data[year]) {
            _automobiles[item.index] = {
              name: data[name],
              type: data[type],
              year: data[year],
            };
            if (data[image]) {
              _automobiles[item.index].image = data[image];
            } else {
              if (image === item.name) {
                _automobiles[item.index].image = item.value;
              }
            }
          }
          return true;
        });
      });

      //console.log(data);

      //return false;

      if (status) {
        _automobiles = _automobiles.filter((item) => item);
        _moniteurs = _moniteurs.filter((item) => item);
        update(autoecole.id, {
          automobile: JSON.stringify(_automobiles),
          moniteur: JSON.stringify(_moniteurs),
          address: data.address,
          name: data.name,
          location: JSON.stringify(location),
        }).then((response) => {
          //console.log(response);
          if (response.status) {
            setAlertType("success");
            setSuccess(true);
            setError(false);
            setReload('All');
          } else {
            setAlertType("error");
            setError(true);
            setSuccess(false);
          }
          setMessage(response?.results?.code);
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    };

    return (
      <div className="col-12 p-0">
        <form
          className={`profile-form ${direction}`}
          onSubmit={(event) => handelOnSubmit(event, _onSubmitUpdate)}
          ref={refForm}
        >
          {Array.isArray(message) ? (
            message.map((item, index) => (
              <Alert
                key={index}
                message={getTextFromCodes(item)}
                show={error || success}
                type={alertType}
                onHide={() => {
                  setError(false);
                  setSuccess(false);
                }}
              />
            ))
          ) : (
            <Alert
              message={message}
              show={error || success}
              type={alertType}
              onHide={() => {
                setError(false);
                setSuccess(false);
              }}
            />
          )}

          <div className="card p-3">
            <div className="title">
              <h4>{_localization("updateAutoEcoleInfo")}</h4>
            </div>
            {inputs.map((input, index) => {
              //console.log(errors[input.name] ? true : false);
              return (
                <div key={index}>
                  <span className="error form-text">
                    {errors[input.name] && _localization(errors[input.name])}
                  </span>
                  <div
                    className={`form-group input-group${
                      errors[input.name] ? " input-error" : ""
                    }`}
                  >
                    <FormControl
                      type={input.type}
                      name={input.name}
                      defaultValue={
                        data[input.name]
                          ? data[input.name]
                          : autoecole[input.name]
                      }
                      {...input.props}
                      //className={`form-control`}
                      onChange={handelOnChange}
                      placeholder={input.placeholder}
                    />
                    {input.name === "address" && (
                      <span
                        className="input-group-text input-icon"
                        //onClick={() => setShow(true)}
                      >
                        <MdLocationOn className="icon" />
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="card mt-3 p-3">
            <div className="title">
              <h4>{_localization("moniteursInfo")}</h4>
            </div>
            {moniteurs.map((item, index) => {
              const [genre] = item.filter(
                (_) => _.name === `moniteur_genre_${index}`
              );

              const [image] = item.filter(
                (_) => _.name === `moniteur_image_${index}`
              );

              const _image =
                image && image.value
                  ? image.value
                  : `${imageUrl}profile.${
                      genre && genre.value ? `${genre.value}.` : ""
                    }png`;

              return (
                <div className="group row" key={index}>
                  <div className="profile-box col-xl-3 col-lg-3 col-md-5 col-sm-12">
                    <div className="pic">
                      <img
                        alt=""
                        src={
                          data[`moniteur_image_${index}`]
                            ? data[`moniteur_image_${index}`]
                            : `${_image}`
                        }
                      />
                    </div>
                  </div>
                  <div className="col-xl-9 col-lg-9 col-md-7 col-sm-12">
                    {item.map((input, _index) => {
                      const key = `${input.name}`;
                      return (
                        <div key={key}>
                          <span className="error form-text">
                            {errors[key] && _localization(errors[key])}
                          </span>
                          <div
                            className={`form-group input-group${
                              errors[key] ? " input-error" : ""
                            }`}
                          >
                            {input.type === "select" ? (
                              <FormControl
                                name={key}
                                as={input.type}
                                defaultValue={input.value}
                                //className={`form-control`}
                                onChange={handelOnChange}
                                {...input.props}
                                placeholder={input.placeholder}
                              >
                                <option value={input.value ? input.value : ""}>
                                  {input.value
                                    ? _localization(input.value)
                                    : input.placeholder}
                                </option>
                                {Object.keys(input.items).map((_key) => (
                                  <option key={_key} value={_key}>
                                    {input.items[_key]}
                                  </option>
                                ))}
                              </FormControl>
                            ) : (
                              <>
                                <FormControl
                                  type={input.type}
                                  name={key}
                                  defaultValue={
                                    data[key]
                                      ? data[key]
                                      : input.type === "file"
                                      ? null
                                      : input?.value
                                  }
                                  //className={`form-control`}
                                  onChange={
                                    input.type === "file"
                                      ? (e) => onChange(e, input.name)
                                      : (e) => handelOnChange(e)
                                  }
                                  {...input.props}
                                  placeholder={input.placeholder}
                                />
                                {index !== 0 && _index === 0 && (
                                  <span
                                    className="input-group-text delete"
                                    onClick={() => removeMonieur(index)}
                                  >
                                    {_localization("delete")}
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}

            {!forView && (
              <div>
                <button
                  className={`btn btn-sec float-${
                    localization._locale === "ar" ? "left" : "right"
                  }`}
                  type="button"
                  onClick={addMonieur}
                >
                  {localization.Add} {localization.moniteur}
                </button>
              </div>
            )}
          </div>

          <div className="card mt-3 p-3">
            <div className="title">
              <h4>{_localization("automobileInfo")}</h4>
            </div>
            {automobiles.map((item, index) => {
              const [image] = item.filter(
                (_) => _.name === `automobile_image_${index}`
              );
              const _image =
                image && image.value ? image.value : `${imageUrl}car.jpg`;

              return (
                <div className="group row" key={index}>
                  <div className="profile-box col-xl-3 col-lg-3 col-md-5 col-sm-12">
                    <div className="pic">
                      <img
                        className="rounded"
                        alt=""
                        src={
                          data[`automobile_image_${index}`]
                            ? data[`automobile_image_${index}`]
                            : `${_image}`
                        }
                      />
                    </div>
                  </div>
                  <div className="col-xl-9 col-lg-9 col-md-7 col-sm-12">
                    {item.map((input, _index) => {
                      const key = `${input.name}`;
                      return (
                        <div key={key}>
                          <span className="error form-text">
                            {errors[key] && _localization(errors[key])}
                          </span>
                          <div
                            className={`form-group input-group${
                              errors[key] ? " input-error" : ""
                            }`}
                          >
                            {input.type === "select" ? (
                              <select
                                name={key}
                                defaultValue={input.value}
                                className={`form-control`}
                                onChange={handelOnChange}
                                {...input.props}
                                placeholder={input.placeholder}
                              >
                                <option value={input.value ? input.value : ""}>
                                  {input.value
                                    ? _localization(input.value)
                                    : input.placeholder}
                                </option>
                                {Object.keys(input.items).map((_key) => (
                                  <option key={_key} value={_key}>
                                    {input.items[_key]}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              <>
                                <input
                                  type={input.type}
                                  name={key}
                                  defaultValue={
                                    data[key]
                                      ? data[key]
                                      : input.type === "file"
                                      ? null
                                      : input?.value
                                  }
                                  className={`form-control`}
                                  onChange={
                                    input.type === "file"
                                      ? (e) => onChange(e, input.name)
                                      : (e) => handelOnChange(e)
                                  }
                                  {...input.props}
                                  placeholder={input.placeholder}
                                />
                                {index !== 0 && _index === 0 && (
                                  <span
                                    className="input-group-text delete"
                                    onClick={() => removeAutomobile(index)}
                                  >
                                    {_localization("delete")}
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
            <div>
              {!forView && (
                <button
                  className={`btn btn-sec float-${
                    localization._locale === "ar" ? "left" : "right"
                  }`}
                  type="button"
                  onClick={addAutomobile}
                >
                  {_localization("Add")} {_localization("car")}
                </button>
              )}
            </div>
          </div>

          {!forView && (
            <div
              className={`float-${
                localization._locale === "ar" ? "left" : "right"
              }`}
            >
              <button
                type="submit"
                className={`btn btn-default btn-submit mt-4 ${direction}`}
              >
                {_localization("update")}
                {loading && <div className="lds-dual-ring white"></div>}
              </button>
            </div>
          )}
        </form>
        {/*<Model show={show} onHide={() => setShow(false)} Html={Html} />*/}
      </div>
    );
  };

  /*return (
    <Modal
      animation={false}
      size="lg"
      show={show}
      onHide={onHide}
      className={`edit-cours ${direction}`}
    >
      <Modal.Header closeButton>
        <div className="title"></div>
      </Modal.Header>
      <Modal.Body className="pt-0 pb-0">
        <Row className={`p-4`}>
          <FormHtml />
        </Row>
      </Modal.Body>
    </Modal>
  );*/

  return <Modal show={show} onHide={onHide} Html={FormHtml} />;
};

export default FormUpdateAutoEcole;
