//import Home from "./home";
import Users from "./users";
import Series from "./series";
import Questions from "./questions";
import Cours from "./cours";
import Settings from "./settings";
import Categories from "./categories";
import AutoEcole from "./autoEcole";
import Pages from "./pages";

import { FiSettings, FiUsers, FiLogOut, FiList } from "react-icons/fi";
import {BsQuestionOctagon, BsGrid} from "react-icons/bs";
import {ImNewspaper} from 'react-icons/im';
import {MdLocalTaxi} from 'react-icons/md';
import {RiPagesFill} from "react-icons/ri";


const pages = (localization) => [
  {
    key: 'users',
    name: localization.users,
    link: "/users",
    icon: () => <FiUsers color="#fff" className="navbar-icon" />,
    component : Users,
    class: "",
  },
  {
    key: 'series',
    name: localization.series,
    link: "/series",
    icon: () => <FiList color="#fff" className="navbar-icon" />,
    component : Series,
    class: "",
  },
  {
    key: 'questions',
    name: localization.questions,
    link: "/questions",
    icon: () => <BsQuestionOctagon color="#fff" className="navbar-icon" />,
    component : Questions,
    class: "",
  },
  {
    key: 'cours',
    name: localization.cours,
    link: "/cours",
    icon: () => <ImNewspaper color="#fff" className="navbar-icon" />,
    component : Cours,
    class: "",
  },
  {
    key: 'autoecole',
    name: localization.autoEcole,
    link: "/autoecole",
    icon: () => <MdLocalTaxi color="#fff" className="navbar-icon" />,
    component : AutoEcole,
    class: "",
  },
  {
    key: 'categories',
    name: localization.categories,
    link: "/categories",
    icon: () => <BsGrid color="#fff" className="navbar-icon" />,
    component : Categories,
    class: "",
  },
  {
    key: 'pages',
    name: localization.pages,
    link: "/pages",
    icon: () => <RiPagesFill color="#fff" className="navbar-icon" />,
    component : Pages,
    class: "",
  },
  {
    key: 'settings',
    name: localization.settings,
    link: "/settings",
    icon: () => <FiSettings color="#fff" className="navbar-icon" />,
    component : Settings,
    class: "",
  },
  {
    key: 'logout',
    name: localization.logout,
    link: "#",
    icon: () => <FiLogOut color="#fff" className="navbar-icon" />,
    component : () => null,
    class: "logout-icon",
  }
];

export default pages;
