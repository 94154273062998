import React from "react";
import {
  Table,
  Image,
  Button,
  DropdownButton,
  Dropdown,
  Row,
  Col,
} from "react-bootstrap";
import { useLocalization } from "../context";

import Swal from "../constants/swal";

import FormUpdateUser from "./formUpdateUser";

import FormPasswordUpdate from "./formPasswordUpdate";

import { FiTrash2, FiEye, FiEdit, FiLock } from "react-icons/fi";
import { IoIosMore } from "react-icons/io";
import Config from "../appConfig";

const UsersTabel = ({
  userData,
  data,
  paginate,
  update,
  removeFinelbyId,
  nbrOfPage,
  loading,
}) => {
  const { localization } = useLocalization();

  const [modalShowUpdateuser, setModalShowUpdateuser] = React.useState(false);
  const [modalShowPasswordUpdate, setModalShowPasswordUpdate] = React.useState(
    false
  );
  const [userDataforUpdate, setUserDataforUpdate] = React.useState({});
  const role = userData.role;
  const [forView, setForView] = React.useState(false);

  const _update = update;

  //console.log(userData);

  const _deleteFinel = async (item) => {
    if (role !== "admin") {
      return false;
    }

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger m-2",
        cancelButton: "btn btn-secondary",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons.fire({
      title: localization.removeFinel,
      confirmButtonText: localization.delete,
      showCancelButton: true,
      cancelButtonText: localization.cancel,
      text: "",
      //icon: 'warning',
      //showLoaderOnConfirm: true,
      reverseButtons: true,
      preConfirm: () => {
        removeFinelbyId(item.id).then((result) => {
          if (result.status) {
            Swal.fire(localization.deleteIsSuccess, "", "success").then();
          } else {
            Swal.fire(localization.deleteIsError, "", "error").then();
          }
        });
      },
    });
  };

  const _edit = (item) => {
    //console.log(item);
    //if (role === 1) {
    setUserDataforUpdate(item);
    setTimeout(() => {
      setModalShowUpdateuser(true);
    }, 150);
    //}
  };

  const _editPassword = (item) => {
    //console.log(item);
    if (role) {
      setUserDataforUpdate(item);
      setModalShowPasswordUpdate(true);
    }
  };

  return (
    <>
      <div className="box table-app">
        <Table responsive>
          <thead>
            <tr>
              <th>{localization.name}</th>
              <th>{localization.roles[0]}</th>
              <th>{localization.status}</th>
              <th>{localization.moreInfo}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              let style = { backgroundColor: "#ffffff" };
              let colorWhite = "";
              let styleBorderColor = {};
              if (!parseInt(item.active)) {
                style = { backgroundColor: "#cdcdcd" };
                colorWhite = "text-white";
                styleBorderColor = { borderColor: "#fff" };
              }
              return (
                <tr key={index} style={style}>
                  <td className={colorWhite}>
                    <Image
                      style={styleBorderColor}
                      src={`${Config.pic}${item.image}`}
                      className="table-pic"
                      roundedCircle
                    />
                    <span
                      style={{
                        display: "inline-grid",
                        lineHeight: 1,
                      }}
                    >
                      {item.fullname}
                      <span className="subline">{item.email}</span>
                    </span>
                  </td>
                  <td>
                    <span className={colorWhite}>
                      {localization[item.role]}
                    </span>
                  </td>
                  <td className={colorWhite}>
                    <span>
                      {parseInt(item.active) === 1
                        ? localization.active
                        : localization.disactive}
                    </span>
                  </td>
                  <td className={`more-info ${colorWhite}`}>
                    <p>
                      {localization.phone} : <span>{item.phone}</span>
                    </p>
                  </td>

                  <td className="btn-table">
                    <Button
                      onClick={() => {
                        setForView(true);
                        _edit(item);
                      }}
                    >
                      <FiEye size={20} color="#fff" />
                    </Button>
                    <Button
                      variant="info"
                      onClick={() => {
                        setForView(false);
                        _edit(item);
                      }}
                    >
                      <FiEdit size={20} color="#fff" />
                    </Button>
                    <DropdownButton
                      drop={"down"}
                      variant="secondary"
                      className="dropdown-more"
                      id="tt"
                      title={<IoIosMore color="#fff" fontSize="24px" />}
                    >
                      <Button
                        variant="danger"
                        onClick={() => _deleteFinel(item)}
                      >
                        <FiTrash2 size={20} color="#fff" /> {localization.finel}
                      </Button>

                      <Button onClick={() => _editPassword(item)}>
                        <FiLock color="#fff" fontSize="22px" />
                      </Button>
                      <DropdownButton
                        variant="secondary"
                        className="dropdown-user"
                        id="status"
                        title={localization.status}
                      >
                        <Dropdown.Item
                          onClick={() => _update(item.id, { active: 1 })}
                        >
                          {localization.active}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => _update(item.id, { active: "0" })}
                        >
                          {localization.disactive}
                        </Dropdown.Item>
                      </DropdownButton>
                    </DropdownButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <Row>
        <Col lg={4} md={4} sm={1} xs={12} />
        <Col lg={4} md={4} sm={10} xs={12}>
          <button className="btn more w-100" onClick={paginate}>
            <span className="more-text">
              {localization.more}
              {' - '}{localization.page} {nbrOfPage}
              {loading && <div className="lds-dual-ring"></div>}
            </span>
          </button>
        </Col>
        <Col lg={4} md={4} sm={1} xs={12} />
      </Row>
      <FormUpdateUser
        show={modalShowUpdateuser}
        onHide={() => setModalShowUpdateuser(false)}
        userData={userDataforUpdate}
        adminData={userData}
        update={_update}
        forView={forView}
      />
      <FormPasswordUpdate
        show={modalShowPasswordUpdate}
        onHide={() => setModalShowPasswordUpdate(false)}
        userData={userDataforUpdate}
        adminData={userData}
      />
    </>
  );
};

export default UsersTabel;
