/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import {
  Image,
  Button,
  Form,
  InputGroup,
  Col,
  FormControl,
  Row,
  Modal,
} from "react-bootstrap";
import { useLocalization } from "../context";
import Codes from "../constants/codes";
import useValidation from "../hooks/useValidation";
import Alert from "../components/alert";
//import Modal from "./Modal";
import { FiEdit } from "react-icons/fi";
import fileUpload from "../helps/fileUpload";
import ReactQuill from "react-quill";

const FormAddCours = (props) => {
  const { localization, direction } = useLocalization();

  const _onSubmit = props.onSubmit;
  const coursLang = props.lang;
  const categories = props.categories;

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],

      //[{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction

      //[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      //[{ 'font': [] }],
      [{ align: [] }],
      ["link", "image", "video"],
      ["clean"], // remove formatting button
    ],
  };

  const _localization = (key) => {
    return localization[key];
  };

  const getTextFromCodes = (code) => {
    if (!code) {
      return "";
    }
    return _localization(Codes[code].text);
  };

  const inputs = [
    {
      name: "category",
      value: "",
      type: "select",
      items: categories.map((category) => {
        return {
          name: category.name,
          translate: category.translate[coursLang],
        };
      }),
      subItems: {
        name: "subCategory",
        value: "",
        type: "select",
        items: (name) => {
          return categories.filter((category) => category.name === name);
        },
      },
    },
    {
      name: "title",
      value: "",
      type: "textarea",
    },
    {
      name: "content",
      value: "",
      type: "quill",
    },
  ];

  //console.log(props.question);

  const Html = () => {
    const inputNotReq = [];

    const {
      errors,
      handelOnChange,
      data,
      handelOnSubmit,
      setData,
      RefEvent,
      refForm,
    } = useValidation(inputNotReq);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [alertType, setAlertType] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const [image, setImage] = useState(null);
    const refImage1 = useRef(null);
    const [content, setContent] = useState("");
    const [file, setFile] = useState(null);
    const [submitDisable, setSubmitDisable] = useState(false);

    useEffect(() => {
      setTimeout(() => setData(RefEvent()), 800);
      //console.log(refForm);
    }, [refForm]);

    //console.log(isOpen);

    const _fileUpdoad = (event, name) => {
      //const _data = {};
      setLoading(true);
      fileUpload(event.target.files, (result) => {
        //console.log(result);
        if (result.status) {
          if (result.filebase64) {
            setImage(result.filebase64);
            setFile(result.file);
            setError(false);
          }
        } else {
          setError(true);
          setAlertType("error");
          setMessage(result.message);
        }
        setLoading(false);
      });
    };

    //console.log(rightAnswer);

    const onSubmit = (event, status) => {
      event.preventDefault();

      const langs = ["ar", "fr"];
      const [key] = langs.filter((l) => l !== coursLang);

      if (!content && content?.trim() === "") {
        setError(true);
        setAlertType("error");
        setMessage("FieldEmptyInput");
        return false;
      }

      if (!file) {
        setError(true);
        setAlertType("error");
        setMessage("FieldEmptyInput");
        return false;
      }

      const _content = {
        [coursLang]: {
          title: data.title,
          content,
          status: true,
        },
        [key]: {
          title: "",
          content: "",
          status: false,
        },
      };

      //console.log(data, _content);

      //return false;
      if (status && !submitDisable) {
        setLoading(true);
        _onSubmit("newCours", {
          category: data.category,
          subCategory: data.subCategory,
          content: JSON.stringify(_content),
          image: file,
        }).then((response) => {
          if (response.status) {
            setAlertType("success");
            setSuccess(true);
            setTimeout(() => props.onHide(), 500);
            setSubmitDisable(true);
          } else {
            setError(true);
            setAlertType("error");
            setSubmitDisable(false);
          }
          setMessage(response?.results?.code);
          setLoading(false);
        });
      }
    };

    //console.log(content);

    return (
      <>
        <Form
          ref={refForm}
          onSubmit={(event) => handelOnSubmit(event, onSubmit)}
          autoComplete="off"
          className={`edit-question ${direction}`}
        >
          <Form.Row>
            <Col lg="12" md="12" sm="12" xs="12">
              {/*<Image src={image} />*/}
            </Col>
            <Col lg="12" md="12" sm="12" xs="12">
              <Row>
                <Col lg="12" md="12" sm="12" xs="12" className={`${direction}`}>
                  {Array.isArray(message) ? (
                    message.map((item, index) => (
                      <Alert
                        key={index}
                        message={getTextFromCodes(item)}
                        show={error || success}
                        type={alertType}
                        onHide={() => {
                          setError(false);
                          setSuccess(false);
                        }}
                      />
                    ))
                  ) : (
                    <Alert
                      message={
                        localization[message] ? localization[message] : message
                      }
                      show={error || success}
                      type={alertType}
                      onHide={() => {
                        setError(false);
                        setSuccess(false);
                      }}
                    />
                  )}
                </Col>
                <Col lg="12" md="12" sm="12" xs="12">
                  <div className="question-image">
                    <div
                      className="image-handle"
                      onClick={() => refImage1.current.click()}
                    >
                      <Image className="img-fluid" src={image} alt="" />
                      <div className="image-edit">
                        {<FiEdit size={82} color="#fff" />}
                      </div>
                    </div>
                  </div>
                </Col>

                {inputs.map((input, index) => {
                  return (
                    <React.Fragment key={index.toString()}>
                      <Col lg="12" md="12" sm="12" xs="12" className="input">
                        <label className={`${direction}`}>
                          {localization[input.name]} <span> *</span>
                        </label>
                        <span className={`error form-text ${direction}`}>
                          {errors[input.name] &&
                            _localization(errors[input.name])}
                        </span>

                        {input.type === "select" && (
                          <React.Fragment>
                            <InputGroup
                              className={
                                errors[input.name] ? "input-error" : ""
                              }
                            >
                              <FormControl
                                dir="auto"
                                as={input.type}
                                placeholder={localization[input.name]}
                                name={input.name}
                                defaultValue={input.value}
                                onChange={handelOnChange}
                              >
                                <option value={""}>{"-------------"}</option>
                                {input.items.map((item, index) => {
                                  //console.log(item);
                                  return (
                                    <React.Fragment key={index}>
                                      <option value={item.name}>
                                        {item.translate}
                                      </option>
                                    </React.Fragment>
                                  );
                                })}
                              </FormControl>
                            </InputGroup>
                            {input.subItems &&
                              input.subItems
                                .items(data[input.name])
                                .map((subItem, _index) => {
                                  //subCategories
                                  //console.log(subItem);
                                  return (
                                    <div className="sub-item" key={_index}>
                                      <label>
                                        {localization[input.subItems.name]}{" "}
                                        <span> *</span>
                                      </label>
                                      <span className="error form-text">
                                        {errors[input.subItems.name] &&
                                          _localization(
                                            errors[input.subItems.name]
                                          )}
                                      </span>
                                      <InputGroup
                                        className={
                                          errors[input.subItems.name]
                                            ? "input-error"
                                            : ""
                                        }
                                      >
                                        <FormControl
                                          dir="auto"
                                          as={input.subItems.type}
                                          placeholder={
                                            localization[input.subItems.name]
                                          }
                                          name={input.subItems.name}
                                          defaultValue={input.subItems.value}
                                          onChange={handelOnChange}
                                        >
                                          <option value={""}>
                                            {"-------------"}
                                          </option>
                                          {subItem.subCategories.map(
                                            (item, index) => {
                                              //console.log(item);
                                              return (
                                                <React.Fragment key={index}>
                                                  <option value={item.name}>
                                                    {item.translate[coursLang]}
                                                  </option>
                                                </React.Fragment>
                                              );
                                            }
                                          )}
                                        </FormControl>
                                      </InputGroup>
                                    </div>
                                  );
                                })}
                          </React.Fragment>
                        )}
                        {(input.type === "textarea" ||
                          input.type === "text") && (
                          <InputGroup
                            className={errors[input.name] ? "input-error" : ""}
                          >
                            <FormControl
                              dir="auto"
                              as={input.type}
                              placeholder={localization[input.name]}
                              name={input.name}
                              defaultValue={input.value}
                              onChange={handelOnChange}
                            />
                          </InputGroup>
                        )}
                        {input.type === "quill" && (
                          <div>
                            <ReactQuill
                              theme="snow"
                              modules={modules}
                              //style={{minHeight: "400px"}}
                              style={{
                                direction: coursLang === "ar" ? "rtl" : "ltr",
                              }}
                              onChange={setContent}
                              value={content}
                              name={input.name}
                            />
                          </div>
                        )}
                      </Col>
                    </React.Fragment>
                  );
                })}

                <Col className={`${direction}`}>
                  <Button
                    disabled={submitDisable}
                    type="submit"
                    className="btn-submit btn-default"
                  >
                    {localization.Add}
                    {loading && <div className="lds-dual-ring white"></div>}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Form.Row>
        </Form>
        <div>
          <input
            type="file"
            hidden
            ref={refImage1}
            onChange={(event) => _fileUpdoad(event, "image")}
            accept="image/x-png,image/gif,image/jpeg"
            name="image"
          />
        </div>
      </>
    );
  };

  //return <Modal show={props.show} onHide={props.onHide} Html={Html} />;

  return (
    <Modal
      animation={false}
      size="lg"
      show={props.show}
      onHide={props.onHide}
      className={`edit-cours ${direction}`}
    >
      <Modal.Header closeButton>
        <div className="title">
          {localization.setCours}
          {localization[coursLang]}
        </div>
      </Modal.Header>
      <Modal.Body className="pt-0 pb-0">
        <Row className={`p-4`}>
          <Html />
        </Row>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default FormAddCours;
