/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocalization, useAuth } from "../context";
import {
  Container,
  Row,
  Col,
  Button,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import useDb from "../hooks/useDb";
import usePagination from "../hooks/usePagination";
import Alert from "../components/alert";
import Codes from "../constants/codes";
import Modal from "../components/Modal";
import ReactQuill from "react-quill";

const Pages = ({ table }) => {
  const { localization, direction } = useLocalization();
  const { authToken } = useAuth();
  const { update } = useDb(table);
  const [dataPage, setDataPage] = useState("");
  const [show, setShow] = useState(false);

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],

      //[{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction

      //[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      //[{ 'font': [] }],
      [{ align: [] }],
      ["link", "image", "video"],
      ["clean"], // remove formatting button
    ],
  };

  const _localization = (key) => {
    return localization[key];
  };

  const getTextFromCodes = (code) => {
    if (!code) {
      return "";
    }
    return _localization(Codes[code].text);
  };

  const { data } = usePagination({
    url: "data",
    where: { table: table },
    authToken: authToken,
    count: 20,
  });

  //console.log(values);

  const Html = () => {
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [alertType, setAlertType] = useState("");
    const [message, setMessage] = useState("");
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [content, setContent] = useState("");
    const [id, setId] = useState(null);
    const [lang, setLang] = useState("ar");
    const [name, setName] = useState("");

    useEffect(() => {
      setContent(dataPage.text);
      setName(dataPage.name);
      setId(dataPage.id);
      setLang(dataPage.local);
    }, [dataPage]);

    const _update = () => {
      if(!name){
        return false;
      }  
      setLoadingUpdate(true);
      update(id, {
        text: content,
        name : name,
      }).then((response) => {
        if (response.status) {
          setAlertType("success");
          setSuccess(true);
        } else {
          setError(true);
          setAlertType("error");
        }
        setMessage(response?.results?.code);
        setLoadingUpdate(false);
      });
    };

    return (
      <Row>
        <Col lg="12" md="12" sm="12" xs="12" className={`${direction}`}>
          {Array.isArray(message) ? (
            message.map((item, index) => (
              <Alert
                key={index}
                message={getTextFromCodes(item)}
                show={error || success}
                type={alertType}
                onHide={() => {
                  setError(false);
                  setSuccess(false);
                }}
              />
            ))
          ) : (
            <Alert
              message={localization[message] ? localization[message] : message}
              show={error || success}
              type={alertType}
              onHide={() => {
                setError(false);
                setSuccess(false);
              }}
            />
          )}
        </Col>
        <Col lg="12" md="12" sm="12" xs="12" className="input">
          <label className={`${direction}`}>
            {localization.name} ({dataPage.slug}) ({localization[lang]}){" "}
            <span> *</span>
          </label>

          <InputGroup className='mb-2'>
            <FormControl
              dir="auto"
              placeholder={""}
              name={"name"}
              defaultValue={name}
              onChange={(event) => setName(event.target.value)}
            />
          </InputGroup>

          <label className={`${direction}`}>
            {localization.content} ({dataPage.slug}) ({localization[lang]}){" "}
            <span> *</span>
          </label>

          <ReactQuill
            theme="snow"
            modules={modules}
            //style={{minHeight: "400px"}}
            style={{
              direction: lang === "ar" ? "rtl" : "ltr",
            }}
            onChange={setContent}
            value={content}
            name="text"
          />
        </Col>
        <Col className={`${direction}`}>
          <Button
            onClick={_update}
            type="button"
            className="btn-submit btn-default"
          >
            {localization.update}
            {loadingUpdate && <div className="lds-dual-ring white"></div>}
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <Container className={`container-app ${direction}`}>
      <Row>
        <Col>
          <div className="title">{localization.pages}</div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} className={"box"}>
          {data.map((item, index) => {
            return (
              <Col
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  margin: "10px 0",
                }}
              >
                <p className="m-0">
                  {item.slug} ({localization[item.local]})
                </p>
                <button
                  className="btn btn-link p-0"
                  style={{ margin: "0 10px" }}
                  onClick={() => {
                    setDataPage(item);
                    setShow(true);
                  }}
                >
                  {localization.update}
                </button>
              </Col>
            );
          })}
        </Col>
      </Row>
      <Modal show={show} onHide={() => setShow(false)} Html={Html} />
    </Container>
  );
};

export default Pages;
