import config from "../appConfig.js";
import queryString from "query-string";
//import { detect } from "detect-browser";
let addressApi =
  "https://nominatim.openstreetmap.org/reverse?format=json&zoom=18&addressdetails=1&";



async function _fetch(links, header = {}) {
  try {
    let response = await fetch(links, header);
    let responseJson = await response.json();
    if (response.status === 200) {
      return {
        status: true,
        ...responseJson,
      };
    } else {
      return {
        ...responseJson,
        status: false,
      };
    }
  } catch (error) {
    return {
      status: false,
      data: [],
      results: {
        code: [3001],
      },
      error,
    };
  }
}

async function getJson(links, data = {}, key = config.appKey) {
  //console.log(links, data);
  let header = {
    method: "GET",
    headers: {
      [config.nameKay]: key,
      //device: data.device ? data.device : getBrowser(),
    },
  };

  //console.log(data);

  if (data.device) {
    delete data.device;
  }

  data = "?" + queryString.stringify(data);

  const response = await _fetch(links + data, header);
  return response;
}

async function postJson(links, data, key = config.appKey) {

  //console.log(data);

  //data.device = data.device ? data.device : getBrowser();

  var formdata = new FormData();

  for (let key in data) {
    if (data[key] !== undefined) {
      formdata.append(key, data[key]);
    }
  }

  let header = {
    method: "POST",
    headers: {
      [config.nameKay]: key,
      //device: data.device,
    },
    body: formdata,
  };

  const response = await _fetch(links, header);
  return response;
}

async function goCoding(geolocation, lang) {
  addressApi =
    addressApi +
    "lat=" +
    geolocation.latitude +
    "&lon=" +
    geolocation.longitude +
    "&accept-language=" +
    lang;
  //console.log(addressApi);
  const response = await _fetch(addressApi, {});
  if (response.status) {
    return response.display_name === undefined
      ? {
          status: false,
        }
      : {
          ...response,
          status: true,
        };
  } else {
    return {
      status: false,
    };
  }
}

export { getJson, postJson, _fetch, goCoding };
