import { createContext, useContext } from "react";

export const Context = createContext();

export function useAuth() {

  const users = useContext(Context);

  return {
    authToken: users.authToken,
    userData: users.userData,
    setAuthUserData: users.setAuthUserData,
  };
}

export function useReload() {
  const _c = useContext(Context);
  return {
    setReload: _c.setReload,
    reload: _c.reload,
    reloadTo : _c.reloadTo,
  };
}

export function useLocalization() {
  const local = useContext(Context);
  return {
    setLang: local.setLang,
    localization: local.localization,
    direction: local.direction,
  };
}

