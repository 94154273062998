import React from "react";
import { useLocalization, useAuth } from "../context";
import { Container, Row, Col, DropdownButton, Dropdown } from "react-bootstrap";
import FormSearch from "../components/formSearch";
import useSearch from "../hooks/useSearch";
import useDb from "../hooks/useDb";
import { FiPlusSquare } from "react-icons/fi";
import FormAddCours from "../components/formAddCours";
import CoursTable from "../components/coursTable";
import usePagination from "../hooks/usePagination";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const Cours = ({ table, categories }) => {
  const { localization, direction } = useLocalization();
  const { authToken, userData } = useAuth();
  const { search, pathname } = useLocation();
  const { p } = queryString.parse(search);

  const {
    onSearch,
    setSearchBy,
    setSearchValue,
    onSearchOption,
    searchQuerys,
  } = useSearch("content");

  const {
    page,
    data,
    loading,
    more,
    paginate,
    setPage,
    pageCount,
  } = usePagination({
    url: "data",
    where: { table: table, ...searchQuerys },
    authToken: authToken,
    page: p,
    onPageChange : (_p) => {
      window.history.pushState(
        {},
        null,
        `${window.location.origin}/#${pathname}?p=${_p}`
      )
    },
  });

  const _onSearch = () => {
    setPage(1);
    onSearch();
  };

  const { removeFinelbyId, update, addData } = useDb(table);
  const [showCours, setShowCours] = React.useState(false);
  const [coursLang, setCoursLang] = React.useState({});

  const _add = (lang) => {
    setCoursLang(lang);
    setTimeout(() => setShowCours(true), 150);
  };

  return (
    <Container className={`container-app ${direction}`}>
      <Row>
        <Col>
          <div className="title">{localization.cours}</div>
        </Col>
      </Row>
      <Row>
        <Col lg="10" md="8" sm="12" xs="12">
          <FormSearch
            onSearch={_onSearch}
            searchBy={setSearchBy}
            searchValue={setSearchValue}
            table={table}
            searchOption={onSearchOption}
            categories={categories}
          />
        </Col>
        <Col lg="2" md="4" sm="12" xs="12">
          <div className="box btn-add">
            <DropdownButton
              className="dropdown-user"
              id="status"
              title={<FiPlusSquare size={26} />}
            >
              <Dropdown.Item onClick={() => _add("ar")}>
                {localization.setCours}
                {localization.ar}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => _add("fr")}>
                {localization.setCours}
                {localization.fr}
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <CoursTable
            searchQuerys={searchQuerys}
            removeFinelbyId={removeFinelbyId}
            data={data}
            paginate={() => paginate(page + 1)}
            userData={userData}
            authToken={authToken}
            nbrOfPage={`${page} / ${pageCount}`}
            update={update}
            loading={loading || more}
            categories={categories}
          />
        </Col>
      </Row>
      <FormAddCours
        show={showCours}
        onHide={() => setShowCours(false)}
        lang={coursLang}
        categories={categories}
        onSubmit={addData}
      />
    </Container>
  );
};

export default Cours;
