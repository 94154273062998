/* eslint-disable no-unused-vars */
import React from "react";
import {
  Table,
  Button,
  DropdownButton,
  Dropdown,
  Row,
  Col,
} from "react-bootstrap";
import { useLocalization } from "../context";
import Swal from "../constants/swal";
import { FiTrash2, FiEdit } from "react-icons/fi";
import { IoIosMore } from "react-icons/io";
import FormEditSeries from "./formEditSeries";

const SeriesTabel = ({
  userData,
  data,
  paginate,
  categories,
  update,
  removeFinelbyId,
  nbrOfPage,
  loading,
}) => {
  const { localization } = useLocalization();
  const role = userData.role;
  const [show, setShow] = React.useState(false);
  const [serie, setSerie] = React.useState({});
  const _update = update;
  //console.log(userData);

  const _deleteFinel = async (item) => {
    if (role !== "admin") {
      return false;
    }

    const swalWithBootstrapButtons =  Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger m-2",
        cancelButton: "btn btn-secondary",
      },
      buttonsStyling: false,
    });
    await swalWithBootstrapButtons.fire(
      {
        title: localization.removeFinel,
        confirmButtonText: localization.delete,
        showCancelButton: true,
        cancelButtonText: localization.cancel,
        text: "",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          removeFinelbyId(item.id).then((result) => {
            if (result.status) {
              Swal.fire(localization.deleteIsSuccess, "", "success").then();
            } else {
              Swal.fire(localization.deleteIsError, "", "error").then();
            }
          });
        },
      },
    );
  };

  const _edit = (item) => {
    setSerie(item);
    setTimeout(() => {
      setShow(true);
    }, 150);
  };

  //console.log(serie);

  return (
    <>
      <div className="box table-app">
        <Table responsive>
          <thead>
            <tr>
              <th>{localization.series}</th>
              <th>{localization.status}</th>
              <th>{localization.questions}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              let style = { backgroundColor: "#ffffff" };
              let colorWhite = "";
              if (!parseInt(item.active)) {
                style = { backgroundColor: "#cdcdcd" };
                colorWhite = "text-white";
              }
              return (
                <tr key={index} style={style}>
                  <td className={colorWhite}>
                    <span
                      style={{
                        display: "inline-grid",
                        lineHeight: 1,
                      }}
                    >
                      {localization[item.mode]}
                      <span className="subline">{item.name}</span>
                      <span className="subline">id : {item.id}</span>
                    </span>
                  </td>
                  <td className={colorWhite}>
                    <span>
                      {parseInt(item.active) === 1
                        ? localization.active
                        : localization.disactive}
                    </span>
                  </td>
                  <td className={colorWhite}>
                    <span>{item.questions.length} / 40</span>
                  </td>
                  <td className="btn-table">
                    <Button
                      variant="info"
                      onClick={() => {
                        _edit(item);
                      }}
                    >
                      <FiEdit size={20} color="#fff" />
                    </Button>
                    <DropdownButton
                      drop={"down"}
                      variant="secondary"
                      className="dropdown-more"
                      id="tt"
                      title={<IoIosMore color="#fff" fontSize="24px" />}
                    >
                      <Button
                        variant="danger"
                        onClick={() => _deleteFinel(item)}
                      >
                        <FiTrash2 size={20} color="#fff" /> {localization.finel}
                      </Button>
                      <DropdownButton
                        variant="secondary"
                        className="dropdown-user"
                        id="status"
                        title={localization.status}
                      >
                        <Dropdown.Item
                          onClick={() => _update(item.id, { active: 1 })}
                        >
                          {localization.active}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => _update(item.id, { active: "0" })}
                        >
                          {localization.disactive}
                        </Dropdown.Item>
                      </DropdownButton>
                    </DropdownButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <Row>
        <Col lg={4} md={4} sm={1} xs={12} />
        <Col lg={4} md={4} sm={10} xs={12}>
          <button className="btn more w-100" onClick={paginate}>
            <span className="more-text">
              {localization.more}
              {' - '}{localization.page} {nbrOfPage}
              {loading && <div className="lds-dual-ring"></div>}
            </span>
          </button>
        </Col>
        <Col lg={4} md={4} sm={1} xs={12} />
      </Row>
      <FormEditSeries
        callBack={_update}
        show={show}
        onHide={() => setShow(false)}
        serie={serie}
        userData={userData}
        typeForm={"updateSerie"}
        categories={categories}
      />
    </>
  );
};

export default SeriesTabel;
