const en = {
  direction: "ltr",
  or: "Or",
  app_name: "Driving website",
  locale: "English",
  _locale: "en",
  ar: "Arabe",
  fr: "Français",
  en: "English",
  language: "Language",
  home: "Home",
  logout: "Logout",
  settings: "Settings",
  loginTitle: "Login",
  signUpTitle: "SignUp",
  btnLogin: "Login",
  btnSignup: "SignUp",
  btnFacebook: "with facebook",
  recoveryPass: "I forgot password ?",
  recovery: "Recovery",
  recoveryPassBtn: "Recovery password",
  recoveryPassTitle: "Recovery the password",
  recoveryPassMsg:
    "A password change message has been sent to your email. Some times it is sent to a spam box",
  recoveryPassMsgError: "There is a problem! Check the email",
  register: "Register",
  registerQuestion: "I don't have an account ?",
  update: "Update",
  name: "Full name",
  first_name: "Nom",
  last_name: "Prénom",
  phone2: "Autre numéro de téléphone ",
  genre: [
    "Le genre",
    {
      male: "Mâle",
      female: "Femelle",
    },
  ],
  confirmPassword: "Confirm password",
  date: "Date de naissance",
  account: "Compte",
  connection: "Connexion",
  phone: "Phone number",
  email: "E-mail",
  password: "Password",
  newPassword: "A new password",
  oldPassword: "Your current password",
  PassNotMatch: "Be sure to match the two passwords",
  dontChange: "If you don't want to change your password, leave it blank",
  newAccount: "New account",
  IhaveAccount: "I have an account, Go back to login",
  EmailNoValid: "Email Already Used or Invalid",
  PhoneNoValid: "Phone number Already Used or Invalid",
  FieldEmpty: "The field is required.",
  MinPassWord: "Password should be more than 8 characters",
  categories: "Categories",
  bookmarks: "Favorites",
  about: "About",
  notification: "Notification",
  bookmarkMsg: "Added to Favorites",
  bookmarkMsgAdded: "Added previously",
  noInternet: "Connection error",
  resources: "Resources",
  subscribe: "Join us on Facebook",
  contact: "Contact us on email",
  contactUs: "Contact us",
  developer: "Developer by @meksiabdou",
  version: "Version",
  more: "Show more",
  notificationAlert:
    "Notification blocked, go to your system settings and enable notifications",
  alert: "Alert",
  social: "Social Media",
  share: "Share",
  rate: "Rate",
  privacy: "Privacy policy",
  Comments: "Comments",
  profileUpdate: "Profile Update",
  youInfoUpdated: "Update successfully",
  youInfoUpdatedUnsuccess: "Update unsuccessfully",
  cart: "Shopping cart",
  popularProducts: "Most popular products",
  statusUser: {
    student: "Student",
    employee: "Employee",
    particular: "Particular",
  },
  coupon: "Coupon de réduction",
  coupons: "Coupons",
  allCoupons: "All coupons",
  code: "Code",
  subTotal: "Sub-Total",
  shippingCost: "Shipping Cost",
  total: "Total amount",
  apply: "Apply",
  delete: "Delete",
  cancel: "Cancel",
  ok: "Ok",
  buy: "Take order",
  deleteItme: "Do you really want to delete an item from the cart?",
  open: "Open",
  closed: "Closed",
  Add: "Add",
  MailNotSend: "There is a problem! The email has not been sent",
  RegisterFiled: "Error The new account registration was not completed",
  YourInfoNotValid: "User information is not valid",
  FieldEmptyInput: "All fields are required.",
  rateText: "Rate",
  users: "Users",
  profile: "Profile",
  selectAddress: "Choose the delivery address",
  address: "Address",
  activity: [
    "Activity",
    {
      student: "Student",
      employee: "Employee",
      particular: "Particular",
    },
  ],
  results_denied: "Location permission denied by user.",
  never_ask_again: "Location permission revoked by user.",
  addressNotUpdate: "**** address not available",
  clickInMaps: "Click on a location you want to select",
  gestionAddress: "Address Management",
  autre: "Other",
  FieldCardEmpty: "The card name field is required.",
  productNotFound: "Product not available \nor unavailable",
  detail: "Details",
  producttTitle: "Product Features",
  searchInput: "Search",
  search: "Search",
  status: "Status",
  loginUnsuccessful: "Error while logging in",
  active: "Active",
  disactive: "Disactive",
  accountStatus: "Statut du compte",
  maxFileSize: "Are not allowed to upload more than 4Mb size",
  typeFile: "Make sure the file extension (png, gif, jpeg)",
  deleteIsSuccess: "Delete successfully",
  deleteIsError: "Error when deleting",
  addIsSuccess: "Add successfully",
  date_created: "Date Created",
  date_updated: "Date Updated",
  causeDelete: "Mention the reason for deletion",
  FieldCauseDeleteEmpty: "The reason for deletion field is mandatory.",
  num_ccp: "Numéro de ccp",
  stores: "Stores",
  store: "Store",
  help: "Help",
  youWelcome: "Welcom",
  updatePassword: "Password change",
  referral: "Referral",
  referralLink: "Referral link",
  loginFacebook: "Login with Facebook",
  loginGoogle: "Login with google",
  error: "Error",
  youAccountCreated:
    "Account created successfully. An activation message has been sent to your email",
  youCountRef: "Registered number via your referral link",
  browseMore: "Browse the full list now",
  aboutTitle: "About cashback",
  aboutDisc: "",
  shopNow: "Shop Now",
  expiryDate: "Expiry date",
  upTo: "Up to",
  conditions: "The conditions!",
  days: "Days",

  referrals: "Referrals",
  updateImage: "Edit profile photo",
  pages: {
    "terms-of-services": "Terms of service",
    "privacy-policy": "Privacy policy",
    "how-to-buy-with-a-cashback": "How to buy with a cashback",
  },
  menu:
    '[{"title":"Traffic Laws","link":"/traffic-laws"},{"title":"Cours","link":"/cours"}]',
  copyRight: "Recashit.com recashit copyrights reserved",
  summary: "Summary",
  cours: "Lesson series",
  traffic: "Traffic Law",
  result: "Result",
  variableResult: "{min} on {max}",
  question: "Question",
  questions: "Questions",
  correction: "Correction",
  valid: "",
  relaunch: "",
  continue: "Continue",
  errors: "Errors",
  faq: "FQA",
  wilaya: "Wilaya",
};

export default en;
