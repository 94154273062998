/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { MdHighlightOff, MdTranslate } from "react-icons/md";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { useLocalization } from "../context";
import Config from "../appConfig";
import Modal from "./Modal";

const Question = ({ question, show, onHide }) => {
  const { localization, direction } = useLocalization();
  const Ch = (key) => {
    key = key.toUpperCase();
    let _ = {
      A: "أ",
      B: "ب",
      C: "ج",
      D: "د",
    };

    if (localization._locale !== "ar") {
      _ = {
        A: "A",
        B: "B",
        C: "C",
        D: "D",
      };
    }

    return _[key];
  };
  const iconsRander = (questionResponse, key) => {
    if (!questionResponse.includes(key)) {
      return <MdHighlightOff className="icon" />;
    } else {
      return <IoIosCheckmarkCircleOutline className="icon select" />;
    }
  };

  const answerRander = (question, answers) => {
    const questionResponse =
      typeof question.right_answer === "object"
        ? question.right_answer
        : JSON.parse(question.right_answer);
    return Object.keys(answers).map((key, index) => {
      const answer = answers;
      if (answer[key] === null) return null;
      return (
        <div
          key={index}
          className={`reponse-item card-item ${
            questionResponse.includes(key) ? "reponse-true" : "reponse-false"
          }`}
        >
          <div className={`reponse-item-content`}>
            <div className="reponse-index">
              {Ch(key)} {"-"}{" "}
            </div>
            <p>{answer[key]} </p>
          </div>
          <div className={`reponse-item-icon`}>
            {iconsRander(questionResponse, key)}
          </div>
        </div>
      );
    });
  };

  const Html = () => {
    const [lang, setLang] = useState("fr");

    const translate =
      typeof question.translate === "object"
        ? question.translate
        : JSON.parse(question.translate);

    const [content, setContent] = useState(translate[lang]);

    //const _content = JSON.parse(question.content);

    useEffect(() => {
      setContent(translate[lang]);
    }, [lang]);

    if (content.question_1) {
      const image1 =
        question.image_question_1 === null
          ? `${Config.assetsUrl}/traffic.jpg`
          : `${Config.uploads}questions/${question.category_name}/${question.image_question_1}`;
      const image2 =
        content.question_2 === null
          ? null
          : question.image_question_2 &&
            `${Config.uploads}questions/${question.category_name}/${question.image_question_2}`;
      const faq = content.faq ? content.faq : [];
      //console.log(faq);
      return (
        <div className={`alert-question ${direction}`}>
          <div className="change-lang">
            <DropdownButton
              drop={"down"}
              //variant="info"
              className="dropdown-more"
              id="tt"
              title={<MdTranslate size={28} color="#fff" />}
            >
              <Dropdown.Item onClick={() => setLang("ar")}>
                {localization.ar}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setLang("fr")}>
                {localization.fr}
              </Dropdown.Item>
            </DropdownButton>
          </div>
          <div className="question-image">
            <img className="img-fluid" src={image1} alt="" />
            {image2 && image2 !== image1 && (
              <img className="img-fluid" src={image2} alt="" />
            )}
          </div>
          <div className="question-box">
            <div dir="auto" className="question">
              <p>{content.question_1.question}</p>
            </div>
            <div className="suggestions">
              {answerRander(question, content.question_1.answer)}
            </div>
            {content.question_2 ? (
              <>
                <div className="question">
                  <p>{content.question_2.question}</p>
                </div>
                <div className="suggestions">
                  {answerRander(question, content.question_2.answer)}
                </div>
              </>
            ) : null}
          </div>
          <div className="detail">
            <h4>{localization.response}</h4>
            <pre dir='auto'>{question[`response_text_${lang}`]}</pre>
          </div>
          
          <div className="detail">
            <h4>{faq.length !== 0 && localization.faq}</h4>
            {faq.map((item, index) => {
              return (
                <div dir="auto" className="faq-detail" key={index}>
                  <p>{item.question}</p>
                  <span>{item.response}</span>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    return null;
  };

  return <Modal show={show} onHide={onHide} Html={Html} />;
};

export default Question;
