/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import {
  Image,
  Button,
  Form,
  InputGroup,
  Col,
  FormControl,
  Row,
} from "react-bootstrap";
import { useLocalization, useAuth, useReload } from "../context";
import Codes from "../constants/codes";
import Config from "../appConfig";
import useValidation from "../hooks/useValidation";
import Alert from "../components/alert";
import Modal from "./Modal";
import { FiEdit } from "react-icons/fi";
import fileUpload from "../helps/fileUpload";
import { postJson } from "../networking/server";
import Links from "../constants/Links";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";

const FormEditQuestion = (props) => {
  const { localization, direction } = useLocalization();
  const { authToken, userData } = useAuth();
  const { setReload } = useReload();

  const _update = props.update;
  const lang = props.lang;

  const _localization = (key) => {
    return localization[key];
  };

  const getTextFromCodes = (code) => {
    if (!code) {
      return "";
    }
    return _localization(Codes[code].text);
  };

  //console.log(props.question);

  const Html = () => {
    const [question, setQuestion] = useState({});
    const inputNotReq = ["answer_question_1_c", "answer_question_1_d", "question_1", "question_2"];

    const {
      errors,
      handelOnChange,
      data,
      handelOnSubmit,
      setData,
      RefEvent,
      refForm,
    } = useValidation(inputNotReq);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [alertType, setAlertType] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const [images, setImages] = useState({
      image_question_1: null,
      image_question_2: null,
    });

    const refImage1 = useRef(null);
    const refImage2 = useRef(null);

    //const [image, setImage] = useState("");

    //const forView = props.forView ? true : false;

    const [faqs, setFaqs] = useState([]);

    const [id, setId] = useState(0);

    const [othreAnswer, setOthreAnswer] = useState(false);

    const [rightAnswer, setRightAnswer] = useState([]);

    useEffect(() => {
      setId(props.question.id);
      setError(false);
      _setQuestion();
      setMessage("");
      setSuccess(false);
    }, [props.question]);

    useEffect(() => {
      setTimeout(() => setData(RefEvent()), 800);
      //console.log(refForm);
    }, [faqs, othreAnswer]);

    //console.log(isOpen);

    const _fileUpdoad = (event, name) => {
      const _data = {
        category_name: question.category_name,
        name: name,
        id: id,
        uid: userData.id,
      };
      setLoading(true);
      fileUpload(event.target.files, (result) => {
        if (result.status) {
          if (result.filebase64) {
            postJson(
              Links.updateImageQuestion,
              { ..._data, image: result.file },
              authToken
            ).then((response) => {
              if (response.status) {
                setImages({ ...images, [name]: result.filebase64 });
                setAlertType("success");
                setError(false);
                setSuccess(true);
                setReload();
              } else {
                setAlertType("error");
                setSuccess(false);
                setError(true);
              }
              setMessage(response?.results?.code);
            });
          }
        } else {
          setError(true);
          setAlertType("error");
          setMessage(result.message);
        }
        setLoading(false);
      });
    };

    const _setQuestion = () => {
      const translate = JSON.parse(props.question.translate);
      const content = JSON.parse(props.question.content);

      const _image1 =
        props.question.image_question_1 === null
          ? `${Config.assetsUrl}/traffic.jpg`
          : `${Config.uploads}questions/${props.question.category_name}/${props.question.image_question_1}`;
      const _image2 =
        content.question_2 === null
          ? null
          : props.question.image_question_2 &&
            `${Config.uploads}questions/${props.question.category_name}/${props.question.image_question_2}`;

      //console.log(question.translate.faq);
      setImages({
        image_question_1: _image1,
        image_question_2: _image2,
      });

      setQuestion({
        ...props.question,
        content,
        translate,
      });

      setFaqs(content.faq ? content.faq : []);

      setRightAnswer(JSON.parse(props.question.right_answer));
    };

    //console.log(rightAnswer);

    const faqAdd = () => {
      setFaqs([
        ...faqs,
        {
          question: "",
          response: "",
        },
      ]);
    };

    const addToRightAnswer = (ch) => {
      if (!rightAnswer.includes(ch)) {
        setRightAnswer([...rightAnswer, ch]);
      } else {
        const right_answer = rightAnswer.filter((a) => ch !== a);
        setRightAnswer(right_answer);
      }
    };

    const faqRemove = (index) => {
      const _faqs = faqs.filter((f, i) => index !== i);
      setFaqs(_faqs);
      //console.log(_faqs);
    };

    const onSubmit = (event, status) => {
      event.preventDefault();

      const dataForUpdate = question.translate;

      const translateds = {};
      let translated = 0;
      //console.log(data);

      Object.keys(dataForUpdate).map((_lang) => {
        if (dataForUpdate[_lang].status && _lang !== "en") {
          translateds[_lang] = dataForUpdate[_lang].status;
        } else if (_lang === "fr") {
          translateds[_lang] = true;
        } else if (!dataForUpdate[_lang].status && _lang !== "en") {
          translateds[_lang] = false;
        }

        if (_lang === lang) {
          const question_1 = dataForUpdate[_lang].question_1;
          const question_2 = dataForUpdate[_lang].question_2;
          const faq = faqs.map(() => {
            return {
              question: "",
              response: "",
            };
          });

          dataForUpdate[_lang].status = true;
          translateds[_lang] = dataForUpdate[_lang].status;

          if (question_1) {
            if (data.question_1) {
              question_1.question = data.question_1;
            }else {
              question_1.question = '';
            }

            Object.keys(question_1.answer).map((key) => {
              if (data[`answer_question_1_${key}`]) {
                question_1.answer[key] = data[`answer_question_1_${key}`];
              } else {
                question_1.answer[key] = null;
              }
              return true;
            });
          }

          if (question_2) {
            if (data.question_2) {
              question_2.question = data.question_2;
            }else {
              question_2.question = null;
            }

            Object.keys(question_2.answer).map((key) => {
              if (data[`answer_question_2_${key}`]) {
                question_2.answer[key] = data[`answer_question_2_${key}`];
              } else {
                question_2.answer[key] = null;
              }
              return true;
            });
          }

          if (!data.question_2 && !question_2) {
            dataForUpdate[_lang].question_2 = null;
          }

          if (faqs.length >= 1) {
            faqs.map((_faq, index) => {
              if (
                data[`faq_question_${index}`] &&
                data[`faq_response_${index}`]
              ) {
                if (faq[index]) {
                  faq[index].question = data[`faq_question_${index}`];
                  faq[index].response = data[`faq_response_${index}`];
                }
                dataForUpdate[_lang].faq = faq;
              }
              return true;
            });
          } else {
            dataForUpdate[_lang].faq = [];
          }
        }
        return true;
      });

      //console.log(dataForUpdate);
      //console.log(status);
      //return false;

      if (
        !Object.values(translateds).includes(false) &&
        Object.values(translateds).length !== 0
      ) {
        translated = 1;
      }

      //console.log(data, dataForUpdate[lang]);

      //return false;

      if (rightAnswer.length === 0) {
        setError(true);
        setAlertType("error");
        setMessage("FieldEmptyInput");
        return false;
      }

      if (status) {
        setLoading(true);
        _update(id, {
          translated: translated,
          translate: JSON.stringify(dataForUpdate),
          right_answer: JSON.stringify(rightAnswer),
          [`response_text_${lang}`] : data.response_text,
        }).then((response) => {
          if (response.status) {
            setAlertType("success");
            setSuccess(true);
          } else {
            setError(true);
            setAlertType("error");
          }
          setMessage(response?.results?.code);
          setLoading(false);
        });
      }
    };

    return (
      <>
        <Form
          ref={refForm}
          onSubmit={(event) => handelOnSubmit(event, onSubmit)}
          autoComplete="off"
          className={`edit-question ${direction}`}
        >
          <Form.Row>
            <Col lg="12" md="12" sm="12" xs="12">
              {/*<Image src={image} />*/}
            </Col>
            <Col lg="12" md="12" sm="12" xs="12">
              <Row>
                <Col lg="12" md="12" sm="12" xs="12" className="title">
                  {localization.update}
                </Col>
                <Col lg="12" md="12" sm="12" xs="12">
                  {Array.isArray(message) ? (
                    message.map((item, index) => (
                      <Alert
                        key={index}
                        message={getTextFromCodes(item)}
                        show={error || success}
                        type={alertType}
                        onHide={() => {
                          setError(false);
                          setSuccess(false);
                        }}
                      />
                    ))
                  ) : (
                    <Alert
                      message={
                        localization[message] ? localization[message] : message
                      }
                      show={error || success}
                      type={alertType}
                      onHide={() => {
                        setError(false);
                        setSuccess(false);
                      }}
                    />
                  )}
                </Col>
                <Col lg="12" md="12" sm="12" xs="12">
                  <div className="question-image">
                    <div
                      className="image-handle"
                      onClick={() => refImage1.current.click()}
                    >
                      <Image
                        className="img-fluid"
                        src={images.image_question_1}
                        alt=""
                      />
                      <div className="image-edit">
                        {<FiEdit size={82} color="#fff" />}
                      </div>
                    </div>

                    {images.image_question_2 &&
                      images.image_question_2 !== images.image_question_1 && (
                        <div
                          className="image-handle"
                          onClick={() => refImage2.current.click()}
                        >
                          <Image
                            className="img-fluid"
                            src={images.image_question_2}
                            alt=""
                          />
                          <div className="image-edit">
                            {<FiEdit size={82} color="#fff" />}
                          </div>
                        </div>
                      )}
                  </div>
                </Col>

                {[
                  question.content?.question_1,
                  question.content?.question_2,
                ].map((_q, index) => {
                  if (_q && _q !== null) {
                    const key = `question_${index + 1}`;
                    const _question = question.translate[lang][key];

                    //console.log(_question);

                    return (
                      <React.Fragment key={index.toString()}>
                        <Col lg="12" md="12" sm="12" xs="12" className="input">
                          <label>
                            {localization.question} {index + 1} <span> {!inputNotReq.includes(key) ? "*" : ""}</span>
                          </label>
                          <p dir="auto">{question.translate['fr'][key].question ? question.translate['fr'][key].question : question.content[key].question}</p>
                          <span className="error form-text">
                            {errors[key] && _localization(errors[key])}
                          </span>
                          <InputGroup
                            className={errors[key] ? "input-error" : ""}
                          >
                            <FormControl
                              type="text"
                              dir="auto"
                              as="textarea"
                              placeholder={localization.question}
                              name={key}
                              defaultValue={_question.question}
                              onChange={handelOnChange}
                            />
                          </InputGroup>
                        </Col>
                        
                        <Col lg="12" md="12" sm="12" xs="12" className="input">
                          <label>
                            {localization.answer} {localization.question}
                            {index + 1}
                            <span>*</span>
                          </label>
                          {!question.content?.question_2 && (
                            <Button
                              className={othreAnswer ? "remove" : "add"}
                              onClick={() => {
                                setOthreAnswer(!othreAnswer);
                              }}
                            >
                              {othreAnswer
                                ? localization.delete
                                : localization.Add}{" "}
                              {localization.othreAnswer}
                            </Button>
                          )}
                          <p>- {localization.rightAnswerCheck}</p>

                          {Object.keys(_question.answer).map((ch) => {
                            if (
                              !question.content[key].answer[ch] &&
                              !_question.answer[ch] &&
                              !othreAnswer
                            ) {
                              return null;
                            }
                            const answer = `answer_${key}_${ch}`;
                            return (
                              <div key={ch} className="answer">
                                <p dir="auto">
                                  <span style={{ color: "#dc3545" }}>
                                    ({ch}){" "}
                                  </span>
                                  {question.content[key].answer[ch]
                                    ? question.content[key].answer[ch]
                                    : ""}
                                </p>
                                <span className="error form-text">
                                  {errors[answer] &&
                                    _localization(errors[answer])}
                                </span>
                                <InputGroup
                                  className={
                                    errors[answer] ? "input-error" : ""
                                  }
                                >
                                  <FormControl
                                    dir="auto"
                                    type="text"
                                    placeholder={localization.answer}
                                    name={answer}
                                    defaultValue={
                                      _question.answer[ch]
                                        ? _question.answer[ch]
                                        : ""
                                    }
                                    onChange={handelOnChange}
                                  />
                                  <InputGroup.Append>
                                    <Button
                                      variant="link"
                                      onClick={() => addToRightAnswer(ch)}
                                    >
                                      {rightAnswer.includes(ch) ? (
                                        <ImCheckboxChecked />
                                      ) : (
                                        <ImCheckboxUnchecked />
                                      )}
                                    </Button>
                                  </InputGroup.Append>
                                </InputGroup>
                              </div>
                            );
                          })}
                        </Col>
                      </React.Fragment>
                    );
                  }
                  return null;
                })}

                <React.Fragment>
                  <Col lg="12" md="12" sm="12" xs="12" className="input">
                    <label>
                      {localization.response_text} <span> *</span>
                    </label>
                    <p dir="auto">{question[`response_text_fr`]}</p>
                    <span className="error form-text">
                      {errors[`response_text`] &&
                        _localization(errors[`response_text`])}
                    </span>
                    <InputGroup
                      className={errors[`response_text`] ? "input-error" : ""}
                    >
                      <FormControl
                        type="text"
                        dir="auto"
                        as="textarea"
                        placeholder={localization.response}
                        name={`response_text`}
                        defaultValue={question[`response_text_${lang}`]}
                        onChange={handelOnChange}
                      />
                    </InputGroup>
                  </Col>
                </React.Fragment>

                {faqs.map((_faq, index) => {
                  const faq = question.translate[lang].faq[index]
                    ? question.translate[lang].faq[index]
                    : {
                        question: "",
                        response: "",
                      };

                  return (
                    <React.Fragment key={index.toString()}>
                      <Col>
                        <p className="faq">{localization.faq}</p>
                      </Col>

                      <Col lg="12" md="12" sm="12" xs="12" className="input">
                        <label>
                          {localization.question} {index + 1} <span> *</span>
                          <Button
                            variant="danger"
                            className="remove"
                            onClick={() => faqRemove(index)}
                          >
                            {localization.delete}
                          </Button>
                        </label>
                        <p dir="auto">{_faq.question}</p>
                        <span className="error form-text">
                          {errors[`faq_question_${index}`] &&
                            _localization(errors[`faq_question_${index}`])}
                        </span>
                        <InputGroup
                          className={
                            errors[`faq_question_${index}`] ? "input-error" : ""
                          }
                        >
                          <FormControl
                            type="text"
                            dir="auto"
                            as="textarea"
                            placeholder={localization.question}
                            name={`faq_question_${index}`}
                            defaultValue={faq.question}
                            onChange={handelOnChange}
                          />
                        </InputGroup>
                      </Col>
                      <Col lg="12" md="12" sm="12" xs="12" className="input">
                        <label>
                          {localization.response} {index + 1} <span> *</span>
                        </label>
                        <p dir="auto">{_faq.response}</p>
                        <span className="error form-text">
                          {errors[`faq_response_${index}`] &&
                            _localization(errors[`faq_response_${index}`])}
                        </span>
                        <InputGroup
                          className={
                            errors[`faq_response_${index}`] ? "input-error" : ""
                          }
                        >
                          <FormControl
                            type="text"
                            dir="auto"
                            as="textarea"
                            placeholder={localization.response}
                            name={`faq_response_${index}`}
                            defaultValue={faq.response}
                            onChange={handelOnChange}
                          />
                        </InputGroup>
                      </Col>
                    </React.Fragment>
                  );
                })}

                <Col lg="12" md="12" sm="12" xs="12">
                  <Button onClick={faqAdd} className="p-0" variant="link">
                    {localization.Add} {localization.faq}
                  </Button>
                </Col>

                <Col>
                  <Button
                    disabled={loading}
                    type="submit"
                    className="btn-submit btn-default"
                  >
                    {localization.update}
                    {loading && <div className="lds-dual-ring white"></div>}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Form.Row>
        </Form>
        <div>
          <input
            type="file"
            hidden
            ref={refImage1}
            onChange={(event) => _fileUpdoad(event, "image_question_1")}
            accept="image/x-png,image/gif,image/jpeg"
            name="image_question_1"
          />
          <input
            type="file"
            hidden
            ref={refImage2}
            onChange={(event) => _fileUpdoad(event, "image_question_2")}
            accept="image/x-png,image/gif,image/jpeg"
            name="image_question_2"
          />
        </div>
      </>
    );
  };

  return <Modal show={props.show} onHide={props.onHide} Html={Html} />;
};

export default FormEditQuestion;
