import React from "react";
import { Image, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <Col className="header">
      <div>
        <Link to="/">
          <Image className="logo" src={"/assets/images/logo.png"} />
        </Link>
      </div>
    </Col>
  );
};

export default Header;
