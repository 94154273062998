const fr = {
  direction: "ltr",
  or: "Ou",
  app_name: "Site Web de conduite",
  locale: "Français",
  _locale: "fr",
  ar: "Arabe",
  fr: "Français",
  en: "Anglais",
  language: "Langue",
  home: "Accueil",
  logout: "Déconnecté",
  settings: "Paramètres",
  loginTitle: "Connectez-vous",
  signUpTitle: "Inscription",
  btnSignup: "S'inscrire",
  btnLogin: "Se connecter",
  connection: "Connexion",
  btnFacebook: "avec facebook",
  recoveryPass: "J'ai oublié le mot de passe?",
  recovery: "Récupération",
  recoveryPassBtn: "Récupération de mot de passe",
  recoveryPassTitle: "Récupération de mot de passe",
  recoveryPassMsg:
    "Un message de changement de mot de passe a été envoyé à votre e-mail. Parfois, il est envoyé dans une boîte spam",
  recoveryPassMsgError: "Il y a un problème! Vérifiez l'e-mail",
  register: "S'inscrire",
  registerQuestion: "Vous n'avez pas de compte? Inscrivez-vous ici",
  update: "Mise à jour",
  name: "Nom et Prénom",
  first_name: "Nom",
  last_name: "Prénom",
  phone: "Numéro de téléphone",
  phone2: "Autre numéro de téléphone ",
  genre: [
    "Le genre",
    {
      male: "Mâle",
      female: "Femelle",
    },
  ],
  email: "E-mail",
  password: "Mot de pass",
  confirmPassword: "Confirmer le mot de passe",
  date: "Date de naissance",
  newPassword: "Un nouveau mot de passe",
  oldPassword: "Votre mot de passe actuel",
  dontChange:
    "Si vous ne souhaitez pas modifier votre mot de passe, laissez-le vide",
  account: "Compte",
  newAccount: "Créer un nouveau compte",
  IhaveAccount: "J'ai un compte, revenir à la connexion",
  EmailNoValid: "E-mail déjà utilisé ou non valide",
  PhoneNoValid: "Numéro de téléphone déjà utilisé ou non valide",
  FieldEmpty: "Le champ est obligatoire.",
  FieldEmptyInput: "Touts les champs est obligatoire.",
  MinPassWord: "Le mot de passe doit contenir plus de 8 caractères",
  PassNotMatch: "Veillez à faire correspondre les deux mot de passe",
  categories: "Catégories",
  bookmarks: "Favoris",
  about: "A propos",
  notification: "Notification",
  bookmarkMsg: "Ajouté aux favoris",
  bookmarkMsgAdded: "Ajouté précédemment",
  noInternet: "Erreur de connexion",
  resources: "Ressources",
  subscribe: "Rejoignez nous sur Facebook",
  contact: "Nous \n Contacter",
  contactUs: "Nous \n Contacter",
  developer: "Développeur par  @meksiabdou",
  version: "Version",
  more: "Montre plus",
  notificationAlert:
    "Notification bloquée, accédez aux paramètres de votre système et activez les notifications",
  alert: "Alerte",
  social: "Des médias sociaux",
  share: "Partager",
  rate: "Noter l'application",
  rateText: "Noter l'application",
  privacy: "Politique de confidentialité",
  Comments: "Commentaires",
  users: "Utilisateur",
  profileUpdate: "Mise à jour de compte",
  profile: "Profile",
  youInfoUpdated: "Modifié avec succès",
  youInfoUpdatedUnsuccess: "Mise à jour infructueuse",
  total: "Montant total",
  apply: "Appliquer",
  delete: "supprimer",
  cancel: "Annuler",
  ok: "Ok",
  buy: "Passer la commande",
  deleteItme: "Voulez-vous vraiment supprimer?",
  open: "Ouvert",
  closed: "Fermé",
  selectAddress: "Choisissez l'adresse de livraison",
  address: "Address",
  activity: [
    "Activité",
    {
      student: "Étudiant",
      employee: "Employé",
      particular: "Particulier",
    },
  ],
  Add: "Ajouter",
  addressNotUpdate: "**** adresse non disponible",
  results_denied: "Autorisation de localisation refusée par l'utilisateur.",
  never_ask_again: "Autorisation de localisation révoquée par l'utilisateur.",
  clickInMaps: "Cliquez sur un emplacement que vous souhaitez sélectionner",
  gestionAddress: "Gestion les address",
  MailNotSend: "Il y a un problème! L'email n'a pas été envoyé",
  RegisterFiled:
    "Erreur L'enregistrement du nouveau compte n'a pas été terminé",
  YourInfoNotValid: "Les informations utilisateur ne sont pas valides",
  autre: "Autre",
  FieldCardEmpty: "Le champ du nom de la carte est obligatoire.",
  productNotFound: "produit non disponible ou unavailable",
  detail: "Détails",
  producttTitle: "Caractéristiques du produit",
  searchInput: "Recherche",
  search: "Recherche",
  status: "Status",
  loginUnsuccessful: "Erreur lors de la connexion",
  active: "Active",
  disactive: "Disactive",
  accountStatus: "Statut du compte",
  maxFileSize: "Ne sont pas autorisés à télécharger plus d’une taille 4Mb",
  typeFile: "Assurez-vous que l’extension de fichier (png, gif, jpeg)",
  deleteIsSuccess: "Supprimer avec succès",
  deleteIsError: "Erreur lors de supprimer",
  addIsSuccess: "Ajouter  avec succès",
  date_created: "Date créée",
  date_updated: "Date modifiée",
  causeDelete: "Mentionnez la raison de la suppression",
  FieldCauseDeleteEmpty: "Le champ raison de la suppression est obligatoire.",
  num_ccp: "Numéro de ccp",
  coupon: "Coupon de réduction",
  coupons: "Coupons",
  allCoupons: "Tout les coupons",
  code: "Code",
  subTotal: "Sous-Total",
  shippingCost: "Frais de livraison",
  stores: "Magasins",
  store: "Magasin",
  help: "Aidez-moi",
  youWelcome: "Bienvenue",
  updatePassword: "Changement de mot de passe",
  referral: "Référence",
  referralLink: "Lien de référence",
  loginFacebook: "Se connecter avec Facebook",
  loginGoogle: "Se connecter avec google",
  error: "Erreur",
  youAccountCreated:
    "Compte créé avec succès. Un message d'activation a été envoyé à votre e-mail",
  youCountRef: "Numéro enregistré via votre lien de référence",
  browseMore: "Parcourir la liste complète maintenant",
  aboutTitle: "À propos du cashback",
  aboutDisc: "",
  expiryDate: "Date d'expiration",
  upTo: "Jusqu'à",
  conditions: "The conditions!",
  days: "Jours",
  referrals: "Références",
  updateImage: "Modifier photo de profile",
  menu:
    '[{"title":"Code de la route","link":"/code"},{"title":"Cours","link":"/cours"}]',
  copyRight: "Recashit.com Tous droits réservés",
  summary: "Sommaire",
  cours: "Série de leçons",
  traffic: "Code de la route",
  result: "Résultat",
  series: "Séries",
  newSerie: "Nouvelle série",
  training: "Entraînement",
  thematic: "Série thématique",
  whiteExam: "Examen blanc",
  "training-exam": "Entraînement",
  "white-exam": "Examen blanc",
  "thematic-exam": "Série thématique",
  without: "Sans",
  with: "Avec",
  variableResult: "{min} sur {max}",
  question: "Question",
  questions: "Questions",
  correction: "Correction",
  continue: "Continuer",
  valid: "Vérifie ta réponse",
  relaunch: "Relance",
  errors: "Fautes",
  congratulations: "Toutes nos félicitations",
  resultSuccessText: "Bravo, vous avez réussi votre quiz!",
  resultUnSuccessText: "Désolé, j'ai échoué à votre quiz",
  faq: "Questions fréquemment posées",
  wilaya: "Wilaya",
};

export default fr;
