/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Button, Col, Row, Modal, FormControl } from "react-bootstrap";
import { useLocalization } from "../context";
import Codes from "../constants/codes";
import Alert from "../components/alert";
import QuestionList from "./questionList";
import Question from "./quesion";
import { FiEye } from "react-icons/fi";

const FormEditSeries = ({
  callBack,
  serie,
  show,
  onHide,
  typeForm,
  categories,
}) => {
  const { localization, direction } = useLocalization();
  const [showQuestion, setShowQuestion] = useState(false);
  const [question, setQuestion] = useState({});
  const [questions, setQuestions] = useState([]);
  const [_serie, setSerie] = useState({});

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [questionNbrByCategory, setQuestionNbrByCategory] = useState([]);
  const [subscribe, setSubscribe] = useState("free-premium");

  const _onSubmit =
    typeForm === "updateSerie"
      ? (_id, where) => callBack(_id, where)
      : (n, data) => callBack(data);

  const [id, setId] = useState(0);

  useEffect(() => {
    if (typeForm === "updateSerie") {
      setId(serie.id);
    }
    setError(false);
    setIsSubmit(false);
    if (show) {
      _setQuestions();
    }
    setMessage("");
    setSuccess(false);
    setSubscribe(serie.subscribe);
  }, [serie, show]);

  const _setQuestions = () => {
    _setQuestionNbrByCategory(serie.questions);
    setQuestions(serie.questions);
    setSerie({
      mode: serie.mode,
      category_name:
        serie.mode === "thematic-exam" ? serie.questions[0].category_name : "",
    });
  };

  /*useEffect(() => {
  } ,[questions]);*/

  const _setQuestionNbrByCategory = (qus) => {
    const _questionNbrByCategory = categories.map((cat) => {
      return { name: cat.name, value: 0 };
    });

    qus.map((qu) => {
      _questionNbrByCategory.map((category, index) => {
        //console.log(Category.name === qu.category_name);
        if (category.name === qu.category_name) {
          _questionNbrByCategory[index] = {
            name: category.name,
            value: _questionNbrByCategory[index].value + 1,
          };
        }
        return true;
      });
      return true;
    });
    setQuestionNbrByCategory(_questionNbrByCategory);
  };
  //const lang = lang;

  //console.log(questionNbrByCategory);

  const _localization = (key) => {
    return localization[key];
  };

  const getTextFromCodes = (code) => {
    if (!code) {
      return "";
    }
    return _localization(Codes[code].text);
  };

  //console.log(question);
  const _view = (item) => {
    setQuestion(item);
    setTimeout(() => {
      setShowQuestion(true);
    }, 150);
  };

  const _delete = (item) => {
    const q = questions.filter((_item) => _item.id !== item.id);
    setQuestions(q);
    _setQuestionNbrByCategory(q);
  };

  const _add = (item) => {
    if (questions.length < 40) {
      item.translate =
        typeof item.translate === "object"
          ? item.translate
          : JSON.parse(item.translate);
      item.content =
        typeof item.content === "object"
          ? item.content
          : JSON.parse(item.content);
      //console.log(item);
      let q = questions.filter((_item) => _item.id !== item.id);
      q = [item, ...q];
      setQuestions(q);
      _setQuestionNbrByCategory(q);
    }
  };

  const onSubmit = () => {
    setLoading(true);
    if (questions.length === 40) {
      if (isSubmit) {
        setLoading(false);
        return false;
      }
      _onSubmit(id, {
        questions: JSON.stringify(questions.map((item) => item.id)),
        subscribe: subscribe,
      }).then((response) => {
        if (response.status) {
          setAlertType("success");
          setSuccess(true);
          if (typeForm === "addNewSerie") {
            setIsSubmit(true);
            setTimeout(() => onHide(), 500);
          }
        } else {
          setError(true);
          setAlertType("error");
        }
        setMessage(response?.results?.code);
        setLoading(false);
      });
    } else {
      setError(true);
      setAlertType("error");
      setMessage([3015]);
      setLoading(false);
    }
  };

  //console.log(questions);

  const Questions = () => {
    return (
      <Col lg="6" md="6" sm="6" xs="6" style={{ marginTop: "0px" }}>
        <Col lg="12" md="12" sm="12" xs="12" className={`p-0`}>
          <Row>
            <Col lg="12" md="12" sm="12" xs="12" className="mode">
              <div className="static-category">
                <div>
                  <p>
                    {localization.mode} : {localization[serie?.mode]}
                    <span dir="auto">{questions.length}</span>/ 40
                  </p>
                </div>
                {questionNbrByCategory.map((q, index) => {
                  if (q.value === 0) {
                    return null;
                  } else {
                    return (
                      <p key={index}>
                        {" "}
                        {localization[q.name]} : <span>({q.value})</span>
                      </p>
                    );
                  }
                })}
              </div>
            </Col>
            <Col
              lg="12"
              md="12"
              sm="12"
              xs="12"
              className="questions scroll pagination-section"
            >
              {questions.map((question, index) => {
                //const _question = question.translate[lang][key];
                if (!question) {
                  return null;
                }
                //const _question = question.content;
                const content = question.content;
                const translate = question.translate;

                //translate['fr'].question_1.question
                return (
                  <Col
                    key={index}
                    lg="12"
                    md="12"
                    sm="12"
                    xs="12"
                    className={`input p-0 ${
                      !parseInt(question.active) ? "no-active" : ""
                    }`}
                  >
                    {/*<p dir="auto">{question.content.question_1.question}</p>*/}
                    <div className="question-box">
                      <div className="right-side">
                        <p>{question.id}</p>
                        <p className="question" dir="auto">
                          {translate['fr'].question_1.question? translate['fr'].question_1.question : content.question_1.question}
                          <b>{localization[question.category_name]}</b>
                        </p>
                      </div>
                      <div className="left-side">
                        <Button
                          onClick={() => {
                            _view(question);
                          }}
                        >
                          <FiEye size={20} color="#fff" />
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() => _delete(question)}
                        >
                          {localization.delete}
                        </Button>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Col>
            {serie.mode === "white-exam" && (
              <Col lg="12" md="12" sm="12" xs="12" className="mt-2">
                <label htmlFor="subscribe">
                  {localization.subscribe} {localization.free}
                </label>
                <FormControl
                  className="float-right mr-3"
                  id="subscribe"
                  type="checkbox"
                  defaultChecked={(serie.subscribe === "free" || subscribe === 'free') ? true : false}
                  onClick={(event) => {
                    if (event.target.checked) {
                      setSubscribe("free");
                    } else {
                      setSubscribe("free-premium");
                    }
                  }}
                />
              </Col>
            )}

            <Col>
              <Button
                disabled={loading}
                className="btn-submit btn-default mt-4"
                onClick={onSubmit}
              >
                {typeForm === "updateSerie"
                  ? localization.update
                  : localization.Add}
                {loading && <div className="lds-dual-ring white"></div>}
              </Button>
            </Col>
          </Row>
        </Col>
      </Col>
    );
  };

  //console.log(subscribe);

  return (
    <>
      <Modal
        animation={false}
        size="lg"
        show={show}
        onHide={onHide}
        className={`edit-series ${direction}`}
      >
        <Modal.Header closeButton>
          <div className="title">{localization.update} ({localization[serie.mode]})</div>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-0">
          <Row className={`p-2`}>
            <Col lg="12" md="12" sm="12" xs="12">
              {Array.isArray(message) ? (
                message.map((item, index) => (
                  <Alert
                    key={index}
                    message={getTextFromCodes(item)}
                    show={error || success}
                    type={alertType}
                    onHide={() => {
                      setError(false);
                      setSuccess(false);
                    }}
                  />
                ))
              ) : (
                <Alert
                  message={message}
                  show={error || success}
                  type={alertType}
                  onHide={() => {
                    setError(false);
                    setSuccess(false);
                  }}
                />
              )}
            </Col>
            <Col lg="6" md="6" sm="6" xs="6">
              <QuestionList
                view={_view}
                add={_add}
                serie={_serie}
                categories={categories}
              />
            </Col>
            <Questions />
          </Row>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Question
        question={question}
        show={showQuestion}
        onHide={() => setShowQuestion(false)}
      />
    </>
  );
};

export default FormEditSeries;
