/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useLocalization, useAuth } from "../context";
import {
  Container,
  Row,
  DropdownButton,
  Dropdown,
  Table,
  Col,
} from "react-bootstrap";
import useDb from "../hooks/useDb";
import Swal from "../constants/swal";
import { FiEdit, FiPlusSquare } from "react-icons/fi";
//import { FiTrash2, FiEdit, FiPlusSquare } from "react-icons/fi";
import { IoIosMore } from "react-icons/io";
import FormEditCategory from "../components/formEditCategory";
import FormEditSubCategories from "../components/formEditSubCategories";
import FormAddCategory from "../components/formAddCategory";

const Categories = ({ table, categories }) => {
  const { localization, direction } = useLocalization();
  const { userData } = useAuth();
  const { update, removeFinelbyId, addData } = useDb(table);
  const role = userData.role;
  const [show, setShow] = useState(false);
  const [showSubCat, setShowSubCat] = useState(false);
  const langs = ["ar", "fr", "en"];
  const [lang, _setLang] = useState(langs[0]);
  const [category, setCategory] = useState({});
  const [showAddCat, setShowAddCat] = useState(false);
  const [showAddSubCat, setShowAddSubCat] = useState(false);


  // eslint-disable-next-line no-unused-vars
  const _deleteFinel = async (item, _table) => {
    if (role !== "admin") {
      return false;
    }
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger m-2",
        cancelButton: "btn btn-secondary",
      },
      buttonsStyling: false,
    });
    await swalWithBootstrapButtons.fire(
      {
        title: localization.removeFinel,
        confirmButtonText: localization.delete,
        showCancelButton: true,
        cancelButtonText: localization.cancel,
        text: "",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          removeFinelbyId(item.id, _table).then((result) => {
            if (result.status) {
              Swal.fire(localization.deleteIsSuccess, "", "success").then();
            } else {
              Swal.fire(localization.deleteIsError, "", "error").then();
            }
          });
        },
      },
    );
  };

  const _edit = (item, _lang) => {
    setCategory(item);
    _setLang(_lang);
    setTimeout(() => {
      setShow(true);
    }, 150);
  };

  const _editSubItem = (item, _lang) => {
    setCategory(item);
    _setLang(_lang);
    setTimeout(() => {
      setShowSubCat(true);
    }, 150);
  };

  const updateStatus = (id, status, _table) => {
    update(id, { active: status }, _table).then();
  };

  const _addSubCat = (_lang) => {
    _setLang(_lang);
    setTimeout(() => {
      setShowAddSubCat(true);
    }, 150);
  };

  const _addCat = (_lang) => {
    _setLang(_lang);
    setTimeout(() => {
      setShowAddCat(true);
    }, 150);
  };

  return (
    <Container className={`container-app ${direction}`}>
      <Row>
        <Col lg="10" md="8" sm="12" xs="12"></Col>
        <Col lg="2" md="4" sm="12" xs="12">
          <div className="box btn-add">
            <DropdownButton
              className="dropdown-user"
              id="status"
              title={<FiPlusSquare size={26} />}
            >
              <Dropdown.Item onClick={() => _addCat("ar")}>
                {localization.Add} {localization.category}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => _addSubCat("ar")}>
                {localization.Add} {localization.subCategory}
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </Col>
      </Row>
      <Row>
        <div className="col-12 box table-app">
          <Table responsive>
            <thead>
              <tr>
                <th>{localization.category}</th>
                <th>{localization.status}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {categories.map((item, index) => {
                let style = { backgroundColor: "#ffffff" };
                let colorWhite = "";
                if (!parseInt(item.active)) {
                  style = { backgroundColor: "#cdcdcd" };
                  colorWhite = "text-white";
                }
                return (
                  <React.Fragment key={index}>
                    <tr style={style}>
                      <td className={colorWhite}>
                        <span
                          style={{
                            display: "inline-grid",
                            lineHeight: 1,
                          }}
                        >
                          {item.name}
                          <span className="subline"></span>
                        </span>
                      </td>
                      <td className={colorWhite}>
                        <span>
                          {parseInt(item.active) === 1
                            ? localization.active
                            : localization.disactive}
                        </span>
                      </td>

                      <td className="btn-table">
                        <DropdownButton
                          drop={"down"}
                          variant="info"
                          className="dropdown-more"
                          id="tt"
                          title={<FiEdit size={20} color="#fff" />}
                        >
                          <Dropdown.Item onClick={() => _edit(item, "ar")}>
                            {localization.ar}
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => _edit(item, "fr")}>
                            {localization.fr}
                          </Dropdown.Item>
                        </DropdownButton>
                        <DropdownButton
                          drop={"down"}
                          variant="secondary"
                          className="dropdown-more"
                          id="tt"
                          title={<IoIosMore color="#fff" fontSize="24px" />}
                        >
                          {/*<Button
                        variant="danger"
                        onClick={() => _deleteFinel(item)}
                      >
                        <FiTrash2 size={20} color="#fff" /> {localization.finel}
                      </Button>*/}
                          <DropdownButton
                            variant="secondary"
                            className="dropdown-user"
                            id="status"
                            title={localization.status}
                          >
                            <Dropdown.Item
                              onClick={() =>
                                updateStatus(item.id, 1, "categories")
                              }
                            >
                              {localization.active}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                updateStatus(item.id, "0", "categories")
                              }
                            >
                              {localization.disactive}
                            </Dropdown.Item>
                          </DropdownButton>
                        </DropdownButton>
                      </td>
                    </tr>
                    {item.subCategories.map((subItem, _index) => {
                      let subStyle = { backgroundColor: "#ffffff" };
                      let subColorWhite = "";
                      if (!parseInt(subItem.active)) {
                        subStyle = { backgroundColor: "#cdcdcd" };
                        subColorWhite = "text-white";
                      }
                      return (
                        <tr key={_index} style={subStyle}>
                          <td className={subColorWhite}>
                            <span
                              style={{
                                display: "inline-grid",
                                lineHeight: 1,
                              }}
                            >
                              {subItem.name}
                              <span className="subline">{item.name}</span>
                            </span>
                          </td>
                          <td className={subColorWhite}>
                            <span>
                              {parseInt(subItem.active) === 1
                                ? localization.active
                                : localization.disactive}
                            </span>
                          </td>

                          <td className="btn-table">
                            <DropdownButton
                              drop={"down"}
                              variant="info"
                              className="dropdown-more"
                              id="tt"
                              title={<FiEdit size={20} color="#fff" />}
                            >
                              <Dropdown.Item
                                onClick={() =>
                                  _editSubItem(
                                    {
                                      ...subItem,
                                      image: item.image,
                                    },
                                    "ar"
                                  )
                                }
                              >
                                {localization.ar}
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  _editSubItem(
                                    {
                                      ...subItem,
                                      image: item.image,
                                    },
                                    "fr"
                                  )
                                }
                              >
                                {localization.fr}
                              </Dropdown.Item>
                            </DropdownButton>
                            <DropdownButton
                              drop={"down"}
                              variant="secondary"
                              className="dropdown-more"
                              id="tt"
                              title={<IoIosMore color="#fff" fontSize="24px" />}
                            >
                              <DropdownButton
                                variant="secondary"
                                className="dropdown-user"
                                id="status"
                                title={localization.status}
                              >
                                <Dropdown.Item
                                  onClick={() =>
                                    updateStatus(
                                      subItem.id,
                                      1,
                                      "sub_categories"
                                    )
                                  }
                                >
                                  {localization.active}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() =>
                                    updateStatus(
                                      subItem.id,
                                      "0",
                                      "sub_categories"
                                    )
                                  }
                                >
                                  {localization.disactive}
                                </Dropdown.Item>
                              </DropdownButton>
                            </DropdownButton>
                          </td>
                        </tr>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Row>
      <FormEditCategory
        callBack={update}
        show={show}
        onHide={() => setShow(false)}
        lang={lang}
        category={category}
      />
      <FormEditSubCategories
        callBack={update}
        show={showSubCat}
        onHide={() => setShowSubCat(false)}
        lang={lang}
        category={category}
        table={"sub_categories"}
      />
      <FormAddCategory         
        callBack={addData}
        show={showAddCat}
        onHide={() => setShowAddCat(false)}
        lang={lang}
        categories={categories}
        table={"categories"} />
      <FormAddCategory         
        callBack={addData}
        show={showAddSubCat}
        onHide={() => setShowAddSubCat(false)}
        lang={lang}
        categories={categories}
        table={"sub_categories"} />
    </Container>
  );
};

export default Categories;
