import { useReload, useAuth } from "../context";
import { postJson } from "../networking/server";
import Links from "../constants/Links";

const useDb = (table) => {
  const { authToken, userData } = useAuth();
  const { setReload } = useReload();

  //const [table, setTable] = useState(_table);

  const update = async (id, data ,  _table = table) => {
    const response = await postJson(
      Links.update,
      { ...data, table: _table, id, uid: userData.id },
      authToken
    );
    if (response.status) {
      setReload('All');
    }
    return response;
  };

  const removeFinelbyId = async (id, _table = table) => {
    const response = await postJson(
      Links.delete,
      { table: _table, id, adminId: userData.id },
      authToken
    );
    //console.log(response);
    if (response.status) {
      setReload('All');
    }
    return response;
  };

  const addData = async (link, data) => {
    const response = await postJson(
      Links[link],
      { ...data, uid: userData.id },
      authToken
    );
    //console.log(response);
    if (response.status) {
      setReload('All');
    }
    return response;
  };

  return {removeFinelbyId, update, addData};

};

export default useDb;
