/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { postJson } from "../networking/server";
import Links from "../constants/Links";
import { useReload } from "../context";



const usePagination = ({url, where, authToken, count, page, onPageChange}) => {
  where = where ? where : {};
  page = page ? parseInt(page) : 1;
  count = count ? count : 10;
  onPageChange = onPageChange ? onPageChange : () => null;

  const [data, setData] = useState([]);
  const [_page, setPage] = useState(parseInt(page));
  const [loading, setLoading] = useState(true);
  const [more, setMore] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [moreData, setMoreData] = useState({});
  const { reload, reloadTo } = useReload();

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    getFromApi(page).then((response) => {
      if (response.status) {
        //console.log(response.results);
        if (isMounted) {
          setData(response.results.data);
          setPageCount(Math.ceil(response.results.count_all / count));
          setMoreData({
            count_all: response.results.count_all,
          });
          setPage(page);
          setLoading(false);
        }
      } else {
        if (isMounted) {
          setData([]);
          setLoading(false);
        }
      }
    });
    return () => (isMounted = false);
  }, [reload, page]);
  
  //console.log(page);

  //useEffect(() => {})

  //console.log(where);

  const getFromApi = async (p) => {

    p = parseInt(p);

    if(reloadTo === 'All')
    {
      if(where.table){
        where = {table : where.table};
      } else {
        where = {};
      }
    }

    const offset = count * (p - 1);
    const response = await postJson(
      Links[url],
      { ...where, limit: count, offset},
      authToken
    );
    return response;
  };

  const paginate = (p) => {
    p = parseInt(p);
    //console.log((p - 1) , pageCount);
    if((p - 1) >= pageCount){
      return false;
    }
    setMore(true);
    getFromApi(p).then((response) => {
      if (response.status) {
        setData([...data, ...response.results.data]);
      }
      setMore(false);
    });
    setPage(p);
    onPageChange(p);
  };

  return {
    page : _page,
    setPage,
    data,
    loading,
    more,
    pageCount,
    count,
    moreData,
    paginate,
  };
};

export default usePagination;
