/* eslint-disable import/no-anonymous-default-export */

//const baseUrl = "https://api.dz-web.eu/";
//const baseUrl = "http://cdr.dz-web.ovh/";
const baseUrl = process.env.REACT_APP_DOMAIN_API;
//const baseUrl = "https://api.dz-web.eu/";

module.exports = {
    appKey: process.env.REACT_APP_NOT_SECRET_TOKEN,
    nameKay: 'token',
    device: 'web',
    url: baseUrl + '/api',
    homeUrl: baseUrl,
    pic : baseUrl + '/uploads/?image=images/',
    uploads : baseUrl + '/uploads/?image=',
    appUrl : '',
    assetsUrl : baseUrl + '/images'
};