/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Form, Button, Col, InputGroup, FormControl } from "react-bootstrap";
import { useLocalization } from "../context";
import { FiFilter, FiSearch } from "react-icons/fi";

const FormSearch = (props) => {
  const { localization } = useLocalization();
  const [filterShow, setFilterShow] = React.useState(false);
  const table = props.table;

  const categories = props.categories ? props.categories : [];

  const option = (event, value) => {
    let _value = null;

    //console.log(event.target.name);

    if (event.target.checked) {
      _value = value;
    } else if (event.target.type !== "radio") {
      _value = value;
    } else {
      _value = null;
    }

    props.searchOption(event.target.name, _value);
  };

  React.useEffect(() => {
    if (!filterShow) {
      props.searchOption();
    }
  }, [filterShow]);

  return (
    <div className="user-search box">
      <Form className="search" onSubmit={(event) => event.preventDefault()}>
        <Form.Row>
          <Col lg="5" md="4" sm="12" xs="12" className="input">
            <InputGroup>
              <FormControl
                type="text"
                placeholder={localization.searchInput}
                name="search"
                onChange={(event) => props.searchValue(event.target.value)}
              />
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <FiSearch size={30} color="#333" />
                </InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>
          </Col>
          <Col lg="4" md="3" sm="12" xs="12" className="input">
            <InputGroup>
              <FormControl
                type="text"
                //defaultValue="name"
                as="select"
                onChange={(event) => props.searchBy(event.target.value)}
              >
                {table === "users" && (
                  <>
                    <option value="fullname">{localization.name}</option>
                    <option value="email">{localization.email}</option>
                    <option value="phone">{localization.phone}</option>
                    <option value="address">{localization.address}</option>
                    <option value="uid">Id</option>
                  </>
                )}
                {table === "questions" && (
                  <>
                    <option value="translate">{localization.question}</option>
                    <option value="id">Id</option>
                  </>
                )}
                {(table === "series") && (
                  <>
                    <option value="name">{localization.mode}</option>
                    <option value="id">Id</option>
                  </>
                )}
                {(table === "autoecole") && (
                  <>
                    <option value="name">{localization.autoEcole}</option>
                    <option value="address">{localization.address}</option>
                    <option value="id">Id</option>
                  </>
                )}
                {table === "cours" && (
                  <>
                    <option value="content">{localization.title}</option>
                    <option value="id">Id</option>
                  </>
                )}
              </FormControl>
            </InputGroup>
          </Col>
          <Col lg="1" md="1" sm="12" xs="12" className="input p-0">
            <Button
              className="btn-filter"
              onClick={() => setFilterShow(!filterShow)}
            >
              <FiFilter size={30} color="#333" />
            </Button>
          </Col>
          <Col lg="2" md="4" sm="12" xs="12" className="input">
            <Button className="btn-search" onClick={props.onSearch}>
              {localization.search}
            </Button>
          </Col>
          <Col
            className="filter p-0"
            lg="12"
            style={filterShow ? { display: "block" } : { display: "none" }}
          >
            {categories.length >= 1 && (
              <Col lg="5" md="4" sm="12" xs="12" className="input p-1">
                <p>{localization.categories}</p>
                <InputGroup>
                  <FormControl
                    name={table === "questions" ? "category_name" : "category"}
                    as="select"
                    onChange={(event) => option(event, event.target.value)}
                  >
                    <option value={""}>{"-------------"}</option>
                    {categories.map((category, index) => {
                      return (
                        <React.Fragment key={index}>
                          <option value={category.name}>
                            {category.translate["ar"]}
                          </option>
                        </React.Fragment>
                      );
                    })}
                  </FormControl>
                </InputGroup>
              </Col>
            )}
            <Col lg="12" className="p-1 mt-2">
              <div className="form-check">
                <label>{localization.status} : </label>
                <section>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="active"
                    defaultChecked={true}
                    defaultValue={0}
                    id="all"
                    onChange={(event) => option(event, "all")}
                  />
                  <label className="form-check-label" htmlFor="all">
                    {localization.all}
                  </label>
                </section>
                <section>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="active"
                    defaultChecked={false}
                    defaultValue={0}
                    id="active"
                    onChange={(event) => option(event, 1)}
                  />
                  <label className="form-check-label" htmlFor="active">
                    {localization.active}
                  </label>
                </section>
                <section>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="active"
                    defaultChecked={false}
                    defaultValue={0}
                    id="noActive"
                    onChange={(event) => option(event, 0)}
                  />
                  <label className="form-check-label" htmlFor="noActive">
                    {localization.noActive}
                  </label>
                </section>
              </div>
              {(table === "questions" || table === "cours") && (
                <div className="form-check">
                  <label>
                    {localization.status} {localization.translation} :{" "}
                  </label>
                  <section>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="translated"
                      defaultChecked={true}
                      defaultValue={0}
                      id="translatedAll"
                      onChange={(event) => option(event, "all")}
                    />
                    <label className="form-check-label" htmlFor="translatedAll">
                      {localization.all}
                    </label>
                  </section>
                  <section>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="translated"
                      defaultChecked={false}
                      defaultValue={1}
                      id="translated"
                      onChange={(event) => option(event, 1)}
                    />
                    <label className="form-check-label" htmlFor="translated">
                      {localization.translated}
                    </label>
                  </section>
                  <section>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="translated"
                      defaultChecked={false}
                      defaultValue={0}
                      id="noTranslated"
                      onChange={(event) => option(event, 0)}
                    />
                    <label className="form-check-label" htmlFor="noTranslated">
                      {localization.noTranslated}
                    </label>
                  </section>
                </div>
              )}
            </Col>
          </Col>
        </Form.Row>
      </Form>
    </div>
  );
};

export default FormSearch;
