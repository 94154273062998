/* eslint-disable no-unused-vars */
import ar from './ar';
import en from './en';
import fr from './fr';

  
const localization = {ar, fr, en};

const locales = {ar : 'ar', fr : 'fr', en : 'en'};

//locale = 'ar';

const _localization = (lang = 'ar') => {

  if(locales[lang]){
    return localization[lang];
  }else{
    return localization['ar'];
  }
  
};


export {_localization, locales};

