/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import {
  Image,
  Button,
  Form,
  InputGroup,
  Col,
  FormControl,
  Row,
  //Modal,
} from "react-bootstrap";
import { useLocalization, useAuth, useReload } from "../context";
import Codes from "../constants/codes";
import useValidation from "../hooks/useValidation";
import Alert from "./alert";
import Modal from "./Modal";
import { FiEdit } from "react-icons/fi";
import fileUpload from "../helps/fileUpload";
import { postJson } from "../networking/server";
import Links from "../constants/Links";
import ReactQuill from "react-quill";
import Config from "../appConfig";

const FormAddCours = (props) => {
  const { localization, direction } = useLocalization();
  const { authToken, userData } = useAuth();
  const { setReload } = useReload();

  const _onSubmit = props.callBack;
  const coursLang = props.lang;
  const categories = props.categories;
  const [cours, setCours] = useState({});

  const _setCours = (data) => {
    setCours({
      ...data,
      content: data.content ? JSON.parse(data.content) : { ar: "", fr: "" },
    });
  };

  useEffect(() => {
    _setCours(props.cours);
  }, [props.cours]);

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],

      //[{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction

      //[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      //[{ 'font': [] }],
      [{ align: [] }],
      ["link", "image", "video"],
      ["clean"], // remove formatting button
    ],
  };

  const _localization = (key) => {
    return localization[key];
  };

  const getTextFromCodes = (code) => {
    if (!code) {
      return "";
    }
    return _localization(Codes[code].text);
  };

  const inputs = [
    {
      name: "category",
      value: "",
      type: "select",
      items: categories.map((category) => {
        return {
          name: category.name,
          translate: category.translate[coursLang],
        };
      }),
      subItems: {
        name: "subCategory",
        value: "",
        type: "select",
        items: (name) => {
          return categories.filter((category) => category.name === name);
        },
      },
    },
    {
      name: "title",
      value: "",
      type: "textarea",
    },
    {
      name: "content",
      value: "",
      type: "quill",
    },
  ];

  //console.log(props.question);

  const Html = () => {
    const inputNotReq = [];

    const {
      errors,
      handelOnChange,
      data,
      handelOnSubmit,
      setData,
      RefEvent,
      refForm,
    } = useValidation(inputNotReq);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [alertType, setAlertType] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const [image, setImage] = useState(
      `${Config.uploads}/articles/${cours.image}`
    );
    const [filename, setFileName] = useState(`${cours.image}`);
    const refImage1 = useRef(null);
    const [content, setContent] = useState(cours.content[coursLang].content);

    useEffect(() => {
      setTimeout(() => setData(RefEvent()), 800);
      //console.log(refForm);
    }, [refForm]);

    //console.log(isOpen);

    const _fileUpdoad = (event) => {
      const _data = {
        id: cours.id,
        uid: userData.id,
        filename: filename,
      };
      setLoading(true);
      fileUpload(event.target.files, (result) => {
        if (result.status) {
          if (result.filebase64) {
            postJson(
              Links.updateImageCours,
              { ..._data, image: result.file },
              authToken
            ).then((response) => {
              if (response.status) {
                setImage(result.filebase64);
                setFileName(response.results.data.image);
                setAlertType("success");
                setError(false);
                setSuccess(true);
                setReload("All");
              } else {
                setAlertType("error");
                setSuccess(false);
                setError(true);
              }
              setMessage(response?.results?.code);
            });
          }
        } else {
          setError(true);
          setAlertType("error");
          setMessage(result.message);
        }
        setLoading(false);
      });
    };

    const onSubmit = (event, status) => {
      event.preventDefault();

      const langs = ["ar", "fr"];
      const [otherLang] = langs.filter((l) => l !== coursLang);
      let translated = 0;
      const translateds = {};

      langs.map((l) => {
        translateds[l] = cours.content[l].status;
        return true;
      });

      translateds[langs] = true;

      if (!content && content?.trim() === "") {
        setError(true);
        setAlertType("error");
        setMessage("FieldEmptyInput");
        return false;
      }

      if (
        !Object.values(translateds).includes(false) &&
        Object.values(translateds).length !== 0
      ) {
        translated = 1;
      }

      const _content = {
        [coursLang]: {
          title: data.title,
          content,
          status: translateds[langs],
        },
        [otherLang]: cours.content[otherLang],
      };

      if (status) {
        setLoading(true);
        _onSubmit(cours.id, {
          category: data.category,
          subCategory: data.subCategory,
          content: JSON.stringify(_content),
          translated,
        }).then((response) => {
          if (response.status) {
            setAlertType("success");
            setSuccess(true);
            //setTimeout(() => props.onHide(), 500);
          } else {
            setError(true);
            setAlertType("error");
          }
          setMessage(response?.results?.code);
          setLoading(false);
        });
      }
    };

    //console.log(cours);

    return (
      <>
        <Form
          ref={refForm}
          onSubmit={(event) => handelOnSubmit(event, onSubmit)}
          autoComplete="off"
          className={`edit-question ${direction}`}
        >
          <Form.Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <Row>
                <Col lg="12" md="12" sm="12" xs="12">
                  <div className="title">{localization.update}</div>
                </Col>
                <Col lg="12" md="12" sm="12" xs="12">
                  {Array.isArray(message) ? (
                    message.map((item, index) => (
                      <Alert
                        key={index}
                        message={getTextFromCodes(item)}
                        show={error || success}
                        type={alertType}
                        onHide={() => {
                          setError(false);
                          setSuccess(false);
                        }}
                      />
                    ))
                  ) : (
                    <Alert
                      message={
                        localization[message] ? localization[message] : message
                      }
                      show={error || success}
                      type={alertType}
                      onHide={() => {
                        setError(false);
                        setSuccess(false);
                      }}
                    />
                  )}
                </Col>
                <Col lg="12" md="12" sm="12" xs="12">
                  <div className="question-image">
                    <div
                      className="image-handle"
                      onClick={() => refImage1.current.click()}
                    >
                      <Image className="img-fluid" src={image} alt="" />
                      <div className="image-edit">
                        {<FiEdit size={82} color="#fff" />}
                      </div>
                    </div>
                  </div>
                </Col>

                {inputs.map((input, index) => {
                  return (
                    <React.Fragment key={index.toString()}>
                      <Col lg="12" md="12" sm="12" xs="12" className="input">
                        <label>
                          {localization[input.name]} <span> *</span>
                        </label>
                        <span className="error form-text">
                          {errors[input.name] &&
                            _localization(errors[input.name])}
                        </span>
                        {input.type === "select" && (
                          <React.Fragment>
                            <InputGroup
                              className={
                                errors[input.name] ? "input-error" : ""
                              }
                            >
                              <FormControl
                                dir="auto"
                                as={input.type}
                                placeholder={localization[input.name]}
                                name={input.name}
                                defaultValue={cours[input.name]}
                                onChange={handelOnChange}
                              >
                                <option value={""}>{"-------------"}</option>
                                {input.items.map((item, index) => {
                                  //console.log(item);
                                  return (
                                    <React.Fragment key={index}>
                                      <option value={item.name}>
                                        {item.translate}
                                      </option>
                                    </React.Fragment>
                                  );
                                })}
                              </FormControl>
                            </InputGroup>
                            {input.subItems &&
                              input.subItems
                                .items(data[input.name])
                                .map((subItem, _index) => {
                                  //subCategories
                                  //console.log(subItem);
                                  return (
                                    <div className="sub-item" key={_index}>
                                      <label>
                                        {localization[input.subItems.name]}{" "}
                                        <span> *</span>
                                      </label>
                                      <span className="error form-text">
                                        {errors[input.subItems.name] &&
                                          _localization(
                                            errors[input.subItems.name]
                                          )}
                                      </span>
                                      <InputGroup
                                        key={_index}
                                        className={
                                          errors[input.subItems.name]
                                            ? "input-error"
                                            : ""
                                        }
                                      >
                                        <FormControl
                                          dir="auto"
                                          as={input.subItems.type}
                                          placeholder={
                                            localization[input.subItems.name]
                                          }
                                          name={input.subItems.name}
                                          defaultValue={
                                            cours[input.subItems.name]
                                          }
                                          onChange={handelOnChange}
                                        >
                                          <option value={""}>
                                            {"-------------"}
                                          </option>
                                          {subItem.subCategories.map(
                                            (item, index) => {
                                              //console.log(item);
                                              //console.log(cours[input.subItems.name], item.name);
                                              return (
                                                <React.Fragment key={index}>
                                                  <option value={item.name}>
                                                    {item.translate[coursLang]}
                                                  </option>
                                                </React.Fragment>
                                              );
                                            }
                                          )}
                                        </FormControl>
                                      </InputGroup>
                                    </div>
                                  );
                                })}
                          </React.Fragment>
                        )}
                        {(input.type === "textarea" ||
                          input.type === "text") && (
                          <InputGroup
                            className={errors[input.name] ? "input-error" : ""}
                          >
                            <FormControl
                              dir="auto"
                              as={input.type}
                              placeholder={localization[input.name]}
                              name={input.name}
                              defaultValue={
                                cours.content[coursLang][input.name]
                              }
                              onChange={handelOnChange}
                            />
                          </InputGroup>
                        )}
                        {input.type === "quill" && (
                          <div>
                            <ReactQuill
                              theme="snow"
                              modules={modules}
                              //style={{minHeight: "400px"}}
                              style={{direction : coursLang === 'ar' ? 'rtl' : 'ltr'}}
                              onChange={setContent}
                              value={content}
                              name={input.name}
                            />
                          </div>
                        )}
                      </Col>
                    </React.Fragment>
                  );
                })}

                <Col>
                  <Button
                    disabled={loading}
                    type="submit"
                    className="btn-submit btn-default"
                  >
                    {localization.update}
                    {loading && <div className="lds-dual-ring white"></div>}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Form.Row>
        </Form>
        <div>
          <input
            type="file"
            hidden
            ref={refImage1}
            onChange={(event) => _fileUpdoad(event, "image")}
            accept="image/x-png,image/gif,image/jpeg"
            name="image"
          />
        </div>
      </>
    );
  };

  return <Modal show={props.show} onHide={props.onHide} Html={Html} />;

  /*return (
    <Modal
      animation={false}
      size="lg"
      show={props.show}
      onHide={props.onHide}
      className={`edit-cours ${direction}`}
    >
      <Modal.Header closeButton>
        <div className="title">
          {localization.update}
        </div>
      </Modal.Header>
      <Modal.Body className="pt-0 pb-0">
        <Row className={`p-4`}>
          <Html />
        </Row>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );*/
};

export default FormAddCours;
