/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import {
  Image,
  Button,
  Form,
  InputGroup,
  Col,
  FormControl,
  Row,
} from "react-bootstrap";
import { useLocalization } from "../context";
import Codes from "../constants/codes";
//import Config from "../appConfig";
import useValidation from "../hooks/useValidation";
import fileUpload from "../helps/fileUpload";
import { FiEdit } from "react-icons/fi";
import Alert from "../components/alert";
import Modal from "./Modal";

const FormAddCategory = (props) => {
  const { localization, direction } = useLocalization();

  const _onSubmit = props.callBack;

  const _localization = (key) => {
    return localization[key];
  };

  const getTextFromCodes = (code) => {
    if (!code) {
      return "";
    }
    return _localization(Codes[code].text);
  };

  const Html = () => {
    const inputNotReq = [];

    const {
      errors,
      handelOnChange,
      data,
      handelOnSubmit,
      setData,
      RefEvent,
      refForm,
    } = useValidation(inputNotReq);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [alertType, setAlertType] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(null);
    const [inputs, setInputs] = useState([]);
    const refImage = useRef(null);
    //const [lang, setLang] = useState("ar");
    const [file, setFile] = useState(null);

    //const lang = props.lang;

    useEffect(() => {
      _setInputs(props.categories);
      //setLang(props.lang);
    }, []);

    //console.log(props.table);

    const _setInputs = (categories) => {
      let _inputs = [];

      if (props.table === "sub_categories") {
        _inputs = [
          {
            name: "category_id",
            value: "",
            type: "select",
            placeholder: localization.category,
            items: categories.map((category) => {
              return {
                name: category.id,
                translate: category.translate[props.lang],
              };
            }),
          },
        ];
      }

      _inputs = [
        ..._inputs,
        {
          name: `name`,
          placeholder: localization.category_name,
          type: "textarea",
        },
        {
          name: `translate_ar`,
          placeholder: `${localization.category} (${localization.ar})`,
          type: "textarea",
        },
        {
          name: `content_ar`,
          placeholder: `${localization.content} (${localization.ar})`,
          type: "textarea",
        },
        {
          name: `translate_fr`,
          placeholder: `${localization.category} (${localization.fr})`,
          type: "textarea",
        },
        {
          name: `content_fr`,
          placeholder: `${localization.content} (${localization.fr})`,
          type: "textarea",
        },
      ];

      setInputs(_inputs);
    };

    useEffect(() => {
      setTimeout(() => setData(RefEvent()), 800);
    }, [refForm]);

    const _fileUpdoad = (event) => {
      setLoading(true);
      fileUpload(event.target.files, (result) => {
        //console.log(result);
        if (result.status) {
          if (result.filebase64) {
            setImage(result.filebase64);
            setFile(result.file);
            setError(false);
          }
        } else {
          setError(true);
          setAlertType("error");
          setFile(null);
          setMessage(result.message);
        }
        setLoading(false);
      });
    };

    const onSubmit = (event, status) => {
      //console.log(data);

      if (props.table !== "sub_categories") {
        if (!file) {
          setError(true);
          setAlertType("error");
          setMessage("FieldEmptyInput");
          return false;
        }
      }

      const translate = {};
      const content = {};
      const langs = ["ar", "fr"];

      langs.map((lang) => {
        translate[lang] = data[`translate_${lang}`];
        content[lang] = data[`content_${lang}`];
        return true;
      });

      translate["en"] = "";
      content["en"] = "";

      console.log(translate, content);
      //return false;

      if (status) {
        const url =
          props.table === "sub_categories" ? "addSubCategory" : "addCategory";
        setLoading(true);
        _onSubmit(url, {
          category_id: data.category_id ? data.category_id : null,
          name: data.name.replace(/([ ])/g, "_").toLocaleLowerCase(),
          content: JSON.stringify(content),
          translate: JSON.stringify(translate),
          image: file,
        }).then((response) => {
          if (response.status) {
            setAlertType("success");
            setSuccess(true);
            setTimeout(() => props.onHide(), 500);
          } else {
            setError(true);
            setAlertType("error");
          }
          setMessage(response?.results?.code);
          setLoading(false);
        });
      }
    };

    return (
      <>
        <Form
          ref={refForm}
          onSubmit={(event) => handelOnSubmit(event, onSubmit)}
          autoComplete="off"
          className={`edit-question ${direction}`}
        >
          <Form.Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <Row>
                <Col>
                  <p className="title">{localization.Add}</p>
                </Col>
                <Col lg="12" md="12" sm="12" xs="12" className={`${direction}`}>
                  {Array.isArray(message) ? (
                    message.map((item, index) => (
                      <Alert
                        key={index}
                        message={getTextFromCodes(item)}
                        show={error || success}
                        type={alertType}
                        onHide={() => {
                          setError(false);
                          setSuccess(false);
                        }}
                      />
                    ))
                  ) : (
                    <Alert
                      message={
                        localization[message] ? localization[message] : message
                      }
                      show={error || success}
                      type={alertType}
                      onHide={() => {
                        setError(false);
                        setSuccess(false);
                      }}
                    />
                  )}
                </Col>
                <Col lg="12" md="12" sm="12" xs="12">
                  {props.table !== "sub_categories" && (
                    <div className="question-image">
                      <div
                        className="image-handle"
                        onClick={() => refImage.current.click()}
                      >
                        <Image className="img-fluid" src={image} alt="" />
                        <div className="image-edit">
                          {<FiEdit size={82} color="#fff" />}
                        </div>
                      </div>
                    </div>
                  )}
                </Col>

                {inputs.map((input, index) => {
                  return (
                    <React.Fragment key={index.toString()}>
                      <Col lg="12" md="12" sm="12" xs="12" className="input">
                        <label className={`${direction}`}>
                          {input.placeholder} <span> * </span>
                        </label>
                        <span>
                          {input.name === "name" && data[input.name]
                            ? `http://example/category/${data[
                                input.name
                              ]?.replace(/([ ])/g, "_").toLocaleLowerCase()}`
                            : null}
                        </span>
                        <span className={`error form-text ${direction}`}>
                          {errors[input.name] &&
                            _localization(errors[input.name])}
                        </span>

                        {input.type === "select" && (
                          <React.Fragment>
                            <InputGroup
                              className={
                                errors[input.name] ? "input-error" : ""
                              }
                            >
                              <FormControl
                                dir="auto"
                                as={input.type}
                                placeholder={input.placeholder}
                                name={input.name}
                                defaultValue={input.value}
                                onChange={handelOnChange}
                              >
                                <option value={""}>{"-------------"}</option>
                                {input.items.map((item, index) => {
                                  //console.log(item);
                                  return (
                                    <React.Fragment key={index}>
                                      <option value={item.name}>
                                        {item.translate}
                                      </option>
                                    </React.Fragment>
                                  );
                                })}
                              </FormControl>
                            </InputGroup>
                          </React.Fragment>
                        )}

                        {(input.type === "textarea" ||
                          input.type === "text") && (
                          <InputGroup
                            className={errors[input.name] ? "input-error" : ""}
                          >
                            <FormControl
                              dir="auto"
                              as={input.type}
                              placeholder={input.placeholder}
                              name={input.name}
                              defaultValue={input.value}
                              onChange={handelOnChange}
                            />
                          </InputGroup>
                        )}
                      </Col>
                    </React.Fragment>
                  );
                })}

                <Col className={`${direction}`}>
                  <Button
                    disabled={loading}
                    type="submit"
                    className="btn-submit btn-default"
                  >
                    {localization.Add}
                    {loading && <div className="lds-dual-ring white"></div>}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Form.Row>
        </Form>
        <div>
          <input
            type="file"
            hidden
            ref={refImage}
            onChange={(event) => _fileUpdoad(event, "image")}
            accept="image/x-png,image/gif,image/jpeg"
            name="image"
          />
        </div>
      </>
    );
  };

  return <Modal show={props.show} onHide={props.onHide} Html={Html} />;
};

export default FormAddCategory;
