/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import {
  Image,
  Button,
  Form,
  InputGroup,
  Col,
  FormControl,
  Row,
  //Modal,
} from "react-bootstrap";
import { useLocalization } from "../context";
import Codes from "../constants/codes";
import useValidation from "../hooks/useValidation";
import Alert from "./alert";
import Modal from "./Modal";
import { FiEdit } from "react-icons/fi";
import fileUpload from "../helps/fileUpload";
//import Config from "../appConfig";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";

const FormAddQuestion = (props) => {
  const { localization, direction } = useLocalization();

  const _onSubmit = props.onSubmit;
  const lang = props.lang;
  const categories = props.categories;

  const _localization = (key) => {
    return localization[key];
  };

  const getTextFromCodes = (code) => {
    if (!code) {
      return "";
    }
    return _localization(Codes[code].text);
  };

  const inputs = [
    {
      name: "category_name",
      value: "",
      type: "select",
      items: categories.map((category) => {
        return {
          name: category.name,
          translate: category.translate[lang],
        };
      }),
    },
    {
      name: "response_text",
      value: "",
      type: "textarea",
    }
  ];

  const question = {
    question_1: {
      answer: {
        a: null,
        b: null,
        c: null,
        d: null,
      },
      question: "",
    },
    question_2: {
      answer: {
        c: null,
        d: null,
      },
      question: "",
    },
  };

  const Html = () => {
    const inputNotReq = [
      "answer_question_1_c",
      "answer_question_1_d",
      "answer_question_2_c",
      "answer_question_2_d",
      "question_2",
    ];

    const {
      errors,
      handelOnChange,
      data,
      handelOnSubmit,
      setData,
      RefEvent,
      refForm,
    } = useValidation(inputNotReq);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [alertType, setAlertType] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const [image, setImage] = useState(null);
    const refImage1 = useRef(null);
    const [file, setFile] = useState(null);
    const [submitDisable, setSubmitDisable] = useState(false);

    //const [othreAnswer, setOthreAnswer] = useState(false);
    const [rightAnswer, setRightAnswer] = useState([]);
    const [faqs, setFaqs] = useState([]);

    useEffect(() => {
      setTimeout(() => setData(RefEvent()), 800);
      //console.log(refForm);
    }, [refForm, faqs]);

    const faqAdd = () => {
      setFaqs([
        ...faqs,
        {
          question: "",
          response: "",
        },
      ]);
    };

    const addToRightAnswer = (ch) => {
      if (!rightAnswer.includes(ch)) {
        setRightAnswer([...rightAnswer, ch]);
      } else {
        const right_answer = rightAnswer.filter((a) => ch !== a);
        setRightAnswer(right_answer);
      }
    };

    const faqRemove = (index) => {
      const _faqs = faqs.filter((f, i) => index !== i);
      setFaqs(_faqs);
      //console.log(_faqs);
    };

    //console.log(isOpen);

    const _fileUpdoad = (event, name) => {
      //const _data = {};
      setLoading(true);
      fileUpload(event.target.files, (result) => {
        //console.log(result);
        if (result.status) {
          if (result.filebase64) {
            setImage(result.filebase64);
            setFile(result.file);
            setError(false);
          }
        } else {
          setError(true);
          setAlertType("error");
          setMessage(result.message);
        }
        setLoading(false);
      });
    };

    const onSubmit = (event, status) => {
      event.preventDefault();

      const dataForUpdate = {
        [lang]: {
          faq: [],
          ...question,
          status: false,
        },
      };
      //console.log(data);

      const question_1 = dataForUpdate[lang].question_1;
      const question_2 = dataForUpdate[lang].question_2;
      const faq = faqs.map(() => {
        return {
          question: "",
          response: "",
        };
      });

      dataForUpdate[lang].status = true;

      if (question_1) {
        if (data.question_1) {
          question_1.question = data.question_1;
        }

        Object.keys(question_1.answer).map((key) => {
          if (data[`answer_question_1_${key}`]) {
            question_1.answer[key] = data[`answer_question_1_${key}`];
          } else {
            question_1.answer[key] = null;
          }
          return true;
        });
      }

      if (question_2) {
        if (data.question_2) {
          question_2.question = data.question_2;
        }

        Object.keys(question_2.answer).map((key) => {
          if (data[`answer_question_2_${key}`]) {
            question_2.answer[key] = data[`answer_question_2_${key}`];
          } else {
            question_2.answer[key] = null;
          }
          return true;
        });
      }

      if (!data.question_2) {
        dataForUpdate[lang].question_2 = null;
      }

      if (faqs.length >= 1) {
        faqs.map((_faq, index) => {
          if (data[`faq_question_${index}`] && data[`faq_response_${index}`]) {
            if (faq[index]) {
              faq[index].question = data[`faq_question_${index}`];
              faq[index].response = data[`faq_response_${index}`];
            }
            dataForUpdate[lang].faq = faq;
          }
          return true;
        });
      } else {
        dataForUpdate[lang].faq = [];
      }

      //console.log(dataForUpdate);
      //console.log(status);
      //return false;

      const langs = ["ar", "fr"];
      const [key] = langs.filter((l) => l !== lang);

      const translate = {
        ...dataForUpdate,
        [key]: {
          status : false,  
          question_1: {
            answer: {
              a: null,
              b: null,
              c: null,
              d: null,
            },
            question: "",
          },
          question_2: dataForUpdate[lang].question_2
            ? {
                answer: {
                  c: null,
                  d: null,
                },
                question: "",
              }
            : null,
          faq: dataForUpdate[lang].faq.map(() => {
            return {
              question: "",
              response: "",
            };
          }),
        },
      };

      //console.log(data, translate);

      //return false;

      if (rightAnswer.length === 0) {
        setError(true);
        setAlertType("error");
        setMessage("FieldEmptyInput");
        return false;
      }

      if (status) {
        setLoading(true);
        _onSubmit("newQuestion", {
          category_name : data.category_name,  
          translate: JSON.stringify(translate),
          content: JSON.stringify(dataForUpdate[lang]),
          right_answer: JSON.stringify(rightAnswer),
          [`response_text_${lang}`] : data.response_text,
          image: file,
        }).then((response) => {
          if (response.status) {
            setAlertType("success");
            setSuccess(true);
            //setTimeout(() => props.onHide(), 500);
            setSubmitDisable(true);
          } else {
            setError(true);
            setAlertType("error");
          }
          setMessage(response?.results?.code);
          setLoading(false);
        });
      }
    };

    return (
      <>
        <Form
          ref={refForm}
          onSubmit={(event) => handelOnSubmit(event, onSubmit)}
          autoComplete="off"
          className={`edit-question ${direction}`}
        >
          <Form.Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <Row>
                <Col>
                  <div className="title">
                    {localization.setQuestion}
                    {localization[lang]}
                  </div>
                </Col>
                <Col lg="12" md="12" sm="12" xs="12" className={`${direction}`}>
                  {Array.isArray(message) ? (
                    message.map((item, index) => (
                      <Alert
                        key={index}
                        message={getTextFromCodes(item)}
                        show={error || success}
                        type={alertType}
                        onHide={() => {
                          setError(false);
                          setSuccess(false);
                        }}
                      />
                    ))
                  ) : (
                    <Alert
                      message={
                        localization[message] ? localization[message] : message
                      }
                      show={error || success}
                      type={alertType}
                      onHide={() => {
                        setError(false);
                        setSuccess(false);
                      }}
                    />
                  )}
                </Col>
                <Col lg="12" md="12" sm="12" xs="12">
                  <div className="question-image">
                    <div
                      className="image-handle"
                      onClick={() => refImage1.current.click()}
                    >
                      <Image className="img-fluid" src={image} alt="" />
                      <div className="image-edit">
                        {<FiEdit size={82} color="#fff" />}
                      </div>
                    </div>
                  </div>
                </Col>

                {inputs.map((input, index) => {
                  return (
                    <React.Fragment key={index.toString()}>
                      <Col lg="12" md="12" sm="12" xs="12" className="input">
                        <label className={`${direction}`}>
                          {localization[input.name]} <span> *</span>
                        </label>
                        <span className={`error form-text ${direction}`}>
                          {errors[input.name] &&
                            _localization(errors[input.name])}
                        </span>

                        {input.type === "select" && (
                          <React.Fragment>
                            <InputGroup
                              className={
                                errors[input.name] ? "input-error" : ""
                              }
                            >
                              <FormControl
                                dir="auto"
                                as={input.type}
                                placeholder={localization[input.name]}
                                name={input.name}
                                defaultValue={input.value}
                                onChange={handelOnChange}
                              >
                                <option value={""}>{"-------------"}</option>
                                {input.items.map((item, index) => {
                                  //console.log(item);
                                  return (
                                    <React.Fragment key={index}>
                                      <option value={item.name}>
                                        {item.translate}
                                      </option>
                                    </React.Fragment>
                                  );
                                })}
                              </FormControl>
                            </InputGroup>
                          </React.Fragment>
                        )}
                        {(input.type === "textarea" ||
                          input.type === "text") && (
                          <InputGroup
                            className={errors[input.name] ? "input-error" : ""}
                          >
                            <FormControl
                              dir="auto"
                              as={input.type}
                              placeholder={localization[input.name]}
                              name={input.name}
                              defaultValue={input.value}
                              onChange={handelOnChange}
                            />
                          </InputGroup>
                        )}
                      </Col>
                    </React.Fragment>
                  );
                })}

                {[question.question_1, question.question_2].map(
                  (item, index) => {
                    const key = `question_${index + 1}`;

                    return (
                      <React.Fragment key={index.toString()}>
                        <Col lg="12" md="12" sm="12" xs="12" className="input">
                          <label>
                            {localization.question} {index + 1}{" "}
                            <span>
                              {" "}
                              {!inputNotReq.includes(key) ? "*" : ""}
                            </span>
                          </label>
                          <span className="error form-text">
                            {errors[key] && _localization(errors[key])}
                          </span>
                          <InputGroup
                            className={errors[key] ? "input-error" : ""}
                          >
                            <FormControl
                              type="text"
                              dir="auto"
                              as="textarea"
                              placeholder={localization.question}
                              name={key}
                              defaultValue={item.question}
                              onChange={handelOnChange}
                            />
                          </InputGroup>
                        </Col>
                        <Col lg="12" md="12" sm="12" xs="12" className="input">
                          <label>
                            {localization.answer} {localization.question}{" "}
                            {index + 1}
                            <span>{!inputNotReq.includes(key) ? "*" : ""}</span>
                          </label>

                          <p>- {localization.rightAnswerCheck}</p>

                          {Object.keys(item.answer).map((ch) => {
                            if (!item.answer[ch]) {
                              //return null;
                            }
                            const answer = `answer_${key}_${ch}`;
                            return (
                              <div key={ch} className="answer">
                                <p dir="auto">
                                  <span style={{ color: "#dc3545" }}>
                                    {localization.answer} ({ch})
                                  </span>
                                </p>
                                <span className="error form-text">
                                  {errors[answer] &&
                                    _localization(errors[answer])}
                                </span>
                                <InputGroup
                                  className={
                                    errors[answer] ? "input-error" : ""
                                  }
                                >
                                  <FormControl
                                    dir="auto"
                                    type="text"
                                    placeholder={localization.answer}
                                    name={answer}
                                    defaultValue={
                                      item.answer[ch] ? item.answer[ch] : ""
                                    }
                                    onChange={handelOnChange}
                                  />
                                  <InputGroup.Append>
                                    <Button
                                      variant="link"
                                      onClick={() => addToRightAnswer(ch)}
                                    >
                                      {rightAnswer.includes(ch) ? (
                                        <ImCheckboxChecked />
                                      ) : (
                                        <ImCheckboxUnchecked />
                                      )}
                                    </Button>
                                  </InputGroup.Append>
                                </InputGroup>
                              </div>
                            );
                          })}
                        </Col>
                      </React.Fragment>
                    );
                  }
                )}

                {faqs.map((_faq, index) => {
                  return (
                    <React.Fragment key={index.toString()}>
                      <Col>
                        <p className="faq">{localization.faq}</p>
                      </Col>

                      <Col lg="12" md="12" sm="12" xs="12" className="input">
                        <label>
                          {localization.question} {index + 1} <span> *</span>
                          <Button
                            variant="danger"
                            className="remove"
                            onClick={() => faqRemove(index)}
                          >
                            {localization.delete}
                          </Button>
                        </label>
                        <p dir="auto">{_faq.question}</p>
                        <span className="error form-text">
                          {errors[`faq_question_${index}`] &&
                            _localization(errors[`faq_question_${index}`])}
                        </span>
                        <InputGroup
                          className={
                            errors[`faq_question_${index}`] ? "input-error" : ""
                          }
                        >
                          <FormControl
                            type="text"
                            dir="auto"
                            as="textarea"
                            placeholder={localization.question}
                            name={`faq_question_${index}`}
                            defaultValue={_faq.question}
                            onChange={handelOnChange}
                          />
                        </InputGroup>
                      </Col>
                      <Col lg="12" md="12" sm="12" xs="12" className="input">
                        <label>
                          {localization.response} {index + 1} <span> *</span>
                        </label>
                        <span className="error form-text">
                          {errors[`faq_response_${index}`] &&
                            _localization(errors[`faq_response_${index}`])}
                        </span>
                        <InputGroup
                          className={
                            errors[`faq_response_${index}`] ? "input-error" : ""
                          }
                        >
                          <FormControl
                            type="text"
                            dir="auto"
                            as="textarea"
                            placeholder={localization.response}
                            name={`faq_response_${index}`}
                            defaultValue={_faq.response}
                            onChange={handelOnChange}
                          />
                        </InputGroup>
                      </Col>
                    </React.Fragment>
                  );
                })}

                <Col lg="12" md="12" sm="12" xs="12">
                  <Button onClick={faqAdd} className="p-0" variant="link">
                    {localization.Add} {localization.faq}
                  </Button>
                </Col>

                <Col className={`${direction}`}>
                  <Button
                    disabled={submitDisable}
                    type="submit"
                    className="btn-submit btn-default"
                  >
                    {localization.Add}
                    {loading && <div className="lds-dual-ring white"></div>}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Form.Row>
        </Form>
        <div>
          <input
            type="file"
            hidden
            ref={refImage1}
            onChange={(event) => _fileUpdoad(event, "image_question_1")}
            accept="image/x-png,image/gif,image/jpeg"
            name="image"
          />
        </div>
      </>
    );
  };

  return <Modal show={props.show} onHide={props.onHide} Html={Html} />;

  /*return (
    <Modal
      animation={false}
      size="lg"
      show={props.show}
      onHide={props.onHide}
      className={`edit-cours ${direction}`}
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body className="pt-0 pb-0">
        <Row className={`p-4`}>
          <Html />
        </Row>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );*/
};

export default FormAddQuestion;
