/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Image,
  Button,
  Form,
  InputGroup,
  Col,
  FormControl,
  Row,
} from "react-bootstrap";
import { useLocalization } from "../context";
import moment from "moment";
import Codes from "../constants/codes";
import Config from "../appConfig";
import useValidation from "../hooks/useValidation";
import Alert from "../components/alert";
import Modal from "./Modal";

var dateString = (value, formt = 'normal') => {
  if(formt === 'unix'){
    return moment.unix(parseInt(value)).format("DD/MM/YYYY");
  }
  return moment(value).format("DD/MM/YYYY HH:mm");
};

const FormUpdateUser = (props) => {
  const { localization, direction } = useLocalization();
  const _update = props.update;

  const _localization = (key) => {
    return localization[key];
  };

  const getTextFromCodes = (code) => {
    if (!code) {
      return "";
    }
    return _localization(Codes[code].text);
  };

  const Html = () => {
    const [userData, setUserData] = useState({});
    const {
      errors,
      handelOnChange,
      data,
      handelOnSubmit,
      refForm,
      setData,
      RefEvent,
    } = useValidation(["role", "subscribe"]);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [alertType, setAlertType] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const [image, setImage] = useState("");

    const forView = props.forView ? true : false;

    const [id, setId] = useState(0);

    const months = () => {
      const _months = [];

      for (let i = 1; i <= 12; i++) {
        _months[i] = i;
      }

      return _months;
    };

    useEffect(() => {
      setId(props.userData.id);
      setUserData({
        fullname: props.userData.fullname,
        phone: props.userData.phone,
        email: props.userData.email,
        role: props.userData.role,
        subscribe_type: props.userData.subscribe_type,
        subscribe : props.userData.subscribe,
      });
      setError(false);
      setMessage("");
      setSuccess(false);
      setImage(
        props.userData.remoteImage
          ? props.userData.remoteImage
          : `${Config.pic}${props.userData.image}`
      );
    }, [props.userData]);

    const inputs = [
      {
        name: "fullname",
        type: "text",
        placeholder: localization.fullname,
      },
      {
        name: "email",
        type: "email",
        placeholder: localization.email,
      },
      {
        name: "phone",
        type: "text",
        placeholder: localization.phone,
      },
      {
        name: "role",
        type: "select",
        placeholder: localization.roles[0],
        items: localization.roles[1],
      },
      {
        name: "subscribe_type",
        type: "select",
        placeholder: localization.subscribe_type,
        items: {
          free: localization.free,
          premium: localization.premium,
        },
      },
    ];

    useEffect(() => {
      setData(RefEvent());
      //console.log(refForm);
    }, [data.subscribe_type]);

    //console.log(userData);

    const onSubmit = (event, status) => {
      event.preventDefault();

      if (status) {
        setLoading(true);
        //data.oldSubscribe = userData.subscribe;
        _update(id, data).then((response) => {
          if (response.status) {
            setAlertType("success");
            setSuccess(true);
          } else {
            setError(true);
            setAlertType("error");
          }
          setMessage(response?.results?.code);
          setLoading(false);
        });
      }
    };

    //console.log(data);
    return (
      <Form
        ref={refForm}
        onSubmit={(event) => handelOnSubmit(event, onSubmit)}
        autoComplete="off"
        className={direction}
      >
        <Form.Row>
          <Col lg="3" md="4" sm="12" xs="12">
            <div className="profile-pic">
              <Image src={image} roundedCircle />
            </div>
          </Col>
          <Col lg="9" md="8" sm="12" xs="12">
            <Row>
              <Col lg="12" md="12" sm="12" xs="12" className="title">
                {forView ? localization.profile : localization.profileUpdate}
              </Col>
              <Col lg="12" md="12" sm="12" xs="12">
                {Array.isArray(message) ? (
                  message.map((item, index) => (
                    <Alert
                      key={index}
                      message={getTextFromCodes(item)}
                      show={error || success}
                      type={alertType}
                      onHide={() => {
                        setError(false);
                        setSuccess(false);
                      }}
                    />
                  ))
                ) : (
                  <Alert
                    message={message}
                    show={error || success}
                    type={alertType}
                    onHide={() => {
                      setError(false);
                      setSuccess(false);
                    }}
                  />
                )}
              </Col>

              {inputs.map((input, _index) => {
                const key = `${input.name}`;
                return (
                  <Col lg="12" md="12" sm="12" xs="12" key={key}>
                    <span className="error form-text">
                      {errors[key] && localization[errors[key]]}
                    </span>
                    <div
                      className={`form-group input-group${
                        errors[key] ? " input-error" : ""
                      }`}
                    >
                      {input.type === "select" ? (
                        <FormControl
                          name={key}
                          value={data[key] ? data[key] : userData[key]}
                          as={input.type}
                          disabled={forView || userData[key] === "autoecole"}
                          onChange={handelOnChange}
                          placeholder={input.placeholder}
                        >
                          <option value={""}>{input.placeholder}</option>
                          {Object.keys(input.items).map((_key) => (
                            <option key={_key} value={_key}>
                              {input.items[_key]}
                            </option>
                          ))}
                        </FormControl>
                      ) : (
                        <>
                          <FormControl
                            type={input.type}
                            name={key}
                            disabled={forView}
                            defaultValue={userData[key] ? userData[key] : ""}
                            onChange={handelOnChange}
                            placeholder={input.placeholder}
                          />
                        </>
                      )}
                    </div>
                  </Col>
                );
              })}

              {data.subscribe_type && data.subscribe_type === "premium" && (
                <Col lg="12" md="12" sm="12" xs="12" className="input">
                  <label>
                    {localization.subscribe_date_end} : ({userData.subscribe_type === "premium" ? dateString(userData.subscribe, 'unix') : '/'})
                  </label>
                  <InputGroup
                    className={errors.subscribe ? "input-error" : ""}
                  >
                    <Form.Control
                      as="select"
                      disabled={forView}
                      name="subscribe"
                      //value={userData.subscribe}
                      onChange={handelOnChange}
                    >
                      <option value={""}>
                        {localization.subscribe_date_end}
                      </option>

                      {Object.keys(months()).map((_key) => (
                        <option key={_key} value={_key}>
                          {_key}
                        </option>
                      ))}
                    </Form.Control>
                  </InputGroup>
                </Col>
              )}


              {forView && (
                <>
                  <Col lg="12" md="12" sm="12" xs="12" className="input">
                    <label>{localization.date_created}</label>
                    <InputGroup>
                      <FormControl
                        type="text"
                        disabled={forView}
                        value={
                          localization.date_created +
                          ": " +
                          dateString(props.userData.created_at)
                        }
                      />
                    </InputGroup>
                  </Col>
                  <Col lg="12" md="12" sm="12" xs="12" className="input">
                    <label>{localization.date_updated}</label>
                    <InputGroup>
                      <FormControl
                        type="text"
                        disabled={forView}
                        value={
                          localization.date_updated +
                          ": " +
                          dateString(props.userData.created_at)
                        }
                      />
                    </InputGroup>
                  </Col>
                </>
              )}

              <Col>
                {!forView && (
                  <Button disabled={loading} type="submit">
                    {localization.update}
                    {loading && <div className="lds-dual-ring white"></div>}
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Form.Row>
      </Form>
    );
  };

  return <Modal show={props.show} onHide={props.onHide} Html={Html} />;
};

export default FormUpdateUser;
