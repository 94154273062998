import React from "react";
import { useLocalization, useAuth } from "../context";
import { Container, Row, Col, DropdownButton, Dropdown } from "react-bootstrap";
import SeriesTabel from "../components/seriesTabel";
import FormSearch from "../components/formSearch";
import useSearch from "../hooks/useSearch";
import useDb from "../hooks/useDb";
import { FiPlusSquare } from "react-icons/fi";
import FormEditSeries from "../components/formEditSeries";
import usePagination from "../hooks/usePagination";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const Series = ({table, categories}) => {
  const { localization, direction } = useLocalization();
  const { authToken, userData } = useAuth();
  const { search, pathname } = useLocation();
  const { p } = queryString.parse(search);
  const {
    onSearch,
    searchQuerys,
    setSearchBy,
    setSearchValue,
    onSearchOption,
  } = useSearch('mode');
  const { removeFinelbyId, update, addData } = useDb(table);
  const [show, setShow] = React.useState(false);
  const [serie, setSerie] = React.useState({});

  const { page, data, loading, more, paginate, setPage, pageCount } = usePagination({
    url: "series",
    where: { ...searchQuerys },
    authToken: authToken,
    page: p,
    onPageChange : (_p) => {
      window.history.pushState(
        {},
        null,
        `${window.location.origin}/#${pathname}?p=${_p}`
      )
    },
  });

  
  const _onSearch = () => {
    setPage(1);
    onSearch();
  }

  const _addSerie = (mode) => {
    setSerie({
      mode: mode,
      questions: [],
    });
    setTimeout(() => {
      setShow(true);
    }, 150);
  };

  return (
    <Container className={`container-app ${direction}`}>
      <Row>
        <Col>
          <div className="title">{localization.series}</div>
        </Col>
      </Row>
      <Row>
        <Col lg="10" md="8" sm="12" xs="12">
          <FormSearch
            onSearch={_onSearch}
            searchBy={setSearchBy}
            searchValue={setSearchValue}
            table={table}
            searchOption={onSearchOption}
          />
        </Col>
        <Col lg="2" md="4" sm="12" xs="12">
          <div className="box btn-add">
            <DropdownButton
              className="dropdown-user"
              id="status"
              title={<FiPlusSquare size={26} />}
            >
              <Dropdown.Item onClick={() => _addSerie("training-exam")}>
                {localization["training-exam"]}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => _addSerie("white-exam")}>
                {localization["white-exam"]}
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <SeriesTabel
            searchQuerys={searchQuerys}
            update={update}
            data={data}
            paginate={() => paginate(page + 1)}
            userData={userData}
            authToken={authToken}
            nbrOfPage={`${page} / ${pageCount}`}
            loading={loading || more}
            removeFinelbyId={removeFinelbyId}
            categories={categories}
          />
        </Col>
      </Row>
      <FormEditSeries
        callBack={(data) => addData('newSerie', {
          ...data,
          mode: serie.mode,
        })}
        show={show}
        onHide={() => setShow(false)}
        serie={serie}
        //userData={userData}
        //authToken={authToken}
        typeForm={"addNewSerie"}
        categories={categories}
      />
    </Container>
  );
};

export default Series;
